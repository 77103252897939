import request from '@/utils/request';
const options = {};

const $get = request.get(options);
const $post = request.post(options);

// 查询所有年度计划
export const queryAllPlan = params =>
  $post(`/api/insurance-fw/h5/plan/queryAllPlan`, { ...params });
//查询子订单
export const queryMainOrder = mainOrderNo =>
  $post(`/api/trading-order/welfare/insurance/queryMainOrder/${mainOrderNo}`);

// 查询商品信息
export const queryProduct = params =>
  $get(`/api/trading-product/product/inner/sku/cms/${params.skuCode}/${params.skuVersion || ''}`);

// 查询福利总览的福利花费
export const queryAllPointsCost = params =>
  $post(`/api/insurance-fw/h5/plan/queryAllPointsCost`, { ...params });

// 福利计划
export const queryAnnualOptions = params =>
  $post(`/api/insurance-fw/h5/overview/title/list`, { ...params });

// 是否有年度福利计划
export const queryOverviewOrderList = params =>
  $post(`/api/insurance-fw/h5/overview/order/list`, { ...params });

// 快手积分明细
export const getAqBalanceDetail = params =>
  $post(`/api/insurance-fw/kuaishou/transaction/getUserPointsInfoWithOpenPeriod`, {
    ...params,
  });
