const product = {
  namespaced: true,
  state: {
    saleChannelId: '',
    policyState: false,
    source: '',
    bizCode: '',
  },
  mutations: {
    SET_SALE_CHANNEL_ID: (state, payload) => {
      state.saleChannelId = payload;
    },
    SET_BIZ_CODE: (state, payload) => {
      state.bizCode = payload;
    },
    SET_SOURCE: (state, payload) => {
      state.source = payload;
    },
    SET_POLICY_STATE: (state, payload) => {
      state.policyState = payload;
    },
  },
  actions: {},
};

export default product;
