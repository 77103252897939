import apis from '@/api';
import { Toast } from 'vant';
import { isMobile } from '@/utils';
import { setDefaultLanguage } from '@/mixins/switchLanguage';
import publicConfig from '@/constants/publicDefaultConfigData';

const config = {
  namespaced: true,
  state: {
    themeColor: '#bacf60',
    showPersonalCenter: false,
    personalCenterIcon: null,
    loginTheme: {},
    authTheme: {},
    supplyTheme: {},
    emailTheme: {},
    privacyConfig: {},
    logoSource: '',
    currentLanguage: '',
    showLanguageSwitch: false,
    countryCodeEnabled: false, // 选择区号
    paidText: {
      cn: '',
      en: '',
    }, // 待支付文案
    hasMergeLocale: false, // 标记已经合并过定制内容
    mergeLocale: {},
    showHomeIcon: true, // h5去往首页icon是否展示
    navigation: [],
  },
  getters: {
    showLanguageSwitch: state => state.showLanguageSwitch,
    privacyConfig: state => state.privacyConfig,
    paidText: state => state.paidText,
    hasMergeLocale: state => state.hasMergeLocale,
    mergeLocale: state => state.mergeLocale,
    showHomeIcon: state => state.showHomeIcon,
    navigation: state => state.navigation,
  },
  mutations: {
    SET_CONFIG: (state, payload) => {
      const lang = window.localStorage.getItem('_language') || payload?.defaultLang;
      setDefaultLanguage(lang);
      state.currentLanguage = lang;
      state.logoSource = payload?.comLogo;
      state.themeColor = payload?.mainTone;
      state.publicOpenFlag = payload?.publicOpenFlag;
      state.messageTemplateId = {
        msgIdCn: payload?.msgIdCn,
        msgIdEn: payload?.msgIdEn,
        abroadMsgIdCn: payload?.abroadMsgIdCn,
        abroadMsgIdEn: payload?.abroadMsgIdEn,
      };
      state.showPersonalCenter = payload?.personalShow === 1;
      state.personalCenterIcon = payload?.prePageConfig?.[lang]?.personalLogo;
      state.showLanguageSwitch = payload?.supportLangs?.length > 1;
      state.countryCodeEnabled = payload?.countryCodeEnabled;
      state.loginTheme = {
        show: payload?.prePageConfig?.[lang]?.loginPage?.itemList?.[0]?.avaliableStatus == 1,
        backgroundColor: payload?.prePageConfig?.[state.currentLanguage]?.loginPage?.backgroundTone,
        pageTitle: payload?.prePageConfig?.[lang]?.loginPage?.pageName,
        title: payload?.prePageConfig?.[state.currentLanguage]?.loginPage?.itemList?.[0]?.headLine,
        subtitle:
          payload?.prePageConfig?.[state.currentLanguage]?.loginPage?.itemList?.[0]?.subTitle,
        image:
          payload?.prePageConfig?.[state.currentLanguage]?.loginPage?.itemList?.[0]?.picList?.[0]?.[
            isMobile() ? 'picUrl' : 'pcPicUrl'
          ],
        hideCheck: payload?.prePageConfig?.[state.currentLanguage]?.loginPage?.showSla == 0,
      };
      state.authTheme = {
        show:
          payload?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]
            ?.avaliableStatus == 1,
        backgroundColor: payload?.prePageConfig?.[state.currentLanguage]?.authPage?.backgroundTone,
        pageTitle: payload?.prePageConfig?.[state.currentLanguage]?.authPage?.pageName,
        title: payload?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]?.headLine,
        subtitle:
          payload?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]?.subTitle,
        image:
          payload?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]?.picList?.[0]?.[
            isMobile() ? 'picUrl' : 'pcPicUrl'
          ],
        label: payload?.prePageConfig?.[state.currentLanguage]?.authPage?.authMainTitle,
        field: payload?.prePageConfig?.[state.currentLanguage]?.authPage?.authField,
        placeholder: payload?.prePageConfig?.[state.currentLanguage]?.authPage?.authText,
      };
      state.supplyTheme = {
        show:
          payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]
            ?.avaliableStatus == 1,
        backgroundColor: payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.backgroundTone,
        pageTitle: payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.pageName,
        title: payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]?.headLine,
        subtitle:
          payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]?.subTitle,
        image:
          payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]?.picList?.[0]?.[
            isMobile() ? 'picUrl' : 'pcPicUrl'
          ] || require(`@/assets/images/public/banner_${isMobile() ? 'h5' : 'pc'}.png`),
        showLogout: payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.showLogout === 1,
        doubleCheck: payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.doubleCheck === 1,
        doubleCheckConfig:
          payload?.prePageConfig?.[state.currentLanguage]?.fillPage?.doubleCheckModalConfig || {},
      };
      state.emailTheme = {
        show:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]
            ?.avaliableStatus == 1,
        backgroundColor: payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.backgroundTone,
        pageTitle: payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.pageName,
        title: payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]?.headLine,
        subtitle:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]?.subTitle,
        image:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]?.picList?.[0]?.[
            isMobile() ? 'picUrl' : 'pcPicUrl'
          ],
        showTip:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[1]
            ?.avaliableStatus == 1,
        tipTitle:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[1]?.headLine,
        tipInfo:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[1]?.remindContent,
        prefixPlaceholder:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.emailSuffixPlaceholder,
        suffix: payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.emailSuffix,
        codePlaceholder:
          payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.verifyCodePlaceholder,
        showLogout: payload?.prePageConfig?.[state.currentLanguage]?.mailPage?.showLogout === 1,
      };
      state.privacyConfig = payload?.prePageConfig?.[state.currentLanguage]?.privacyPolicyPage;
      state.paidText.cn = payload?.prePageConfig?.cn?.paidStateContent || '待承保';
      state.paidText.en = payload?.prePageConfig?.en?.paidStateContent || 'Underwriting';
    },
    SET_LANGUAGE: (state, payload) => {
      state.currentLanguage = payload;
      if (window.sessionStorage.getItem('_globalConfigCheck')) {
        const data = JSON.parse(window.sessionStorage.getItem('_globalConfigCheck'));
        state.personalCenterIcon = data?.prePageConfig?.[payload]?.personalLogo;
        state.loginTheme = {
          show: data?.prePageConfig?.[payload]?.loginPage?.itemList?.[0]?.avaliableStatus == 1,
          backgroundColor: data?.prePageConfig?.[state.currentLanguage]?.loginPage?.backgroundTone,
          pageTitle: data?.prePageConfig?.[payload]?.loginPage?.pageName,
          title: data?.prePageConfig?.[state.currentLanguage]?.loginPage?.itemList?.[0]?.headLine,
          subtitle:
            data?.prePageConfig?.[state.currentLanguage]?.loginPage?.itemList?.[0]?.subTitle,
          image:
            data?.prePageConfig?.[state.currentLanguage]?.loginPage?.itemList?.[0]?.picList?.[0]?.[
              isMobile() ? 'picUrl' : 'pcPicUrl'
            ],
          hideCheck: data?.prePageConfig?.[state.currentLanguage]?.loginPage?.showSla == 0,
        };
        state.authTheme = {
          show:
            data?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]
              ?.avaliableStatus == 1,
          backgroundColor: data?.prePageConfig?.[state.currentLanguage]?.authPage?.backgroundTone,
          pageTitle: data?.prePageConfig?.[state.currentLanguage]?.authPage?.pageName,
          title: data?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]?.headLine,
          subtitle: data?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]?.subTitle,
          image:
            data?.prePageConfig?.[state.currentLanguage]?.authPage?.itemList?.[0]?.picList?.[0]?.[
              isMobile() ? 'picUrl' : 'pcPicUrl'
            ],
          label: data?.prePageConfig?.[state.currentLanguage]?.authPage?.authMainTitle,
          field: data?.prePageConfig?.[state.currentLanguage]?.authPage?.authField,
          placeholder: data?.prePageConfig?.[state.currentLanguage]?.authPage?.authText,
        };
        state.supplyTheme = {
          show:
            data?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]
              ?.avaliableStatus == 1,
          backgroundColor: data?.prePageConfig?.[state.currentLanguage]?.fillPage?.backgroundTone,
          pageTitle: data?.prePageConfig?.[state.currentLanguage]?.fillPage?.pageName,
          title: data?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]?.headLine,
          subtitle: data?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]?.subTitle,
          image:
            data?.prePageConfig?.[state.currentLanguage]?.fillPage?.itemList?.[0]?.picList?.[0]?.[
              isMobile() ? 'picUrl' : 'pcPicUrl'
            ] || require(`@/assets/images/public/banner_${isMobile() ? 'h5' : 'pc'}.png`),
          showLogout: data?.prePageConfig?.[state.currentLanguage]?.fillPage?.showLogout === 1,
          doubleCheck: data?.prePageConfig?.[state.currentLanguage]?.fillPage?.doubleCheck === 1,
          doubleCheckConfig:
            data?.prePageConfig?.[state.currentLanguage]?.fillPage?.doubleCheckModalConfig || {},
        };
        state.emailTheme = {
          show:
            data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]
              ?.avaliableStatus == 1,
          backgroundColor: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.backgroundTone,
          pageTitle: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.pageName,
          title: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]?.headLine,
          subtitle: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]?.subTitle,
          image:
            data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[0]?.picList?.[0]?.[
              isMobile() ? 'picUrl' : 'pcPicUrl'
            ],
          showTip:
            data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[1]
              ?.avaliableStatus == 1,
          tipTitle: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[1]?.headLine,
          tipInfo:
            data?.prePageConfig?.[state.currentLanguage]?.mailPage?.itemList?.[1]?.remindContent,
          prefixPlaceholder:
            data?.prePageConfig?.[state.currentLanguage]?.mailPage?.emailSuffixPlaceholder,
          suffix: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.emailSuffix,
          codePlaceholder:
            data?.prePageConfig?.[state.currentLanguage]?.mailPage?.verifyCodePlaceholder,
          showLogout: data?.prePageConfig?.[state.currentLanguage]?.mailPage?.showLogout === 1,
        };
        state.privacyConfig = data?.prePageConfig?.[state.currentLanguage]?.privacyPolicyPage;
        state.paidText.cn = data?.prePageConfig?.cn?.paidStateContent || '待承保';
        state.paidText.en = data?.prePageConfig?.en?.paidStateContent || 'Underwriting';
      }
    },
    SET_HAS_MERGE_LOCALE: state => {
      state.hasMergeLocale = true;
    },
    SET_MERGE_LOCALE: (state, payload) => {
      state.mergeLocale = payload;
    },
    SET_SHOW_HOME_ICON: (state, payload) => {
      state.showHomeIcon = payload;
    },
    SET_NAVIGATION: (state, payload) => {
      const lang = window.localStorage.getItem('_language') || 'cn';
      const index = state?.navigation?.findIndex(i => i?.lang === lang);
      if (index > -1) {
        state.navigation[index] = payload?.[0] || {};
      } else {
        state?.navigation?.push(payload?.[0] || {});
      }
    },
  },
  actions: {
    getConfig({ commit }, companyCode) {
      return new Promise((resolve, reject) => {
        if (window.sessionStorage.getItem('_globalConfigCheck')) {
          resolve(JSON.parse(window.sessionStorage.getItem('_globalConfigCheck')));
        } else {
          apis
            .getGlobalConfig({ companyCode })
            .then(({ code, data, message }) => {
              if (code === '0') {
                if (data) {
                  commit('SET_CONFIG', data);
                  // 存储已获取基本配置的标志
                  const globalConfigCheck = {
                    isOpen: data?.status,
                    enableDesktop: data?.pcApplyFlag || 0,
                    isOpenPublic: data?.publicOpenFlag ?? 1,
                    isOpenPrivacy:
                      data?.prePageConfig?.[window.localStorage.getItem('_language') || 'cn']
                        ?.privacyPolicyPage?.collectShow,
                    ...data,
                  };
                  window.sessionStorage.setItem(
                    '_globalConfigCheck',
                    JSON.stringify(globalConfigCheck),
                  );
                  resolve(globalConfigCheck);
                } else {
                  // 福利派的兜底处理
                  commit('SET_CONFIG', publicConfig);
                  const globalConfigCheck = {
                    ...publicConfig,
                  };
                  window.sessionStorage.setItem(
                    '_globalConfigCheck',
                    JSON.stringify(globalConfigCheck),
                  );
                  resolve(globalConfigCheck);
                }
              } else if (['PUBLIC', 'HM'].includes(window.sessionStorage.getItem('_access'))) {
                // 公众号的兜底处理
                commit('SET_CONFIG', publicConfig);
                const globalConfigCheck = {
                  ...publicConfig,
                };
                window.sessionStorage.setItem(
                  '_globalConfigCheck',
                  JSON.stringify(globalConfigCheck),
                );
                resolve(globalConfigCheck);
              } else if (code === '-1019') {
                const globalConfigCheck = {
                  isOpen: 0,
                };
                window.sessionStorage.setItem(
                  '_globalConfigCheck',
                  JSON.stringify(globalConfigCheck),
                );
                resolve(globalConfigCheck);
              } else {
                Toast.fail(message);
                reject();
              }
            })
            .catch(() => {
              Toast.fail('网络异常，请重新进入');
              reject();
            });
        }
      });
    },
    getNavigation({ commit, state, rootGetters }, getCache = true) {
      return new Promise(resolve => {
        const lang = window.localStorage.getItem('_language') || 'cn';
        if (state?.navigation?.findIndex(i => i?.lang === lang) > -1 && getCache) {
          resolve();
          return;
        }
        try {
          const hideErrorToast = window.sessionStorage.getItem('_access') === 'HM';
          const insuredId = rootGetters?.['$_account/insuredId'] || null;
          apis
            .getConfigWithoutAuth(
              {
                pageType: 17,
                insuredId,
                companyCode: window.sessionStorage.getItem('_companyCode'),
                source: window.sessionStorage.getItem('_source'),
              },
              hideErrorToast,
            )
            .then(res => {
              if (res?.code === '0') {
                commit('SET_NAVIGATION', res?.data?.pageList || []);
                resolve();
              } else {
                resolve();
              }
            })
            .catch(() => {
              resolve();
            });
        } catch (err) {
          console.log('获取导航失败 >>> ', err);
          resolve();
        }
      });
    },
  },
};

export default config;
