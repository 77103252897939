import axios from 'axios';
import { Toast } from 'vant';
import store from '@/store';
import router from '@/router';
import i18n from '@/lang';
import { getQueryString } from '@/utils';
import { toLogin } from './login';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export const pendingMap = new Map();

// 生成每个请求唯一的键
const getPendingKey = config => {
  const { url, method, params = {}, data = {} } = config;
  if (['POST', 'PUT'].includes(method.toLocaleUpperCase())) {
    return [url, method, JSON.stringify(data)].join('&');
  } else {
    return [url, method, JSON.stringify(params)].join('&');
  }
};

// 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
const addPending = config => {
  const pendingKey = getPendingKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
};

// 删除重复的请求
const removePending = config => {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
};

// 创建loading实例
const loadingInstance = {
  count: 0,
  target: null,
};

// 关闭loading
const closeLoading = _customOptions => {
  if (!_customOptions.hideLoading && loadingInstance.count > 0) loadingInstance.count--;
  if (loadingInstance.count === 0) {
    NProgress.done();
    loadingInstance.target = null;
  }
};

// 处理异常
const httpErrorStatusHandler = error => {
  // 处理被取消的请求
  if (axios.isCancel(error)) return console.error('发起的重复请求：' + error.message);
  let message = '';
  if (error && error.response) {
    switch (error.response.status) {
      case 302:
        message = '接口重定向了';
        break;
      case 400:
        message = '参数不正确';
        break;
      case 401: {
        const res = toLogin();
        if (!res) message = '您未登录，或者登录已经超时，请先登录';
        break;
      }
      case 403:
        message = '您没有权限操作';
        break;
      case 404:
        message = `请求地址出错: ${error.response.config.url}`;
        break; // 在正确域名下
      case 408:
        message = '请求超时';
        break;
      case 409:
        message = '系统已存在相同数据';
        break;
      case 500:
        message = '服务器内部错误';
        break;
      case 501:
        message = '服务未实现';
        break;
      case 502:
        message = '网关错误';
        break;
      case 503:
        message = '服务不可用';
        break;
      case 504:
        message = '服务暂时无法访问，请稍后再试';
        break;
      case 505:
        message = 'HTTP版本不受支持';
        break;
      default:
        message = '异常问题，请联系管理员';
        break;
    }
  }
  if (error.message.includes('timeout')) message = '请求超时，请稍后再试';
  if (error.message.includes('Network'))
    message = window.navigator.onLine ? '服务器异常，请稍后再试' : i18n.t('GLOBAL_200');

  message && Toast(message);
};

// 登录信息
const getLoginInfo = () => {
  const loginInfo = JSON.parse(window.localStorage.getItem('loginMsg'));
  return {
    token: loginInfo?.jwtToken || getQueryString('token', false),
    userId: loginInfo?.userId,
    companyCode: window.sessionStorage.getItem('_companyCode') || null,
  };
};

const Axios = (axiosConfig, customOptions) => {
  const lang = ['cn', 'en'].includes(window.localStorage.getItem('_language'))
    ? window.localStorage.getItem('_language')
    : 'cn';
  const service = axios.create({
    baseURL: process.env.VUE_APP_INTERFACE_ORIGIN,
    timeout: 15000,
    withCredentials: false,
    headers: {
      'Content-Type':
        customOptions?.type?.toLocaleUpperCase() === 'FORM'
          ? 'multipart/form-data'
          : 'application/json;charset=utf-8',
      lang,
      language: lang === 'cn' ? 'zh-CN' : 'en-US', // 修改手机号 接口使用
      ...customOptions?.header,
    },
  });

  // 是否开启取消重复请求
  const _customOptions = {
    cancelRepeatRequest: true, // 是否取消重复请求
    reductDataFormat: true, // 是否开启简洁的数据结构响应
    hideCodeMessage: false, // 是否隐藏code不为0时的信息提示
    loading: true, // 是否开启loading层效果
    ...customOptions,
  };

  service.interceptors.request.use(
    config => {
      removePending(config);
      _customOptions.cancelRepeatRequest && addPending(config);
      // 创建loading实例
      if (!_customOptions?.hideLoading) {
        loadingInstance.count++;
        if (loadingInstance.count === 1) {
          loadingInstance.target = NProgress.start();
        }
      }
      // 添加token，typeof window !== 'undefined' 是为了兼容ssr
      if (getLoginInfo() && typeof window !== 'undefined') {
        config.headers = { ...config.headers, ...getLoginInfo() };
      }
      return config;
    },
    error => Promise.reject(error),
  );

  service.interceptors.response.use(
    response => {
      removePending(response.config);
      !_customOptions.hideLoading && closeLoading(_customOptions);
      if (!_customOptions.errorHideToast && response.data?.code != 0) {
        const code = response.data?.code;
        if ([401, 2001, 4001].includes(parseInt(code))) {
          toLogin();
        } else if (response.config?.url.indexOf('/insurance-fw') > -1 && code == '-1005') {
          // 针对取消年度计划做的异常处理
          Toast.fail(response.data.message || '数据异常');
          setTimeout(() => {
            const openingTime = store.state.$_openingTime.openingTime;
            const chooseType = this.openingTime?.[0]?.openPeriodType || '';
            if (openingTime?.length === 1) {
              router.replace({
                name: 'choose',
                chooseType,
              });
              return;
            }
            router.replace({
              name: 'homepage',
            });
          }, 3000);
        } else {
          // 异常统一处理，公众号环境下 离职 & 企业未开启  支付下单接口不用提示
          if (!['BX-FW-2051', '-1019', '35020', '35030', '-1008'].includes(code)) {
            // Toast.fail(response.data.message || '数据异常');
            Toast(response.data.message || '数据异常');
          }
          return response.data;
        }
      }
      return _customOptions.reductDataFormat ? response.data : response;
    },
    error => {
      error.config && removePending(error.config);
      _customOptions.loading && closeLoading(_customOptions);
      // 处理错误状态码
      !_customOptions?.hideCodeMessage &&
        !_customOptions?.errorHideToast &&
        httpErrorStatusHandler(error);
      return Promise.reject(error);
    },
  );

  return service(axiosConfig);
};

class Request {
  get = () => (url, params = {}, options = {}) =>
    Axios(
      {
        url,
        method: 'get',
        params,
      },
      options,
    );

  post = () => (url, params = {}, options = {}) =>
    Axios(
      {
        url,
        method: 'post',
        data: params,
      },
      options,
    );

  put = () => (url, params = {}, options = {}) =>
    Axios(
      {
        url,
        method: 'put',
        data: params,
      },
      options,
    );
}

export default new Request();
