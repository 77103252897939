import { isBaidu } from '@/constants/source';
import { isDingTalk } from '@/utils';
import * as dd from 'dingtalk-jsapi';
import i18n from '@/lang';

export default {
  install: function(Vue) {
    var setWechatTitle = function(title, img) {
      if (title === undefined || window.document.title === title) {
        return;
      }
      if (!isBaidu()) {
        document.title = title;
      }
      var mobile = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(mobile)) {
        var iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        // 替换成站标favicon路径或者任意存在的较小的图片即可，支付宝小程序引用默认空白的base64图片会有安全警告
        var _img = /alipay/.test(mobile)
          ? img
          : img || 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        _img && iframe.setAttribute('src', _img);
        var iframeCallback = function() {
          setTimeout(function() {
            iframe.removeEventListener('load', iframeCallback);
            document.body.removeChild(iframe);
          }, 0);
        };
        iframe.addEventListener('load', iframeCallback);
        document.body.appendChild(iframe);
      }
    };
    Vue.directive('wechat-title', function(el, binding) {
      // 设置页面标题
      if (isDingTalk()) {
        dd.ready(() => {
          dd.biz.navigation.setTitle({
            title: binding.value,
          });
        });
      } else if (window.kara) {
        // 设置亚信信部落标题
        window.kara?.setTitle({
          title: binding.value,
        });
      } else if (isBaidu()) {
        // 兼容如流
        document.title = i18n.t('EXTRA_019');
      } else {
        document.addEventListener(
          'JSSDKReady',
          function() {
            if (window.kara) {
              window.kara?.setTitle({
                title: binding.value,
              });
            }
          },
          false,
        );
        setWechatTitle(binding.value, el.getAttribute('img-set') || null);
      }
    });
  },
};
