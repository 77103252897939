var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAAutoQuicker
    ? _c(
        "div",
        { staticClass: "watermark" },
        _vm._l(7, function (item, index) {
          return _c(
            "div",
            { key: `row_${index}`, staticClass: "row" },
            _vm._l(2, function (item, index) {
              return _c(
                "div",
                { key: `column_${index}`, staticClass: "column" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.name) +
                        " " +
                        _vm._s(
                          _vm.clientId || _vm.phoneSuffix || _vm.idNumberSunffix
                        )
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }