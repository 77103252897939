<template>
  <div class="header" v-if="bottomNavigationShow">
    <div class="head" :style="{ justifyContent: justifyAlign ? 'center' : 'flex-end' }">
      <div class="navList" v-if="(navigationData.picList || []).length > 0">
        <div
          class="nav align-center pointer"
          v-for="(nav, index) in navigationData.picList"
          :key="`nav${index}`"
          @click="skipAction(nav, navigationData.itemKey, 'navs')"
          :style="getColor(nav)"
        >
          <span>{{ nav.configName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mixins from '@/mixins';
import SwitchLanguage from '@/components/Desktop/switchLanguage.vue';
import { isByteDance } from '@/constants/source';
export default {
  name: 'BottomNavBar',
  mixins: [mixins],
  props: {
    findVal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultColor: '#333333FF',
    };
  },
  computed: {
    ...mapGetters(['themeColor', 'logoSource']),
    ...mapGetters('$_config', ['navigation', 'showLanguageSwitch']),
    justifyAlign() {
      const list = ['confirm'];
      return this.showLanguageSwitch && (!list?.includes(this.$route.name) || isByteDance());
    },
    bottomNavigationShow() {
      const lang = window.localStorage.getItem('_language') || 'cn';
      return +this.navigation?.find(i => i?.lang === lang)?.itemList?.[0]?.avaliableStatus === 1;
    },
    navigationData() {
      const lang = window.localStorage.getItem('_language') || 'cn';
      return this.navigation?.find(i => i?.lang === lang)?.itemList?.[0] || { picList: [] };
    },
  },
  created() {
    this.getNavigation();
  },
  methods: {
    ...mapActions('$_config', ['getNavigation']),
    getColor(val) {
      return this.findVal?.redirectType == val?.redirectType
        ? { color: this.themeColor, background: '#F5F9FCFF' }
        : { color: this.defaultColor };
    },
  },
  components: { SwitchLanguage },
};
</script>

<style lang="less" scoped>
.header {
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(68, 134, 251, 0.1);
  width: 100%;
  min-width: var(--layoutWidth);
  .head {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    align-items: center;
    width: var(--layoutWidth);
    height: 60px;
    margin: auto;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 32px;
    }
  }
  .navList {
    display: flex;
    .nav {
      padding: 5px 0 3px;
      width: 120px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
