<template>
  <div class="desktop">
    <div class="desktop-content">
      <slot></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'GlobalBody',
};
</script>

<style lang="less" scoped>
.desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: var(--layoutWidth);
  height: 100%;
  overflow: auto;
  background-color: #f5f7fa;
  position: relative;
  &-content {
    width: var(--layoutWidth);
    height: 100%;
  }
  &,
  :deep(div) {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #8d8d8d;
    }
    &::-webkit-scrollbar-track {
      opacity: 0;
    }
  }
  :deep(.scrollbar) {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #8d8d8d;
    }
    &::-webkit-scrollbar-track {
      opacity: 0;
    }
  }
}
</style>
