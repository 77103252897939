import apis from '@/api';

const user = {
  namespaced: true,
  state: {
    openid: '',
    isHasOrder: false,
    gender: 'MALE',
  },
  mutations: {
    SET_OPEN_ID: (state, payload) => {
      state.openid = payload;
    },
    // 用户是否存在福利订单
    SET_HAS_ORDER: (state, payload) => {
      state.isHasOrder = payload;
    },
    // 存储性别
    SET_GENDER: (state, payload) => {
      state.gender = payload == 2 ? 'FEMALE' : 'MALE';
    },
  },
  actions: {
    getGender({ commit }) {
      return new Promise(resolve => {
        if (window.sessionStorage.getItem('_gottenGender')) {
          resolve();
        } else {
          apis
            .checkAuth(
              {
                source: window.sessionStorage.getItem('_source'),
                companyCode: window.sessionStorage.getItem('_companyCode'),
              },
              false,
            )
            .then(({ data }) => {
              data && data?.gender && commit('SET_GENDER', data?.gender);
              // 存储已获取基本配置的标志
              window.sessionStorage.setItem('_gottenGender', true);
              resolve();
            })
            .catch(() => {
              window.sessionStorage.setItem('_gottenGender', true);
              resolve();
            });
        }
      });
    },
  },
};

export default user;
