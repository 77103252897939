import {
  Toast,
  Overlay,
  Icon,
  Popup,
  Picker,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Tab,
  Tabs,
  List,
  Area,
  Swipe,
  SwipeItem,
  Lazyload,
  Button,
  Form,
  Field,
  Dialog,
  Divider,
  Uploader,
  ActionSheet,
  Sticky,
  Loading,
  Row,
  Col,
  PullRefresh,
  IndexBar,
  IndexAnchor,
  Cell,
  Radio,
  RadioGroup,
  Progress,
  Checkbox,
  Search,
  CellGroup,
  Image,
} from 'vant';
let VantPlugin = {};

VantPlugin.install = Vue => {
  Vue.use(Toast)
    .use(Overlay)
    .use(Icon)
    .use(Popup)
    .use(Picker)
    .use(DatetimePicker)
    .use(Collapse)
    .use(CollapseItem)
    .use(Tab)
    .use(Tabs)
    .use(List)
    .use(Area)
    .use(Swipe)
    .use(SwipeItem)
    .use(Button)
    .use(Lazyload)
    .use(Form)
    .use(Field)
    .use(Divider)
    .use(Dialog)
    .use(Uploader)
    .use(ActionSheet)
    .use(Sticky)
    .use(Loading)
    .use(Row)
    .use(Col)
    .use(PullRefresh)
    .use(Cell)
    .use(IndexBar)
    .use(Radio)
    .use(RadioGroup)
    .use(IndexAnchor)
    .use(Progress)
    .use(Checkbox)
    .use(Search)
    .use(CellGroup)
    .use(Image);
};

export default VantPlugin;
