import account from './account';
import config from './config';
import user from './user';
import product from './product';
import insured from './insured';
import openingTime from './openingTime';
import projecData from './projecData';
import balance from './balance';
import productDetail from './productDetail';
import healthManagement from './healthManagement';

export default {
  $_account: account,
  $_config: config,
  $_user: user,
  $_product: product,
  $_insured: insured,
  $_openingTime: openingTime,
  $_projecData: projecData,
  $_balance: balance,
  $_productDetail: productDetail,
  $_healthManagement: healthManagement,
};
