<template>
  <!-- <img :src="switchSrc" class="switch-language pointer" :style="switchStyle" @click="onSwitch" /> -->
  <drag-click-item :init-top="8" :width="100" :source="switchSrc" @on-click="onSwitch" />
</template>

<script>
import mixins from '@/mixins/switchLanguage';
import DragClickItem from '@/components/Mobile/dragClickItem.vue';
export default {
  mixins: [mixins],
  props: {
    type: String,
  },
  components: { DragClickItem },
};
</script>

<style lang="less" scoped>
.switch-language {
  width: 28px;
  height: 28px;
}
</style>
