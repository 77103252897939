var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-overlay",
    { style: { zIndex: _vm.zIndex }, attrs: { show: _vm.isShow } },
    [
      _c(
        "div",
        {
          staticClass: "custom-dialog",
          style: { transform: _vm.size === "small" ? "scale(0.9)" : "unset" },
        },
        [
          _vm.isClose
            ? _c("img", {
                staticClass: "custom-dialog__close",
                attrs: {
                  src: require("@/assets/images/common/close.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.isShow = false
                  },
                },
              })
            : _vm._e(),
          _vm.title
            ? _c(
                "div",
                {
                  staticClass: "custom-dialog__title mb15",
                  style: { textAlign: _vm.titleAlign },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
          _vm.message
            ? [
                _vm.isHtml
                  ? _c("div", {
                      staticClass: "custom-dialog__message mb24",
                      domProps: { innerHTML: _vm._s(_vm.message) },
                    })
                  : _c(
                      "p",
                      {
                        staticClass: "custom-dialog__message mb24",
                        style: { textAlign: _vm.messageAlign },
                      },
                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                    ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "custom-dialog__button flex-row" },
            [
              _vm.isButtonMultiple
                ? [
                    _c(
                      "button",
                      {
                        staticClass:
                          "custom-dialog__button-item custom-dialog__button-item--cancel mr8",
                        style: {
                          color: _vm.themeColor,
                          borderColor: _vm.themeColor,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.actionToClick(false)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.cancelButtonText) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass:
                          "custom-dialog__button-item custom-dialog__button-item--confirm",
                        style: { backgroundColor: _vm.themeColor },
                        on: {
                          click: function ($event) {
                            return _vm.actionToClick(true)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.confirmButtonText) + " ")]
                    ),
                  ]
                : [
                    _vm.showCancelButton
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "custom-dialog__button-item custom-dialog__button-item--cancel mr8",
                            style: {
                              color: _vm.themeColor,
                              borderColor: _vm.themeColor,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.actionToClick(false)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.cancelButtonText) + " ")]
                        )
                      : _vm._e(),
                    _vm.showConfirmButton
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "custom-dialog__button-item custom-dialog__button-item--confirm",
                            style: { backgroundColor: _vm.themeColor },
                            on: {
                              click: function ($event) {
                                return _vm.actionToClick(true)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.confirmButtonText) + " ")]
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }