import {
  checkIdNo,
  checkOfficerNo,
  checkBirthNo,
  checkForeignerNo,
  checkPassNo,
  checkHMNo,
  checkTWNo,
  requireNo,
} from '@/utils/reg';
import i18n from '@/lang';
import store from '@/store';

// 证件类型
export const certificateOptions = () => [
  {
    value: 'IDCARD',
    reg: checkIdNo,
    transfer: 1,
    text: i18n.t('GLOBAL_068'),
  },
  {
    value: 'PASSCARD',
    reg: checkPassNo,
    transfer: 2,
    text: i18n.t('GLOBAL_069'),
  },
  {
    value: 'MILITARYID',
    reg: checkOfficerNo,
    transfer: 3,
    text: i18n.t('GLOBAL_071'),
  },
  {
    value: 'HOUSEHOLD_REGISTER',
    reg: checkBirthNo,
    transfer: 4,
    text: i18n.t('GLOBAL_072'),
  },
  {
    value: 'BIRTH_CERTIFICATE',
    reg: checkBirthNo,
    transfer: 10,
    text: i18n.t('GLOBAL_070'),
  },
  {
    value: 'TAIWANPERMIT',
    reg: checkTWNo,
    transfer: 13,
    text: i18n.t('GLOBAL_074'),
  },
  {
    value: 'HMTRESIDENT',
    reg: checkHMNo,
    transfer: 12,
    text: i18n.t('GLOBAL_073'),
  },
  {
    value: 'ALIENRESIDENCEPERMIT',
    reg: checkForeignerNo,
    transfer: 11,
    text: i18n.t('GLOBAL_075'),
  },
  {
    text: i18n.t('GLOBAL_076'),
    value: 'HK_MACO_LIVE',
    transfer: 15,
    reg: checkHMNo,
  },
  {
    text: i18n.t('GLOBAL_077'),
    value: 'TWN_LIVE',
    transfer: 16,
    reg: checkTWNo,
  },
  {
    value: 'OTHER',
    reg: requireNo,
    transfer: 9,
    text: i18n.t('GLOBAL_078'),
  },
];

// 商品适用人群
export const forTheCrowdOptions = [
  {
    value: 1,
    transfer: '1',
    text: '本人',
  },
  {
    value: 2,
    transfer: '2',
    text: '配偶',
  },
  {
    value: 4,
    transfer: '4',
    text: '子女',
  },
  {
    value: 3,
    transfer: '3',
    text: '父母',
  },
  {
    value: 5,
    transfer: '9',
    text: '配偶父母',
  },
];

// 被保险人属性
export const insuredRelationshipOptions = () => [
  {
    value: 'SELF', // 本人
    code: '1', // 商品人群枚举
    personCode: '1', //人员关系枚举
    text: i18n.t('GLOBAL_006'),
  },
  {
    value: 'MATE', // 配偶
    code: '2',
    personCode: '3',
    text: i18n.t('GLOBAL_007'),
  },
  {
    value: 'CHILDREN', // 子女
    code: '4',
    personCode: '4',
    text: i18n.t('GLOBAL_008'),
  },
  {
    value: 'PARENT', // 父母
    code: '3',
    personCode: '2',
    text: i18n.t('GLOBAL_009'),
  },
  {
    value: 'MATE_PARENT', // 配偶父母
    code: '5',
    personCode: '9',
    text: i18n.t('GLOBAL_012'),
  },
  {
    value: 'OTHER', // 其他
    code: '', //商品没有其他
    personCode: '5',
    text: i18n.t('GLOBAL_015'),
  },
];

// 性别
export const genderOptions = () => [
  {
    value: 'MALE',
    text: i18n.t('GLOBAL_079'),
    transfer: [1],
    code: 1,
  },
  {
    value: 'FEMALE',
    text: i18n.t('GLOBAL_080'),
    transfer: [2],
    code: 2,
  },
  // {
  //   value: 'UN_KNOWN',
  //   text: '未知',
  //   transfer: [3, 9],
  // },
];

// 福利金明细状态
export const balanceDetailStatusList = [
  {
    value: 10,
    text: '支付',
  },
  {
    value: 20,
    text: '退款',
  },
  {
    value: 30,
    text: '领取',
  },
  {
    value: 40,
    text: '失效',
  },
];

// 福利金类型
export const categoryStatusList = [
  {
    value: 10,
    text: '保险',
  },
  {
    value: 20,
    text: '医疗',
  },
];

// 保单状态
export const policyStatusList = () => [
  {
    value: '00',
    text: i18n.t('GLOBAL_026'),
    url: require('@/assets/images/policyDetail/canceled.png'),
  },
  {
    value: '01',
    text: i18n.t('GLOBAL_022'),
  },
  {
    value: '02',
    text: i18n.t('GLOBAL_023'),
    url: require('@/assets/images/policyDetail/to_be_paid.png'),
  },
  {
    value: '03',
    text: i18n.t('GLOBAL_024'),
    url: require('@/assets/images/policyDetail/to_be_insured.png'),
  },
  {
    value: '04',
    text: i18n.t('GLOBAL_025'),
    url: require('@/assets/images/policyDetail/completed.png'),
  },
  {
    value: '05',
    text: i18n.t('GLOBAL_027'),
    url: require('@/assets/images/policyDetail/for_a_refund.png'),
  },
  {
    value: '06',
    text: i18n.t('GLOBAL_028'),
    url: require('@/assets/images/policyDetail/refunded.png'),
  },
];
// 字节定制订单状态
export const ByteDancePolicyStatusList = () => [
  {
    value: '00',
    text: i18n.t('GLOBAL_026'),
    url: require('@/assets/images/custom/ByteDance/canceled.png'),
  },
  {
    value: '01',
    text: i18n.t('GLOBAL_022'),
  },
  {
    value: '02',
    text: i18n.t('GLOBAL_023'),
    url: require('@/assets/images/custom/ByteDance/to_be_paid.png'),
  },
  {
    value: '03',
    text: i18n.t('GLOBAL_024'),
    url: require('@/assets/images/custom/ByteDance/to_be_insured.png'),
  },
  {
    value: '04',
    text: i18n.t('GLOBAL_025'),
    url: require('@/assets/images/custom/ByteDance/completed.png'),
  },
  {
    value: '05',
    text: i18n.t('GLOBAL_027'),
    url: require('@/assets/images/custom/ByteDance/for_a_refund.png'),
  },
  {
    value: '06',
    text: i18n.t('GLOBAL_028'),
    url: require('@/assets/images/custom/ByteDance/refunded.png'),
  },
];
// 保单状态转换
// ALL('01', '全部'),
// PAY('02', '待支付'),
// INSURE('03', '待承保'),
// DONE('04', '已完成'),
// CANCEL('00', '已取消'),
// REFUND('05', '待退款'),
// REFUNDED('06', '已退款'),
// UNKNOWN('0', '未知');
export const statusList = [
  {
    code: '01',
    value: 'ALL',
    text: '全部',
  },
  {
    code: '02',
    value: 'PAY',
    text: '待支付',
  },
  {
    code: '03',
    value: 'INSURE',
    text: '待承保',
  },
  {
    code: '04',
    value: 'DONE',
    text: '已完成',
  },
  {
    code: '05',
    value: 'REFUND',
    text: '待退款',
  },
  {
    code: '06',
    value: 'REFUNDED',
    text: '已退款',
  },
  {
    code: '00',
    value: 'CANCEL',
    text: '已取消',
  },
  {
    code: '0',
    value: 'UNKNOWN',
    text: '未知',
  },
];

/**
 * 投保文件
 */
export const insuranceDocumentsOptions = {
  healthAdvice: '健康告知',
  productLiability: '保险条款',
  policyDetails: '产品详情',
  claimNotice: '理赔须知',
  healthServicesManual: '健康服务手册',
  insureNotice: '投保须知',
  teamInsureAuthDecl: '团体投保授权声明',
  teamInsuredPlan: '团体保障计划书',
};

/**
 * 支付方式
 */
export const paymentTypeOptions = () => ({
  FLEXIBLE_WELFARE_PAY: i18n.t('GLOBAL_082'),
  FLEXIBLE_SALARY_DEDUCTION: i18n.t('GLOBAL_083'),
  WELFARE_SALARY_COMBINATION: i18n.t('GLOBAL_087'),
  WECHAT: i18n.t('GLOBAL_084'),
  COMBINATION_PAY: i18n.t('GLOBAL_086'),
  ALIPAY: i18n.t('GLOBAL_085'),
  OFFlINE_PAY: i18n.t('GLOBAL_088'),
});

// 与本人关系
export const relationshipEnum = (showSelf = true, basicColor = '#BACF60', showTypes = []) => {
  let arr = [
    {
      name: i18n.t('GLOBAL_006'),
      code: 'SELF',
      value: '1',
      color: basicColor,
      background: 'linear-gradient(360deg, #3EC595 0%, #9FE6CF 100%)',
      type: 1,
    },
    {
      name: i18n.t('GLOBAL_007'),
      code: 'MATE',
      value: '3',
      color: '#73A0FA',
      background: 'linear-gradient(315deg, #F8935F 0%, #F58E54 100%)',
      type: 3,
    },
    {
      name: i18n.t('GLOBAL_009'),
      code: 'PARENT',
      value: '2',
      color: '#EB7E65',
      background: 'linear-gradient(280deg, #7B9EFD 0%, #7375F2 100%)',
      type: 2,
    },
    {
      name: i18n.t('GLOBAL_012'),
      code: 'MATE_PARENT',
      value: '6',
      color: '#EB7E65',
      background: 'linear-gradient(280deg, #7B9EFD 0%, #7375F2 100%)',
      type: 9,
    },
    {
      name: i18n.t('GLOBAL_008'),
      code: 'CHILDREN',
      value: '4',
      color: '#F7C739',
      background: 'linear-gradient(135deg, #F6A5A5 0%, #EF7372 100%)',
      type: 4,
    },
    {
      name: i18n.t('GLOBAL_015'),
      code: 'OTHER',
      value: '5',
      color: '#11A663',
      background: '#11A663',
      type: 5,
    },
  ];
  if (!showSelf) {
    arr.shift();
  }
  if (showTypes?.length > 0) {
    return arr.filter(i => showTypes?.includes(i?.type));
  }
  return arr;
};

// 性别
export const genderEnum = (limitShow = true) => {
  let arr = [
    { name: i18n.t('GLOBAL_079'), code: 1, value: 1 },
    { name: i18n.t('GLOBAL_080'), code: 2, value: 2 },
    { name: i18n.t('GLOBAL_081'), code: 3, value: 3 },
  ];
  if (limitShow) {
    arr.pop();
    return arr;
  }
  return arr;
};

// 证件类型
export const credentialsTypeEnum = () => [
  { name: i18n.t('GLOBAL_068'), code: 'IDCARD', value: 1 },
  { name: i18n.t('GLOBAL_069'), code: 'PASSCARD', value: 2 },
  { name: i18n.t('GLOBAL_071'), code: 'MILITARYID', value: 3 },
  { name: i18n.t('GLOBAL_072'), code: 'HOUSEHOLD_REGISTER', value: 4 },
  { name: i18n.t('GLOBAL_070'), code: 'BIRTH_CERTIFICATE', value: 10 },
  { name: i18n.t('GLOBAL_075'), code: 'ALIENRESIDENCEPERMIT', value: 11 },
  { name: i18n.t('GLOBAL_073'), code: 'HMTRESIDENT', value: 12 },
  { name: i18n.t('GLOBAL_074'), code: 'TAIWANPERMIT', value: 13 },
  { name: i18n.t('GLOBAL_076'), code: 'HK_MACO_LIVE', value: 15 },
  { name: i18n.t('GLOBAL_077'), code: 'TWN_LIVE', value: 16 },
  { name: i18n.t('GLOBAL_078'), code: 'OTHER', value: 9 },
];

// 弹福开窗类型 ANNUALLY：年度开窗；ANNUALLY_MULTI：一年多开；MONTHLY：月度开窗；LIFE_EVENTS：人生事件
export const WELFARE_LIST = [
  {
    value: 'ANNUALLY',
    code: '19',
    text: '年度注册',
  },
  {
    value: 'ANNUALLY_MULTI',
    code: '20',
    text: '一年多次开窗',
  },
  {
    value: 'MONTHLY',
    code: '21',
    text: '月度注册',
  },
  {
    value: 'LIFE_EVENTS',
    code: '22',
    text: '人生事件',
  },
];
// 数据字典 code 200027
export const ordersTagslist = [
  {
    value: 1,
    desc: 'NO_SIGN',
    desc_cn: '无标签',
    img: '',
  },
  {
    value: 2,
    desc: 'REPUDIATION',
    desc_cn: '保司拒保',
    img: require('@/assets/images/welfareOverview/pauls-no.png'),
    imgPc: require('@/assets/images/welfareOverview/pauls-no-pc.png'),
  },
  {
    value: 3,
    desc: 'PART_REPUDIATION',
    desc_cn: '保司部分拒保',
    img: require('@/assets/images/welfareOverview/pauls-part.png'),
    imgPc: require('@/assets/images/welfareOverview/pauls-part-pc.png'),
  },
  {
    value: 4,
    desc: 'ADJUST_COVERAGE',
    desc_cn: '方案调整',
    img: require('@/assets/images/welfareOverview/tuning.png'),
    imgPc: require('@/assets/images/welfareOverview/tuning-pc.png'),
  },
  {
    value: 5,
    desc: 'REFUNDED',
    desc_cn: '已退款',
    img: require('@/assets/images/welfareOverview/refunds.png'),
    imgPc: require('@/assets/images/welfareOverview/refunds-pc.png'),
  },
  {
    value: 6,
    desc: 'PART_REFUNDS',
    desc_cn: '部分退款',
    img: require('@/assets/images/welfareOverview/part-refunds.png'),
    imgPc: require('@/assets/images/welfareOverview/part-refunds-pc.png'),
  },
  {
    value: 7,
    desc: 'SEPARATION',
    desc_cn: '离职退费',
    img: require('@/assets/images/welfareOverview/separation.png'),
    imgPc: require('@/assets/images/welfareOverview/separation-pc.png'),
  },
  {
    value: 8,
    desc: 'SPECIAL',
    desc_cn: '特殊操作',
    img: require('@/assets/images/welfareOverview/special.png'),
    imgPc: require('@/assets/images/welfareOverview/special-pc.png'),
  },
];

// 区号
export const getCountryCodeOptions = () => [
  { name: i18n.t('GLOBAL_089'), value: '86' },
  { name: i18n.t('GLOBAL_090'), value: '852' },
  { name: i18n.t('GLOBAL_091'), value: '853' },
  { name: i18n.t('GLOBAL_092'), value: '886' },
  { name: i18n.t('GLOBAL_093'), value: '1' },
  { name: i18n.t('GLOBAL_094'), value: '1' },
  { name: i18n.t('GLOBAL_095'), value: '44' },
  { name: i18n.t('GLOBAL_096'), value: '60' },
  { name: i18n.t('GLOBAL_097'), value: '61' },
  { name: i18n.t('GLOBAL_098'), value: '81' },
];

// 国籍
export const nationalityEnum = () => {
  if (i18n?.locale === 'en') {
    return store.state?.$_projecData?.nationalityEN;
  } else {
    return store.state?.$_projecData?.nationalityCN;
  }
};
