import request from '@/utils/request';
const options = {};

const $post = request.post(options);

const PREFIX = '/api';

// 查询支付结果信息
export const getPayResult = mainOrderNo =>
  $post(`${PREFIX}/trading-order/welfare/payment/info/${mainOrderNo}`);
