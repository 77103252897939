import request from '@/utils/request';
const options = {};

const $get = request.get(options);
const $post = request.post(options);

// 获取sso解密信息
export const getSsoInfo = params =>
  $get('/api/jkb-user-center/v1/open/third/getThirdUserJWTToken', params);

// 获取第三方跳转链接
export const getOuterThirdUrl = params =>
  $get('/api/jkb-user-center/v1/open/third/parseJumpUrl', params);

// 获取人员分类
export const getUserCategory = params =>
  $post(`/api/insurance-fw/h5/comVisibleClassifyType/open/queryComVisibleClassifyType`, params, {
    errorHideToast: true,
  });
