import Apis from '@/api';
import { isCatl } from '@/constants/source';
import { getQueryString, removeUrlParam } from '@/utils';

export const toLogin = () => {
  if (+window.sessionStorage.getItem('_isJian1bao') === 1) {
    window.sessionStorage.removeItem('_companyCode');
    const redirectUrl = getQueryString('redirectUrl', false);
    const url = removeUrlParam(window.location.href, 'token');
    const _redirectUrl = redirectUrl ? redirectUrl : url;
    window.location.replace(
      `${
        window.location.origin
      }/spcare-products/health-fw-static/middleware?pageType=1&redirectUrl=${encodeURIComponent(
        _redirectUrl,
      )}`,
    );
    return true;
  }
  if (window.sessionStorage.getItem('_middleware') && !isCatl()) {
    window.location.replace(window.sessionStorage.getItem('_middleware'));
    return true;
  }
  if (window.sessionStorage.getItem('_companyCode')) {
    window.location.replace(
      `${
        window.location.origin
      }/spcare-products/health-fw-static/middleware?companyCode=${window.sessionStorage.getItem(
        '_companyCode',
      )}&pageType=1&source=${window.sessionStorage.getItem('_source')}`,
    );
    return true;
  }
  return false;
};

export const toLogout = async () => {
  try {
    await Apis.logout();
  } catch (error) {
    console.log('退出登录异常 >>> ', error);
  } finally {
    window.localStorage.removeItem('SP_TOKEN');
    window.localStorage.removeItem('loginMsg');
    window.sessionStorage.removeItem('_form1');
    window.sessionStorage.removeItem('_fw_store');
    window.sessionStorage.removeItem('_globalConfigCheck');
    if (+window.sessionStorage.getItem('_isJian1bao') === 1) {
      window.localStorage.removeItem('_language');
      window.sessionStorage.removeItem('_globalConfigCheck');
      window.sessionStorage.removeItem('_companyCode');
      window.localStorage.setItem('_forbidSilentLogin', 1); // 存储不要微信授权静默登录的标识
    }
    toLogin();
  }
};
