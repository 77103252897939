import { Row, Col, Form, FormItem, Input, Select, Option, DatePicker } from 'element-ui';
let ElementPlugin = {};

ElementPlugin.install = Vue => {
  Vue.use(Row)
    .use(Col)
    .use(Form)
    .use(FormItem)
    .use(Input)
    .use(Select)
    .use(Option)
    .use(DatePicker);
};

export default ElementPlugin;
