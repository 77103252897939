import request from '@/utils/request';
const options = {};

const $get = request.get(options);
const $post = request.post(options);

const PREFIX = '/api/insurance-fw';

// 根据configId查companyCode
export const getCompanyCodeByConfigId = params =>
  $post(`${PREFIX}/open/h5/getComConfigByCondigId`, { ...params });

// 获取基本配置信息（主色调等）
export const getGlobalConfig = params =>
  $post(`${PREFIX}/open/h5/comConfig/displayConfig/v2`, { ...params });

// 获取导航栏信息
export const getNavsConfig = (params, errorHideToast) =>
  $post(
    `${PREFIX}/open/h5/comConfig/navigation/v2`,
    { ...params },
    { hideLoading: true, errorHideToast },
  );

// 获取落地页产品列表
// pageType枚举 >>> 1:首页, 2:保险落地页,3:体检落地页, 4:个人中心, 5:默认配置, 6:登录认证, 7:登录页, 8:认证页, 9:年度计划, 10:选择福利页, 11:确认福利页, 12:福利总览页, 13:福利政策, 14:保险落地页, 15:体检落地页, 16:医疗服务首页, 17:底部导航栏
export const getProductList = params =>
  $post(`${PREFIX}/h5/comConfig/index`, { ...params }, { hideLoading: true });

/**
 * @description:无登录态配置接口
 * @param {*} params
 * @return {*}
 */
export const getConfigWithoutAuth = (params, errorHideToast = false) =>
  $post(`${PREFIX}/open/h5/comConfig/index`, { ...params }, { hideLoading: true, errorHideToast });

// 获取开启时间
export const getOpenPeriod = params => $post(`${PREFIX}/h5/comConfig/getOpenPeriod`, { ...params });

// 检测是否有服务中的服务单
export const checkServing = params =>
  $get(`/api/health-treatment-client/v1/service/order/check/is-serving`, { ...params });

// 更新企业员工是否存在福利订单
export const updateHasOrder = companyCode =>
  $post(`/api/insurance-fw/h5/order/existWelfareOrder/update/${companyCode}`);

// 获取英硕demo跳转链接
export const getEnsureLink = params =>
  $post(`/api/jkb-user-center/v1/outside/getEnsureAccessToken`, { ...params });

// 获取英雄互娱-国寿跳转链接
export const getGuoShouLink = () => $post(`/api/jkb-user-center/v1/thirdParty/createGuoShouToken`);

// 获取快手-光大理赔跳转链接
export const getAqClaimLink = params =>
  $post(`/api/jkb-user-center/v1/outside/silenceRegisterGuangda`, { ...params });

// 获取平安理赔小程序链接
export const getWechatMiniprogramLink = (params, header = {}) =>
  $post(`/api/health-treatment-client/v1/open/wechat/mini/url_link`, { ...params }, { header });

// 获取跳转太平洋保险链接（科园信海）
export const getPacificInsuranceLink = params =>
  $post('/api/jkb-user-center/v1/outside/taiping/request-url', { ...params });
// 获取报销配置
export const getReimbursementDetail = params =>
  $post('/api/insurance-fw/h5/reimburse/config/detail', { ...params });
// 校验人群标签是否符合
export const getApplyForToSubmitted = params =>
  $post('/api/marketing-admin/h5/reimburse/apply/getApplyForToSubmitted', { ...params });

// 获取开始投保前的投保须知
export const getInsuranceNotes = params =>
  $post('/api/insurance-fw/open/h5/comConfig/insuranceNotice', { ...params });

// 获取国籍数据
export const getNationalityEnums = () => $get('/api/insurance-fw/h5/dict/queryAllNatName');
