const productDetail = {
  namespaced: true,
  state: {
    isVisitor: false, // 已下单时为访问模式，仅可查看
    isVisitorViewAllPlans: false, // 标记是否是查看模式下查看全部方案
    visitorAllItpCodes: {}, // 记录险种组合查看模式查看全部方案时选中的skucode
    orderHasPaid: false, // 订单是否已支付
    orderedInsuredInfo: [], // 访问模式下已下单数据
    projNo: '', // 项目编码
    planID: '',
    planItemID: '',
    productData: {}, // spu数据
    cannotBuyCodes: [], // 不能买的sku code
    restrictPurchaseTipConfig: undefined, // 限购提示配置
    insuredList: [], // 家庭成员信息，即：全部可选被保人
    // renewalDeclarationFlag: 1, // 续保人员是否需要填写健告：0-需要；1-不需要
    provisionalData: undefined, // 打开选择sku和被保人弹窗，选择过程中临时记录的临时数据
    originSubmitData: undefined, // 记录一开始的购物车数据，若当前是默认计划且健告选择阻止下单，需要还原对应被保人的默认选择
    toSubmitData: undefined, // 确认提交到购物车里的数据，在成功添加至购物车后需要被清空
    itpGroup: undefined, // 点击查看方案详情点击的险种组合单组数据
    priceDetail: undefined, // 价格获取明细
    isSkuChanged: false, // 是否更新了sku
    errorPriceInsuredNames: false, // 记录单个spu下购物车价格异常的被保人
    finalSubmitData: undefined, // 所有校验通过最终提交更新购物车的数据
    passSkuCodes: [], // 实时校验通过的sku code
    currentSku: undefined,
    preventInsuredId: [], // 健告选择阻止下单的被保人id
    singleInsured: {}, // 点击单条数据填写健告的被保人信息
  },
  getters: {
    isVisitor: state => state.isVisitor,
    isVisitorViewAllPlans: state => state.isVisitorViewAllPlans,
    visitorAllItpCodes: state => state.visitorAllItpCodes,
    orderHasPaid: state => state.orderHasPaid,
    orderedInsuredInfo: state => state.orderedInsuredInfo,
    projNo: state => state.projNo,
    planID: state => state.planID,
    planItemID: state => state.planItemID,
    productData: state => state.productData,
    cannotBuyCodes: state => state.cannotBuyCodes,
    restrictPurchaseTipConfig: state => state.restrictPurchaseTipConfig,
    insuredList: state => state.insuredList,
    // renewalDeclarationFlag: state => state.renewalDeclarationFlag,
    provisionalData: state => state.provisionalData,
    originSubmitData: state => state.originSubmitData,
    toSubmitData: state => state.toSubmitData,
    finalSubmitData: state => state.finalSubmitData,
    itpGroup: state => state.itpGroup,
    priceDetail: state => state.priceDetail,
    isSkuChanged: state => state.isSkuChanged,
    errorPriceInsuredNames: state => state.errorPriceInsuredNames,
    passSkuCodes: state => state.passSkuCodes,
    currentSku: state => state.currentSku,
    preventInsuredId: state => state.preventInsuredId,
    // 自定义弹窗开关：1-开启；2-关闭
    isPopupCustomOpen: state => {
      return +state.productData?.img?.isPopupCustom === 1;
    },
    // 商品spu层级适用人群
    spuApplicablePeople: state => {
      const n = state.productData?.attr?.applicablePeople;
      if (typeof n === 'number') return [n];
      return n;
    },
    // 商品类型: PM-套餐；ITP-险种组合
    productType: state => {
      return state.productData?.base?.sellModel;
    },
    // 是否配置默认方案：配置默认方案后不可删除已选被保人，但可以 新增 或 变更已选被保人的sku
    isExistDefaultPlan: state => {
      let suit = [];
      const productType = state.productData?.base?.sellModel;
      if (productType === 'PM') {
        state.productData?.skus.forEach(item => {
          const isExist = item?.attr?.isDefaultPlan;
          if (isExist) {
            suit.push(item);
          }
        });
      } else {
        state.productData?.skus?.[0]?.specialAttrs?.forEach((item, index) => {
          const obj = item?.specialAttrValues?.find(i => i?.defaultFlag);
          if (obj) {
            suit[index] = obj;
          }
        });
      }
      return suit?.length > 0;
    },
    // 是否为多人投保
    isMultipleInsure: state => {
      // 多人投保：单个spu只能存在一个方案（新增被保人即覆盖原方案），所以若为多人投保，那已经加入购物车的商品数据choiceInsuranceMode必定全部为2
      // 多人投保仅存在于套餐模式，且多个被保人共用一个sku
      if (state.provisionalData?.length > 0) {
        if (
          state.productData?.base?.sellModel === 'PM' &&
          state.provisionalData?.[0]?.planProductList?.length > 0
        ) {
          const code = state.provisionalData?.[0]?.planProductList?.[0]?.productCode;
          const index = state.productData?.skus?.findIndex(i => i?.base?.code === code);
          return +state?.productData?.skus?.[index].attr?.choiceInsuranceMode === 2;
        }
      }
      return false;
    },
    singleInsured: state => state.singleInsured,
  },
  mutations: {
    SET_VISITOR_MODE: (state, payload) => {
      state.isVisitor = payload;
    },
    SET_PROJ_NO: (state, payload) => {
      state.projNo = payload;
    },
    SET_PLAN_ID: (state, payload) => {
      state.planID = payload;
    },
    SET_PLAN_ITEM_ID: (state, payload) => {
      state.planItemID = payload;
    },
    SET_PRODUCT_DATA: (state, payload) => {
      state.productData = payload;
    },
    SET_CANNOT_BUY_CODES: (state, payload) => {
      state.cannotBuyCodes = payload;
    },
    SET_RESTRICT_PURCHASE_TIP_CONFIG: (state, payload) => {
      state.restrictPurchaseTipConfig = payload;
    },
    SET_INSURED_LIST: (state, payload) => {
      state.insuredList = payload;
    },
    // SET_RENEWAL_DECLARATION_FLAG: (state, payload) => {
    //   state.renewalDeclarationFlag = payload;
    // },
    SET_PROVISIONAL_DATA: (state, payload) => {
      state.provisionalData = payload;
    },
    SET_ORIGIN_SUBMIT_DATA: (state, payload) => {
      state.originSubmitData = payload;
    },
    SET_TO_SUBMIT_DATA: (state, payload) => {
      state.toSubmitData = payload;
    },
    SET_ITP_GROUP: (state, payload) => {
      state.itpGroup = payload;
    },
    SET_PRICE_DETAIL: (state, payload) => {
      state.priceDetail = payload;
    },
    SET_SKU_CHANGED: (state, payload) => {
      state.isSkuChanged = payload;
    },
    SET_ERROR_PRICE_INSURED_NAMES: (state, payload) => {
      state.errorPriceInsuredNames = payload;
    },
    SET_FINAL_SUBMIT_DATA: (state, payload) => {
      state.finalSubmitData = payload;
    },
    SET_PASS_SKU_CODES: (state, payload) => {
      state.passSkuCodes = Array.from(new Set(payload || []));
    },
    SET_PREVENT_INSURED_ID: (state, payload) => {
      if (!payload) {
        state.preventInsuredId = [];
      } else {
        const arr = Array.from(new Set([...state.preventInsuredId, payload]));
        state.preventInsuredId = arr?.map(i => +i);
      }
    },
    SET_ORDERED_INSURED_INFO: (state, payload) => {
      state.orderedInsuredInfo = payload;
    },
    SET_CURRENT_SKU: (state, payload) => {
      state.currentSku = payload;
    },
    SET_VISITOR_VIEW_ALL_PLANS: (state, payload) => {
      state.isVisitorViewAllPlans = payload;
    },
    SET_VISITOR_ALL_ITP_CODES: (state, payload) => {
      if (!payload) {
        state.visitorAllItpCodes = {};
      } else {
        state.visitorAllItpCodes = { ...state.visitorAllItpCodes, [payload?.key]: payload?.value };
      }
    },
    SET_ORDER_HAS_PAID: (state, payload) => {
      state.orderHasPaid = payload;
    },
    SET_SINGLE_INSURED: (state, payload) => {
      state.singleInsured = payload;
    },
  },
  actions: {},
};

export default productDetail;
