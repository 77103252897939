import apis from '@/api';

/**
 * 校验积分数据是否过期，缓存有效期 1 分钟
 * @param {*} state
 * @param {*} authStatus 认证状态是否变更
 * @returns boolean
 */
const checkCacheExpired = (state, authStatus) => {
  const { createdTime, bizStatus } = state;
  if (!createdTime || bizStatus === null || bizStatus != authStatus) {
    return true;
  }
  return +new Date() > createdTime + 1 * 60 * 1000;
};

const initQuickerState = {
  quickerBalanceData: null,
  commonPointData: null,
  createdTime: null,
  bizStatus: null, // 认证状态
};

export default {
  namespaced: true,
  state: {
    // 快手积分数据
    quicker: {
      ...initQuickerState,
    },
  },
  getters: {
    quickerBalanceData: state => state.quicker.quickerBalanceData,
    commonPointData: state => state.quicker.commonPointData,
  },
  mutations: {
    SET_QUICKER_BALANCE_DATA: (state, data) => {
      state.quicker = {
        ...state.quicker,
        ...data,
        createdTime: +new Date(),
      };
    },
    // 下单、取消订单之后清除缓存的积分数据
    // eslint-disable-next-line no-unused-vars
    CLEAR_QUICKER_BALANCE_DATA: state => {
      state.quicker = {
        ...initQuickerState,
      };
    },
  },
  actions: {
    // 已认证状态下查询快手积分明细
    async getQuickerBalanceDetail({ commit, state }, payload) {
      // force 是否强制调接口查询
      const authStatus = 2;
      const { params = {}, force = false } = payload;
      const { quickerBalanceData } = state.quicker;
      if (!force && quickerBalanceData && !checkCacheExpired(state.quicker, authStatus)) {
        return;
      }

      const res = await apis.getQuickerPointInfo(params);
      if (res?.code === '0') {
        commit('SET_QUICKER_BALANCE_DATA', {
          quickerBalanceData: res?.data || null,
          commonPointData: {
            haveCommonPoint: res?.data?.haveCommonPoint || null,
            commonPointUsable: res?.data?.commonPointUsable || null,
          },
          bizStatus: authStatus,
        });
      }
    },

    // 已认证状态下查询剩余积分
    async getCommonPointUsable({ commit, state }, payload) {
      // force 是否强制调接口查询
      const authStatus = 2;
      const { params = {}, force = false } = payload;
      const { commonPointData } = state.quicker;
      if (!force && commonPointData && !checkCacheExpired(state.quicker, authStatus)) {
        return;
      }

      const res = await apis.getCommonPointUsable(params);
      if (res?.code === '0') {
        commit('SET_QUICKER_BALANCE_DATA', {
          commonPointData: res?.data || null,
          bizStatus: authStatus,
        });
      }
    },

    // 未认证状态下获取积分明细
    async getUserPointByNoAuth({ commit, state }, payload) {
      // force 是否强制调接口查询
      const authStatus = 1;
      const { params = {}, force = false } = payload;
      const { quickerBalanceData } = state.quicker;
      if (!force && quickerBalanceData && !checkCacheExpired(state.quicker, authStatus)) {
        return;
      }

      const res = await apis.queryUserPointByNoAuth(params);
      if (res?.code === '0') {
        commit('SET_QUICKER_BALANCE_DATA', {
          quickerBalanceData: res?.data || null,
          commonPointData: {
            haveCommonPoint: res?.data?.haveCommonPoint || null,
            commonPointUsable: res?.data?.commonPointUsable || null,
          },
          bizStatus: authStatus,
        });
      }
    },
  },
};
