import Apis from '@/api';
import { isNilString } from '@/utils/utils';

export default {
  namespaced: true,
  state: {
    projecData: {
      comId: '', // 企业编码
      projectID: '', //项目编码
    },
    staffNationalityCollection: undefined, // 员工是否需要收集国籍
    relationNationalityCollection: undefined, // 家属是否需要收集国籍
    nationalityCN: [],
    nationalityEN: [],
  },
  getters: {
    staffNationalityCollection: state => state.staffNationalityCollection,
    relationNationalityCollection: state => state.relationNationalityCollection,
    nationalityCN: state => state.nationalityCN,
    nationalityEN: state => state.nationalityEN,
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.projecData = data;
    },
    SET_CONFIG_DATA: (state, data) => {
      state.projecData.comId = data?.comId || '';
      data?.comId && window.sessionStorage.setItem('_companyCode', data?.comId);
      state.projecData.projectID = data?.projNo || '';
      data?.projNo && localStorage.setItem('_projNo', data?.projNo);
    },
    SET_NATIONALITY_CONFIG: (state, value) => {
      state.staffNationalityCollection = value?.staffNationalityCollection;
      state.relationNationalityCollection = value?.relationNationalityCollection;
    },
    SET_NATIONALITY_DATA: (state, data) => {
      state.nationalityCN = data?.nationalityCN || [];
      state.nationalityEN = data?.nationalityEN || [];
    },
  },
  actions: {
    getNationalityCollectionConfig({ commit, state }, projNo) {
      if (!projNo) return;
      if (
        !isNilString(state?.staffNationalityCollection) &&
        !isNilString(state?.relationNationalityCollection)
      ) {
        return;
      }
      return Apis.projectDetailByProjNo({ projNo }).then(configRes => {
        if (+configRes?.code === 0) {
          const relationNationalityCollection = +configRes?.data?.needMemberNat === 1;
          const staffNationalityCollection = +configRes?.data?.needStaffNat === 1;
          commit('SET_NATIONALITY_CONFIG', {
            relationNationalityCollection,
            staffNationalityCollection,
          });
          if (relationNationalityCollection || staffNationalityCollection) {
            Apis.getNationalityEnums().then(nationalityRes => {
              if (nationalityRes?.code === '0') {
                const { natCnNameList, natEnNameList } = nationalityRes?.data || {};
                const nationalityCN = natCnNameList?.map(i => ({
                  code: i?.code,
                  text: i?.countryNameCn,
                }));
                const nationalityEN = natEnNameList?.map(i => ({
                  code: i?.code,
                  text: i?.countryNameEn,
                }));
                commit('SET_NATIONALITY_DATA', {
                  nationalityCN,
                  nationalityEN,
                });
              }
            });
          }
        }
      });
    },
  },
};
