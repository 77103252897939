import {
  isAAutoQuicker,
  isEnsure,
  isHero,
  isKyuan,
  isBeike,
  isDekon,
  isTequ,
  isBochu,
  isDipingxian,
  isHongxigua,
  isZuoyebang,
  isSOHO,
  isNreal,
} from '@/constants/source';
import Apis from '@/api';
import { Toast } from 'vant';
import router from '@/router';
import { toWechatMiniprogram } from '@/utils/utils';

// 第三方统一处理，中断原本后续逻辑
const thirdpartyResolve = async (item, replace = false) => {
  let isContinue = true;

  // 跳转小程序-平安理赔
  if (+item?.redirectType === 28) {
    isContinue = false;
    toWechatMiniprogram({
      path: '/pages/index/index',
      query: 'path=/mine',
      appId: 'wx18792702155c772e',
    });
    return;
  }

  // 跳转小程序-太平洋保险e企福
  if (+item?.redirectType === 31) {
    isContinue = false;
    toWechatMiniprogram({
      path: '/pages/loading/loading',
      query: '',
      appId: 'wx225c748bd8d8fc4f',
    });
    return;
  }

  // 理赔通用处理
  if (+item?.redirectType === 50) {
    // 国寿理赔
    isContinue = false;
    const res = await Apis.getGuoShouLink().catch(() => Toast('获取中国人寿理赔链接失败'));
    const { code, data } = res || {};
    if (code === '0' && data?.url) {
      window.location.href = data?.url;
    } else {
      Toast('获取中国人寿理赔链接失败');
    }
    return;
  }

  if (+item?.redirectType === 51) {
    // 复星理赔
    isContinue = false;
    const res = await Apis.getFosunLink().catch(() => Toast('获取保司链接失败'));
    const { code, data } = res || {};
    if (+code === 0 && data?.url) {
      window.location.href = data.url;
    } else {
      Toast('获取保司链接失败');
    }
    return;
  }

  if (+item?.redirectType === 52) {
    // 自选 - 太平洋健康保险
    isContinue = false;
    const res = await Apis.getDownstreamInsuranceLink().catch(() => Toast('获取保司链接失败'));
    const { code, data } = res || {};
    if (+code === 0 && data?.url) {
      window.location.href = data.url;
    } else {
      Toast('获取保司链接失败');
    }
    return;
  }

  if (+item?.redirectType === 53) {
    // 理赔 - 太平洋健康保险
    isContinue = false;
    const res = await Apis.getPacificInsuranceLink({
      thirdMarkCode: 35,
      backUrl: window.location.href,
      serviceCode: 'CLAIM',
    }).catch(() => Toast('获取保司链接失败'));
    const { code, data } = res || {};
    if (+code === 0 && data?.url) {
      window.location.href = data.url;
    } else {
      Toast('获取保司链接失败');
    }
    return;
  }

  if (+item?.redirectType === 54) {
    // 保单 - 太平洋健康保险
    isContinue = false;
    const res = await Apis.getPacificInsuranceLink({
      thirdMarkCode: 35,
      backUrl: window.location.href,
      serviceCode: 'INSURANCE',
    }).catch(() => Toast('获取保司链接失败'));
    const { code, data } = res || {};
    if (+code === 0 && data?.url) {
      window.location.href = data.url;
    } else {
      Toast('获取保司链接失败');
    }
    return;
  }

  if (+item?.redirectType === 55) {
    // 中邮人寿
    isContinue = false;
    const res = await Apis.getZhongYouClaimLink({
      modelCode: 'photoClaimZY', // photoClaimZY-拍照理赔；fundZY-直付理赔；homeZY-集合页
    }).catch(() => Toast('获取保司链接失败'));
    const { code, data } = res || {};
    if (+code === 0 && data?.url) {
      if (replace) {
        window.location.replace(data.url);
      } else {
        window.location.href = data.url;
      }
    } else {
      Toast('获取保司链接失败');
    }
    return;
  }

  if (+item?.redirectType === 60) {
    // 英硕商城
    isContinue = false;
    const res = await Apis.getEnsureStoreLink().catch(() => Toast('获取链接失败'));
    const { code, data } = res || {};
    if (+code === 0 && data?.url) {
      if (replace) {
        window.location.replace(data.url);
      } else {
        window.location.href = data.url;
      }
    } else {
      Toast('获取链接失败');
    }
    return;
  }

  if (isEnsure) {
    // 英硕demo
    if (item?.url?.indexOf('yingshuo') > -1) {
      isContinue = false;
      if (item?.url.indexOf('?') < 0) {
        Toast('请检查链接配置是否正确');
        return;
      } else {
        const str = item?.url.split('?')[1];
        const resourceId = str.split('=')[1];
        const res = await Apis.getEnsureLink({
          appId: 'sp',
          companyCode: 'SPGS',
          resourceId: parseInt(resourceId),
          accountName: 'ZK17674658530',
        });
        window.location.href = res?.data?.redirectUrl;
        return;
      }
    }
  }

  // 英雄互娱、德康农牧、特区农牧定制跳转保司
  if (isHero || isDekon || isTequ) {
    if (item?.url?.indexOf('home.pingan.com.cn') > -1) {
      isContinue = false;
      window.location.href = item?.url;
      return;
    }
  }

  // 科园信海、柏楚
  if (isKyuan || isBochu) {
    // 跳转理赔
    if (item?.url?.indexOf('https://type=') > -1) {
      isContinue = false;
      const serviceCode = item?.url?.split('=')?.[1];
      const res = await Apis.getPacificInsuranceLink({
        thirdMarkCode: 35,
        backUrl: window.location.href,
        serviceCode,
      }).catch(() => Toast('获取链接失败'));
      const { code, data } = res || {};
      if (code === '0') {
        window.location.href = data?.url;
        return;
      }
      return;
    }
    // 科园信海跳转保司，柏楚没这情况
    if (item?.url?.indexOf('https://third.party.link') > -1) {
      isContinue = false;
      const res = await Apis.getDownstreamInsuranceLink().catch(() => Toast('获取链接失败'));
      const { code, data } = res || {};

      if (code === '0') {
        window.location.href = data?.url;
        return;
      }
      return;
    }
  }

  // 贝壳
  if (isBeike) {
    if (item?.url?.indexOf('https://third.party.link') > -1) {
      isContinue = false;
      const type = item?.url?.split('=')?.[1];
      if (type === 'HMS') {
        // 跳转健康管理服务
        router.push({
          name: 'bkHealthManageService',
        });
        return;
      } else if (type === 'GS_CLAIM') {
        // 跳转国寿理赔
        const res = await Apis.getGuoShouLink().catch(() => Toast('获取中国人寿理赔链接失败'));
        const { code, data } = res || {};
        if (code === '0' && data?.url) {
          window.location.href = data?.url;
          return;
        } else {
          Toast('获取中国人寿理赔链接失败');
          return;
        }
      } else {
        // 跳转众安理赔
        const res = await Apis.getZhongAnLink({
          comId: window.sessionStorage.getItem('_companyCode'),
        }).catch(() => Toast('获取众安保险理赔链接失败'));
        const { code, data } = res || {};
        if (code === '0' && data) {
          window.location.href = data;
          return;
        } else {
          Toast('获取众安保险理赔链接失败');
        }
      }
      return;
    }
  }

  // 快手，回跳快乐社区
  if (isAAutoQuicker()) {
    if (item?.url?.indexOf('neigou.com') > -1 || item?.url?.indexOf('kuaishou.') > -1) {
      window.location.href = item?.url;
      return;
    }
  }

  // 红西瓜、地平线跳转优佳
  if (isDipingxian || isHongxigua) {
    if (item?.url?.indexOf('https://third.party.link') > -1) {
      isContinue = false;
      const userId = JSON.parse(window.localStorage.getItem('loginMsg'))?.userId;
      const res = await Apis.getYoujiaUrl({
        source: window.sessionStorage.getItem('_source'),
        userId,
      }).catch(() => Toast('获取链接失败'));
      const { code, data } = res || {};
      if (code === '0') {
        window.location.href = data;
        return;
      }
      return;
    }
  }

  // 作业帮、优奈柯恩跳复星
  if (isZuoyebang() || isNreal) {
    if (item?.url?.indexOf('https://third.party.link') > -1) {
      isContinue = false;
      const res = await Apis.getFosunLink().catch(() => Toast('获取保司链接失败'));
      const { code, data } = res || {};
      if (+code === 0 && data?.url) {
        window.location.href = data.url;
      } else {
        Toast('获取保司链接失败');
      }
      return;
    }
  }

  // 慧智微：跳转禾连
  // if (isSmarterMicro()) {
  //   if (item?.url?.indexOf('https://third.party.link') > -1) {
  //     isContinue = false;
  //     const res = await Apis.getHelianLink({
  //       source: window.sessionStorage.getItem('_source'),
  //       suppliertStreamUniqueCode: 'helian001',
  //     }).catch(() => Toast('获取链接失败'));
  //     const { code, data } = res || {};
  //     if (code === '0') {
  //       window.location.href = data?.url;
  //       return;
  //     }
  //     return;
  //   }
  // }

  // SOHO：跳转国寿理赔
  if (isSOHO) {
    if (item?.url?.indexOf('https://third.party.link') > -1) {
      isContinue = false;
      const res = await Apis.getGuoShouLink().catch(() => Toast('获取中国人寿理赔链接失败'));
      const { code, data } = res || {};
      if (code === '0' && data?.url) {
        window.location.href = data?.url;
      } else {
        Toast('获取中国人寿理赔链接失败');
      }
      return;
    }
  }

  return isContinue;
};

export default thirdpartyResolve;
