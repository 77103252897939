import { stringifyObject, urlcat } from '@/utils/utils';
import { Toast } from 'vant';
import { syncPersonInfoToHyApi } from '@/api/healthManagement.api';

/**
 * 跳转互联网医院页面
 * @param path vue页面地址
 * @param query 路由参数
 * @param replace location.replace
 */
export const goHospitalPage = ({ path, query, replace = false }) => {
  const toast = Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true,
    message: '即将前往互联网服务页面',
  });
  const source = window.sessionStorage.getItem('_source');
  const params = {
    source,
    tpsType: 'HY',
  };
  syncPersonInfoToHyApi(params).then(response => {
    const res = response?.data || {};
    const loginInfo = JSON.parse(window.localStorage.getItem('loginMsg')) || {};
    const { jwtToken } = loginInfo;
    // TA_01 为预认证用户，不阻塞页面
    if (res?.code === '0' || res?.code === 'TA_01') {
      const hyBaseUrl = process.env.VUE_APP_INTERNET_HOSPITAL_BASE_URL;
      const hyPageUrl = urlcat(
        path.indexOf(hyBaseUrl) >= 0 ? path : `${hyBaseUrl}${path}`,
        stringifyObject({
          ...query,
          token: res.data || jwtToken,
          __SOURCE__: source,
          companyEncode: window.sessionStorage.getItem('_companyCode') || '',
          healthDomain: encodeURIComponent(window.location.origin),
        }),
      );
      if (replace) {
        window.location.replace(hyPageUrl);
      } else {
        window.location.href = hyPageUrl;
      }
      toast.clear();
    } else {
      toast(res.message);
    }
  });
};
