import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// 埋点
// import '@med/buzz';
// 基础样式配置
import 'sanitize.css';
import '@/styles/index.less';
import 'lib-flexible'; // 移动端适配包
import '@/assets/iconfont/iconfont.css';
// 引入全局工具类
import { isWeChat, iosRestoration, isMobile } from '@/utils/index.js';
// ui组件
import vant from '@/utils/vant';
import element from '@/utils/element';
// 自定义弹窗
import CustomDialog from '@/components/customDialog';
// 过滤器
import * as filters from '@/utils/filter';
// 导入接口
import Apis from '@/api';
// 动态设置标题
// import VueWechatTitle from 'vue-wechat-title';
// 兼容PC模式
import '@vant/touch-emulator';
import directives from '@/utils/directives';
// 事件追踪
import ET from '@/utils/eventTracking';
// sentry
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
// PC模式禁用放大缩小
import { disableBrowserZoom } from '@/utils/utils';
//PC组件body
import globalBody from '@/components/Desktop/globalBody.vue';
Vue.component('globalBody', globalBody);
//多语言
import i18n from '@/lang';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

Vue.use(ElementUI, { locale });

try {
  if (
    !['production', 'prod', 'pre'].includes(process.env.NODE_ENV) ||
    window.location.href.indexOf('vconsole=on') > -1
  ) {
    const Vconsole = require('@/lib/vconsole.min.js');
    const vConsole = new Vconsole();
    Vue.use(vConsole);
  } else {
    // Sentry.init({
    //   Vue,
    //   dsn: 'https://ef824d09b1b847ccbf9cd914f838bbb5@sentry.medbanks.cn/71',
    //   integrations: [
    //     new BrowserTracing({
    //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //       tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
    //     }),
    //   ],
    //   // Set tracesSampleRate to 1.0 to capture 100%
    //   // of transactions for performance monitoring.
    //   // We recommend adjusting this value in production
    //   tracesSampleRate: 1.0,
    //   beforeSend: (event, hint) => {
    //     const patterns = [
    //       /CanceledError/i, // 取消接口
    //       /ChunkLoadError/i, // cdn加载css js等文件
    //       /Loading CSS chunk/i, // 加载css文件
    //       /NavigationDuplicated/i, // 重复路由
    //       /Request failed with status code 401/i, // 接口无权限
    //       /Can't find variable/i, // 接入app中webview引用js
    //       /Navigation cancelled$/i, // 路由取消
    //       /AxiosError/i, // 请求失败,响应超时等情况
    //     ];
    //     const message = hint.originalException;
    //     for (let i = 0; i < patterns.length; i++) {
    //       const pattern = patterns[i];
    //       if (message && pattern?.test(message)) {
    //         return null;
    //       }
    //     }
    //     return event;
    //   },
    // });
  }
} catch (error) {
  console.log(error);
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]); //插入过滤器名和对应方法
});
Vue.use(directives);
Vue.use(vant);
Vue.use(element);

// Vue.use(VueWechatTitle);
Vue.prototype.$apis = Apis;
Vue.prototype.$customDialog = CustomDialog;
Vue.prototype.$ET = ET;

// vue的生产环境info
Vue.config.productionTip = false;

Vue.prototype.$isWechat = isWeChat;

iosRestoration();

disableBrowserZoom();
// PC聚焦刷新
document.onvisibilitychange = function() {
  if (
    document.visibilityState === 'visible' &&
    window.location.href?.indexOf('/spcare-products/health-fw-static/confirm') > -1
  ) {
    window.location.reload();
  }
  if (!isMobile()) {
    if (
      document.visibilityState === 'visible' &&
      window.location.href?.indexOf('/spcare-products/health-fw-static/choose') > -1
    ) {
      window.location.reload();
    }
  }
};

// 代登录处理
// 由于代登录的登录态有效期仅为一次会话，且考虑到不能影响常规登录态一周有效期，所以添加如下判断：当浏览器缓存有代登录信息，同时无正常代登录进入时存储的代登录人信息，则视为是上次代登录时的数据，并清楚登录态。
const loginMsg = JSON.parse(window.localStorage.getItem('loginMsg'));
if (!window.sessionStorage.getItem('_agent') && loginMsg?.proxyOperator) {
  window.localStorage.removeItem('loginMsg');
  window.localStorage.removeItem('SP_TOKEN');
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
