const getters = {
  openid: state => state.$_user.openid,
  saleChannelId: state => state.$_product.saleChannelId,
  policyState: state => state.$_product.policyState,
  source: state => state.$_product.source,
  bizCode: state => state.$_product.bizCode,

  // 全局基本配置
  logoSource: state => state.$_config.logoSource,
  themeColor: state => state.$_config.themeColor,
  showPersonalCenter: state => state.$_config.showPersonalCenter,
  personalCenterIcon: state => state.$_config.personalCenterIcon,
  loginTheme: state => state.$_config.loginTheme,
  authTheme: state => state.$_config.authTheme,
  supplyTheme: state => state.$_config.supplyTheme,
  emailTheme: state => state.$_config.emailTheme,
  // 微信公众号不展示区号。
  countryCodeEnabled: state =>
    state.$_config.countryCodeEnabled && window.sessionStorage.getItem('_isJian1bao') !== '1',

  //商品详情页数据
  formList: state => state.$_insured.form,
  cardType: state => state.$_insured.cardType,
  productData: state => state.$_insured.productData,
  familyList: state => state.$_insured.familyList,
  relationshipList: state => state.$_insured.relationshipList,
  hasCanBuy: state => state.$_insured.hasCanBuy,
  currentInsured: state => state.$_insured.currentInsured,
  currentChosenSuit: state => state.$_insured.currentChosenSuit,
  rootExtra: state => state.$_insured.rootExtra,

  // 用户相关
  isHasOrder: state => state.$_user.isHasOrder,
  //开窗时间
  openingTime: state => state.$_openingTime.openingTime,
  //项目数据
  projecData: state => state.$_openingTime.openingTime,
};

export default getters;
