import request from '@/utils/request';
const options = {};
const $get = request.get(options);
const $post = request.post(options);

/**
 * 以下为健管首页（医疗首页）api
 */

// 获取合规配置
export const getComplianceConfig = () => $post('/api/online-hospital-client/v1/switch');

// 健管首页、企业专属服务、常驻地配置
export const getHeathManagementPageConfig = params =>
  $post('/api/health-treatment-client/v1/homePage/config', params);

// 获取微信分享签名信息
export const getWeChatSignInfo = params =>
  $post('/management-system/api/v1/wechat/signature', params);

// 校验是否需要更新常驻地
export const checkResidentAddress = params =>
  $get('/api/health-treatment-client/v1/health/checkUpdateMemberRegion', params);

// 查询用户服务单
export const getServiceOrder = params =>
  $post('/api/health-treatment-client/v1/service/order/find/claim/medicine/order', params);

// 获取就诊人详情
export const getTreatmentDetail = params =>
  $post('/api/health-treatment-client/v1/person/detail', params);

// 判断企业面诊是否开启
export const getFaceDiagnoseEnterprise = params =>
  $get('/api/health-treatment-client/v1/corporation-diagnosis/portal', params);

// 查询医生团队
export const getTeamOfDoctors = params =>
  $get('/api/health-treatment-client/v1/health/teamQuery', params);

// 查询最新的服务单
export const getLatestServiceOrder = params =>
  $get('/api/health-treatment-client/v1/service/order/find/latestServiceOrder', params);

// 获取企业活动信息
export const getEnterpriseActivity = params =>
  $get('/api/trading-activity/mgmt/activity/selectCompanyActivity', params);

// 获取会员统计和健康报告数量
export const getStatistics = params =>
  $get('/api/health-treatment-client/v1/health/index-count', params);

// 判断是否处于节假日
export const checkOnHoliday = params =>
  $get('/api/health-treatment-client/v1/tip/holiday-info', params);

// 判断是否展示购药订单入口
export const checkDrugOrderEntry = params =>
  $post('/api/jkb-user-center/v1/open/config/getDingdangBlackList', params, {
    errorHideToast: true,
  });

// 健康风险评估查看权益次数
export const getHealthEvaluateCount = params =>
  $get('/api/health-treatment-client/v1/health/query/healthAssessment/count', params);

// 获取健康风险评估地址
export const getHealthEvaluateUrl = params =>
  $get('/api/health-treatment-client/v1/health/healthAssessment', params);

// 查询会员服务权益
export const getMemberServiceRights = params =>
  $get('/api/health-treatment-client/v1/homePage/findServiceEntranceList', params);

// 是否能查看企业专属服务
export const checkEnterpriseHrRight = params => $post('/api/insurance-fw/h5/health/new/hr', params);

/**
 * 以下为企业专属服务页api
 */

// 获取关联企业
export const getHrCompanyList = () => $get('/api/health-hr/v1/hr/homePage/findCompanyList');

// 获取企业权益数量
export const getCompanyEquityCount = params =>
  $get('/api/health-hr/v1/hr/homePage/findServiceEntrance', params);

/**
 * 以下为常驻城市页api
 */
// 查询常驻城市地址
export const searchRegion = params => $get('/master-data/api/region/search', params);

// 更新常驻城市
export const updateResidentAddress = params =>
  $post('/api/health-treatment-client/v1/health/updateMemberRegion', params);

// 跳转互联网医院同步用户数据
export const syncPersonInfoToHyApi = params =>
  $post('/api/health-treatment-client/v1/third-party-service/auth-login', params, {
    hideCodeMessage: true,
    errorHideToast: true,
    reductDataFormat: false,
  });
