import Vue from 'vue';
import CustomDialogH5 from './Mobile/index.vue';
import CustomDialogPC from './Desktop/index.vue';
import { isMobile } from '@/utils';

const Dialog = isMobile() ? CustomDialogH5 : CustomDialogPC;
const DialogConstructor = Vue.extend(Dialog);

Dialog.install = data => {
  const instance = new DialogConstructor({
    data,
  }).$mount();

  document.body.appendChild(instance.$el);

  return instance.onClick();
};

export default Dialog.install;
