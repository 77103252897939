var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.appReady
    ? _c(
        "div",
        { style: _vm.cssVariable, attrs: { id: "app" } },
        [
          _vm.isMobile
            ? [
                _c(
                  "div",
                  { attrs: { id: "app-body" } },
                  [
                    _c(
                      "keep-alive",
                      { attrs: { include: ["MedicalHome"] } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isBtns
                  ? [
                      _vm.isShow
                        ? _c("bottom-nav-bar", {
                            attrs: { findVal: _vm.findVal },
                          })
                        : _vm._e(),
                      _vm.showToUserCenter
                        ? _c("drag-click-item", {
                            attrs: {
                              "init-top": 520,
                              source: _vm.toUserCenterSrc,
                            },
                            on: { "on-click": _vm.toUserCenter },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm.showToHome
                  ? _c("drag-click-item", {
                      attrs: { "init-top": 480, source: _vm.toHomeSrc },
                      on: { "on-click": _vm.toHome },
                    })
                  : _vm._e(),
                _vm.showMobileSwitchLanguage
                  ? _c("mobile-switch-language")
                  : _vm._e(),
              ]
            : [
                _vm.showDesktopSwitchLanguage
                  ? _c("desktop-switch-language")
                  : _vm._e(),
                _vm.showDesktopGlobalHeader
                  ? [
                      _c("desktop-global-header", {
                        attrs: { findVal: _vm.findVal },
                      }),
                    ]
                  : _vm._e(),
                _c("router-view"),
              ],
          _vm.showWatermark ? _c("Watermark") : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }