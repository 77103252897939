import request from '@/utils/request';
const options = {};

// const $get = request.get(options);
const $post = request.post(options);

// 健告ID获取填写情况
export const getRecordByInsuredId = params =>
  $post(`/api/insurance-fw/health/declaration/getRecordByInsuredId`, { ...params });

// // 查询健康告知详情
export const getDetail = params =>
  $post(`/api/insurance-fw/health/declaration/detail`, { ...params });

// 阻止下单删除被保险人接口
export const delYearPlan = params => $post(`/h5/plan/delYearPlan`, { ...params });

// 填写健告记录
export const recordHealthNotification = params =>
  $post(`/api/insurance-fw/health/declaration/record`, { ...params });
