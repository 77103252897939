/**
 * 校验18位身份证
 */
export const checkIdNo = card => {
  const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  return reg.test(card);
};

/**
 * 校验军官证
 * 规则： 军/兵/士/文/职/广/（其他中文） + "字第" + 4到8位字母或数字 + "号"
 * 样本： 军字第2001988号, 士字第P011816X号
 */
export const checkOfficerNo = card => {
  // const reg = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/;
  // return reg.test(card);
  console.log(card);
  return defaultRegNo(card);
};

/**
 * 校验出生证
 */
export const checkBirthNo = card => {
  // const reg = /^[a-zA-Z0-9]{5,21}$/;
  // return reg.test(card);
  console.log(card);
  return defaultRegNo(card);
};

/**
 * 校验外国人永久居留证
 */
export const checkForeignerNo = card => {
  // const reg = /^[a-zA-Z]{3}\d{12}$/;
  // return reg.test(card);
  console.log(card);
  return defaultRegNo(card);
};

/**
 * 校验护照
 */
export const checkPassNo = card => {
  return defaultRegNo(card);
  // const reg = /^[a-zA-Z0-9]{7,13}$/;
  // return reg.test(card);
};

/**
 * 校验港澳居民来往内地通行证
 */
export const checkHMNo = card => {
  return defaultRegNo(card);
  // const reg1 = /^[HM]\d{8}$/;
  // const reg2 = /^[HM]\d{10}$/;
  // return reg1.test(card) || reg2.test(card);
};

/**
 * 校验台湾居民来往大陆通行证
 */
export const checkTWNo = card => {
  return defaultRegNo(card);
  // const reg = /^[0-9]{8}$/;
  // return reg.test(card);
};

/**
 * 校验姓名
 */
export const checkName = card => {
  // const reg = /^[\u4e00-\u9fffa-zA-Z·、.\s]{1,50}$/;
  const reg = /^(([\u4e00-\u9fa5\u3400-\u4DBF\uE000-\uF8FF\uD840-\uD87F]|[\uDC00-\uDFFD])+)(([\uDC00-\uDFFD]|[a-zA-Z\u4e00-\u9fa5\uE000-\uF8FF\uD840-\uD87F\-,.•·/])*){2,50}$/; //中文开头，保留中间空格 允许中英文组合 "-" "," 和  ”/” "•"和 “.”
  const eReg = /^[A-Za-z'éèêëîïùûüàâäôöçÉÈÊËÎÏÙÛÜÀÂÄÔÖÇ]+([\s\-,.•·/][A-Za-z'éèêëîïùûüàâäôöçÉÈÊËÎÏÙÛÜÀÂÄÔÖÇ]*)*$/; //英文、法语开头，保留中间空格，不允许中英文组合  "-"  "," 和 ”/”  "•"和“.”
  const regRepeatBlank = /^.{2,50}$/;
  const regRepeat = /\s{2,}/g;
  return reg.test(card) || (eReg.test(card) && !regRepeat.test(card) && regRepeatBlank.test(card));
};

/**
 * 其他类型
 */
export const requireNo = card => {
  // const reg = /^[a-zA-Z]{3}\d{12}$/;
  // return reg.test(card);
  console.log(card);
  return true;
};
/**
 * 默认校验
 */
export const defaultRegNo = card => {
  const reg = /^([a-zA-Z]|\d|[\u4e00-\u9fffa]|（|）|\(|\)){1,18}$/;
  return reg.test(card);
};

// 去除字符串中重复的连续空格
export const formatRepeatBlank = name => {
  if (typeof name == 'string') {
    return name.replace(/\s+/gi, ' ');
  }
  return name;
};

export const phoneReg = /^\d{6,20}$/; // 通用手机号，长度必须大于5位，最多20位数字

export const chinaPhoneReg = /^1[2,3,4,6,5,7,8,9]\d{9}$/; // +86手机号校验

/**
 * 校验手机号
 */
export function checkPhoneNo(cur) {
  return phoneReg.test(cur);
}
