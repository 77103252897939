export const BASE_URL = 'http://api.example.com/';
export const API_CODE_OK = 10000;
export const BASE_ROUTE_PREFIX = '/spcare-products/health-fw-static';

// 内部域名 hostname
export const securityDomainList = [
  'localhost',
  'jkb.medbanks-test.com',
  'jkb2.medbanks-test.com',
  'hospital.medbanks-test.com',
  'hospital.spcare.cn',
  'ihu.medbanks-test.com',
  // 'medicine-service.medbanks-test.com',
  'jkb.spcare.com.cn',
  'm.spcare.cn',
  'm.medbanks.org.cn',
  'ihu.spcare.cn',
  // 'm.medbanks.cn',
  // 'm.spcare.com.cn',
  'jkb.sipaicare.com.cn',
  'jkb.medbanks.cn',
];
