const healthManagement = {
  namespaced: true,
  state: {
    compliance: {}, // 合规配置
    userInfo: {}, // 用户信息
    config: {}, // 页面配置
    hasHrRight: undefined, // 是否能查看企业专属服务
  },
  getters: {
    compliance: state => state.compliance,
    userInfo: state => state.userInfo,
    config: state => state.config,
    hasHrRight: state => state.hasHrRight,
  },
  mutations: {
    SET_COMPLIANCE: (state, payload) => {
      state.compliance = { ...state.compliance, ...payload };
    },
    SET_USER_INFO: (state, payload) => {
      state.userInfo = { ...state.userInfo, ...payload };
    },
    SET_CONFIG: (state, payload) => {
      state.config = payload;
    },
    SET_HAS_HR_RIGHT: (state, payload) => {
      state.hasHrRight = payload;
    },
  },
  actions: {},
};

export default healthManagement;
