import apis from '@/api';
import { getQueryString } from '@/utils';

export default {
  namespaced: true,
  state: {
    openingTime: [],
    lifeEventData: null,
    gottenInsuranceNotes: false,
    insuranceNotes: [],
  },
  getters: {
    gottenInsuranceNotes: state => state.gottenInsuranceNotes,
    insuranceNotes: state => state.insuranceNotes,
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.openingTime = data || [];
    },
    SET_LIFE_EVENT_DATA: (state, data) => {
      state.lifeEventData = data || {};
    },
    SET_GOTTEN_INSURANCE_NOTES: state => {
      state.gottenInsuranceNotes = true;
    },
    SET_INSURANCE_NOTES: (state, payload) => {
      state.insuranceNotes = payload;
    },
  },
  actions: {
    async getOpeningTime({ commit, rootGetters }) {
      try {
        const companyCode = getQueryString('companyCode') || sessionStorage.getItem('_companyCode');
        if (!companyCode) return;
        const token = JSON.parse(window.localStorage.getItem('loginMsg'))?.jwtToken;
        const insuredId = rootGetters?.['$_account/insuredId'] || null;
        const yearPlanService = token
          ? apis.getYearPlanOpenPeriodConfig
          : insuredId
          ? apis.getYearPlanOpenPeriodConfigByInsuredId
          : null;
        if (!yearPlanService) return;
        const { data, code } = await yearPlanService({ companyCode, insuredId });
        if (code === '0') {
          commit('SET_DATA', data || []);
          if (data?.[0]?.insuredId) {
            commit('$_account/SET_INSURED_ID', data?.[0]?.insuredId, { root: true });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getLifeEventOpeningTime({ commit, rootGetters }) {
      try {
        const companyCode = getQueryString('companyCode') || sessionStorage.getItem('_companyCode');
        if (!companyCode) return;
        const insuredId = rootGetters?.['$_account/insuredId'] || null;
        const { data, code } = await apis.getLifeEventOpenPeriodConfig({ companyCode, insuredId });
        if (+code === 0 && data?.openPeriodId) {
          commit('SET_LIFE_EVENT_DATA', data);
          if (data?.insuredId) {
            commit('$_account/SET_INSURED_ID', data?.insuredId, { root: true });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
