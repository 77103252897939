import request from '@/utils/request';
const options = {};

// const $get = request.get(options);
const $post = request.post(options);

// 查询随访表单配置
export const getFollowFormApi = params =>
  $post(`/api/insurance-fw/open/h5/detail`, {
    ...params,
  });

// 保存高管信息收集
export const addFollowAnswerApi = params =>
  $post(`/api/insurance-fw/follow-up/form/add-answer`, {
    ...params,
  });
