/**
 * 无需 完善信息 就可以访问的页面，存放的是 路由 的 name
 * 不使用路由添加 meta 的方式是因为考虑到客户会定制白名单，通过列表形式更容易处理
 */
import { isAAutoQuicker, isTencentC, isBaidu, isLiby, isCatl } from '@/constants/source';

// 通用
const baseList = [
  'middleware',
  'login',
  'authenticate',
  'supply',
  'email',
  'error',
  'sso',
  'homepage',
  'homeUnopened',
  'aboutUs',
  'spAgreement',
  'spPrivacy',
  'noLoginHomepage',
  'healthCabinHomepage',
  'healthCabinAppointment',
  'healthCabinResult',
  'healthCabinOrder',
  'healthCabinServices',
  'medicalHome',
  'dedicatedService',
  'residentAddress',
  'cms',
  'cmsInvalid',
];

// 快手定制
const AAutoQuickerList = isAAutoQuicker()
  ? [
      'aqSso',
      'aqError',
      'authAq',
      'authSp',
      'aqAgreement',
      'aqPrivacy',
      'healthCabinContainer',
      'healthCabinAuthAq',
      'healthCabinAuthSp',
    ]
  : [];

// 腾讯子公司
const TencentCList = isTencentC ? ['welfarePolicy', 'insurance'] : [];

// 百度
const BaiduList = isBaidu() ? ['welfarePolicy', 'insurance'] : [];

// 立白
const LibyList = isLiby ? ['welfarePolicy', 'insurance'] : [];

// 宁德时代
const CatlList = isCatl() ? ['catlNotification', 'catlAnnouncement'] : [];

export default [
  ...baseList,
  ...AAutoQuickerList,
  ...TencentCList,
  ...BaiduList,
  ...LibyList,
  ...CatlList,
];
