/**
 * 事件追踪（埋点）
 */

import request from '@/utils/request';
import { isMobile } from '@/utils';
import store from '@/store';

const options = {};
const $post = request.post(options);

const getUUID = () => {
  const createUUID = () => {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    window.sessionStorage.setItem('HEALTH_FW_STATIC_UUID', uuid);
    return uuid;
  };

  return window.sessionStorage.getItem('HEALTH_FW_STATIC_UUID') || createUUID();
};

const getDeviceModel = () => {
  let os;
  if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1) {
    os = 'Android';
  } else if (
    navigator.userAgent.indexOf('iPhone') > -1 ||
    navigator.userAgent.indexOf('iPad') > -1
  ) {
    os = 'iOS';
  } else if (navigator.userAgent.indexOf('Windows Phone') > -1) {
    os = 'WP';
  } else {
    os = 'Others';
  }
  return os;
};

const getNetworkType = () => {
  const ua = navigator.userAgent;
  let networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
  networkStr = networkStr.toLowerCase().replace('nettype/', '');
  let networkType;
  switch (networkStr) {
    case 'wifi':
      networkType = 'wifi';
      break;
    case '5g':
      networkType = '5g';
      break;
    case '4g':
      networkType = '4g';
      break;
    case '3g':
      networkType = '3g';
      break;
    case '3gnet':
      networkType = '3g';
      break;
    case '2g':
      networkType = '2g';
      break;
    default:
      networkType = 'other';
  }
  return networkType;
};

const getCompanyCode = () => window.sessionStorage.getItem('_companyCode') || '';

const getPlatform = () => {
  const UA = window.navigator.userAgent.toLowerCase();
  const isWxwork = /wxwork/i.test(UA);
  const isWechat = /micromessenger/i.test(UA) && !isWxwork;
  const isLark = /TTwebview/i.test(UA) || /Lark/i.test(UA);
  const isDingTalk = /DingTalk/i.test(UA);

  if (isWxwork) return 'WXWORK';
  if (isWechat) return 'WECHAT';
  if (isLark) return 'LARK';
  if (isDingTalk) return 'DINGTALK';
  if (isMobile()) return 'H5';
  return 'PC';
};

export default async (eventId = '', pageName = '', eventType = 'click', others = {}) => {
  const isVisitor = store.state?.$_productDetail?.isVisitor;

  const params = {
    systemId: 'health-fw-static',
    systemType: 'FE',
    bizChannel: 'FW',
    biz_type: 'CONSUMER',
    pageId: window.location.pathname,
    uuid: getUUID(),
    deviceId: '',
    deviceModel: getDeviceModel(),
    networkType: getNetworkType(),
    companyCode: getCompanyCode(),
    platform: getPlatform(),
    eventType,
    eventId,
    pageName,
    tp: 0,
    ip: window.sessionStorage.getItem('_internetProtocol'),
    proxyOperator: window.sessionStorage.getItem('_agent'),
    ext1: others?.ext1 || null,
    ext2: others?.ext2 || null,
    ext3: isVisitor ? '已下单' : '未下单',
  };
  return $post(
    '/api/health-treatment-client/v1/open/trackingLog/collect',
    { ...params },
    { hideLoading: true, errorHideToast: true },
  ).catch(() => {
    console.log(`埋点异常_pageId:${window.location.pathname}_eventId:${params?.eventId}`);
  });
};
