import request from '@/utils/request';
const options = {};

const $post = request.post(options);

// 保存家属授权页文件
export const submitFamilyFile = params =>
  $post(`/api/insurance-fw/family/authorize/save`, { ...params });

// 获取家属授权页文件
export const getFamilyFile = params =>
  $post(`/api/insurance-fw/family/authorize/select`, { ...params });

// 上传家属授权页文件
export const uploadFamilyFile = params =>
  $post(`/api/insurance-fw/family/authorize/upload-file`, { ...params }, { type: 'form' });
