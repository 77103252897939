<template>
  <footer v-if="bottomNavigationShow" id="app-footer" :class="['flex-row', iosCompatibility]">
    <template v-if="(navigationData.picList || []).length > 0">
      <div
        class="nav flex-column align-center"
        v-for="(nav, index) in navigationData.picList"
        :key="`nav${index}`"
        :style="{ width: 100 / navigationData.picList.length + '%' }"
        @click="skipAction(nav, navigationData.itemKey, true)"
      >
        <div
          class="svg-icon"
          :style="{
            webkitMaskImage: `url(${nav.picUrl})`,
            maskImage: `url(${nav.picUrl})`,
            background: getColor(nav),
          }"
        ></div>
        <span :style="{ color: getColor(nav) }">{{ nav.configName }}</span>
      </div>
    </template>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mixins from '@/mixins';
import { isAsiaInfo } from '@/constants/source';
import { isIOS } from '@/utils';

export default {
  name: 'BottomNavBar',
  mixins: [mixins],
  props: {
    findVal: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['themeColor']),
    ...mapGetters('$_config', ['showLanguageSwitch', 'navigation']),
    iosCompatibility() {
      return isIOS() ? (isAsiaInfo ? 'ai-ios-compatibility' : 'normal-ios-compatibility') : '';
    },
    bottomNavigationShow() {
      const lang = window.localStorage.getItem('_language') || 'cn';
      return +this.navigation?.find(i => i?.lang === lang)?.itemList?.[0]?.avaliableStatus === 1;
    },
    navigationData() {
      const lang = window.localStorage.getItem('_language') || 'cn';
      return this.navigation?.find(i => i?.lang === lang)?.itemList?.[0] || { picList: [] };
    },
  },
  data() {
    return {
      defaultColor: '#666',
      isAsiaInfo,
    };
  },
  created() {
    this.getNavigation();
  },
  methods: {
    ...mapActions('$_config', ['getNavigation']),
    getColor(val) {
      return this.findVal?.redirectType == val?.redirectType ? this.themeColor : this.defaultColor;
    },
  },
};
</script>

<style lang="less" scoped>
#app-footer {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(68, 134, 251, 0.1);
  .nav {
    padding: 5px 0 3px;
    text-align: center;
    img {
      width: 24px;
      height: 24px;
    }
    span {
      margin-top: 2px;
      font-size: 10px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

.svg-icon {
  width: 24px;
  height: 24px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}
.ai-ios-compatibility {
  padding-bottom: 12px;
  padding-bottom: 12px;
}
.normal-ios-compatibility {
  padding-bottom: calc(16px + constant(safe-area-inset-bottom));
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}
.iconfont {
  font-size: 24px;
  line-height: 24px;
}
</style>
