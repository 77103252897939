import request from '@/utils/request';
const options = {};

const $post = request.post(options);

// 查询年度计划
export const getQuery = params =>
  $post(`/api/insurance-fw/h5/plan/query`, {
    ...params,
  });

// 取消年度计划
export const cancel = params => $post(`/api/insurance-fw/h5/plan/cancel?planID=${params}`);

// 年度计划被保险人更新
export const updateFrom = params =>
  $post(`/api/insurance-fw/h5/plan/item/update`, {
    ...params,
  });

// 年度计划下单
export const placeOrder = params =>
  $post(`/api/insurance-fw/h5/plan/order`, params, {
    errorHideToast: true,
  });

// 告知书校验
export const checkYearPlanHealthInfo = params =>
  $post(`/api/insurance-fw/h5/plan/checkYearPlanHealthInfo?planID=${params}`, {
    ...params,
  });

//校验年度福利 被保险人和家庭成员信息不一致
export const checkFamilyInfo = params =>
  $post(`/api/insurance-fw/h5/plan/checkFamilyInfo?planID=${params}`);

// 校验年度福利 被保险人和家庭成员信息不一致
export const getPlanDiscount = params =>
  $post(`/api/insurance-fw/h5/discount/getDiscount`, {
    ...params,
  });
// 开窗时间
export const getYearPlanOpenPeriodConfig = params =>
  $post(
    `/api/insurance-fw/h5/plan/getYearPlanOpenPeriodConfig`,
    { ...params },
    { errorHideToast: true },
  );

// 开窗时间（无token通过insuredId）
export const getYearPlanOpenPeriodConfigByInsuredId = params =>
  $post(
    `/api/insurance-fw/open/h5/getYearPlanOpenPeriodConfig`,
    { ...params },
    { errorHideToast: true },
  );
// 人生事件开窗时间
export const getLifeEventOpenPeriodConfig = params =>
  $post(
    `/api/insurance-fw/h5/plan/getLifeEventOpenPeriodConfig`,
    { ...params },
    { errorHideToast: true },
  );

// 人生事件开窗时间（无token通过insuredId）
export const getLifeEventOpenPeriodConfigByInsuredId = params =>
  $post(
    `/api/insurance-fw/open/h5/getLifeEventOpenPeriodConfig`,
    { ...params },
    { errorHideToast: true },
  );

/** @name 校验商品 */
export const purchaseCheckForYearPlanAll = params =>
  $post(
    `/api/insurance-fw/h5/rule/purchaseCheckForYearPlanAll`,
    {
      ...params,
    },
    {
      errorHideToast: true,
    },
  );

// 计算福利计划的积分抵扣情况
export const calculatePoints = params =>
  $post(`/api/insurance-fw/h5/plan/calculatePointsAmount4Order`, {
    ...params,
  });
// 计算福利计划的快手积分抵扣情况
export const calculateQuickerPoints = params =>
  $post(`/api/insurance-fw/kuaishou/transaction/calculatePoints`, {
    ...params,
  });

//查询选择福利页list商品详情
export const getSpuListWithCrowd = params =>
  $post(`/api/insurance-fw/h5/rule/getSpuListWithCrowd`, { ...params });

// 判断是否符合开启一键续保的条件
export const checkOneKeyRenewal = params =>
  $post(`/api/insurance-fw/h5/plan/getRenewalTipFlag`, { ...params });

// 判断是否已下单
export const checkHasOrder = params =>
  $post(`/api/insurance-fw/h5/plan/getFWOrderStatus`, { ...params });

// 校验是否需要手动填写积分抵扣
export const ckeckPointManualDeduction = params =>
  $post(`/api/insurance-fw/h5/plan/getPayInfo`, { ...params });
