import { Locale } from 'vant';
import zhCN from 'vant/es/locale/lang/zh-CN';
import enUS from 'vant/es/locale/lang/en-US';
import i18n from '@/lang';
import { mapMutations } from 'vuex';

export default {
  inject: ['languageSwitchReload'],
  data() {
    const lang = window.localStorage.getItem('_language') || 'cn';
    return {
      switchSrc: require(`@/assets/images/shortcut/language_switch_bytedance_${lang}.png`),
      mobileSwitchSrc: require(`@/assets/images/shortcut/language_switch_bytedance_${lang}.png`),
    };
  },
  methods: {
    ...mapMutations('$_config', ['SET_LANGUAGE']),
    // 变更语言
    async onSwitch() {
      const lang = this.$i18n.locale === 'cn' ? 'en' : 'cn';
      this.SET_LANGUAGE(lang);
      window.localStorage.setItem('_language', lang);
      this.$i18n.locale = lang;
      this.switchSrc = require(`@/assets/images/shortcut/language_switch_bytedance_${lang}.png`);
      this.mobileSwitchSrc = require(`@/assets/images/shortcut/language_switch_bytedance_${lang}.png`);
      if (lang === 'en') {
        Locale.use('en-US', enUS);
      } else {
        Locale.use('zh-CN', zhCN);
      }
      this.languageSwitchReload();
    },
  },
};

export const setDefaultLanguage = lang => {
  window.localStorage.setItem('_language', lang);
  i18n.locale = lang;
  if (lang === 'en') {
    Locale.use('en-US', enUS);
  } else {
    Locale.use('zh-CN', zhCN);
  }
};
