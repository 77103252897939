import request from '@/utils/request';
const options = {};

const $post = request.post(options);

// 获取承保公司
export const getInsuranceCompany = params =>
  $post(`/api/jkb-user-center/v1/supply/stream/getInsuranceCompany`, { ...params });

// 获取平安理赔链接
export const getPingAnClaimLink = params =>
  $post(`/api/jkb-user-center/v1/outside/getPinganIndex`, { ...params });

// 查询人员层级（可见分类）数据
export const getMemberVisibleClassify = params =>
  $post(`/api/insurance-fw/h5/company/member/findMemberVisibleClassifyByTokenAndComId`, {
    ...params,
  });

// 获取友宝普通员工理赔链接
export const getYouBaoCommonClaimLink = params =>
  $post(`/api/jkb-user-center/v1/outside/getThirdCMSUrl`, {
    ...params,
  });

// 获取中邮人寿理赔链接
export const getZhongYouClaimLink = params =>
  $post(`/api/jkb-user-center/v1/outside/zhongYou/getClaimUrl `, {
    ...params,
  });

// 获取英硕商城链接
export const getEnsureStoreLink = () => $post(`/api/jkb-user-center/v1/outside/getEnsureMallUrl`);
