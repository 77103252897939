import elmentZhLocale from 'element-ui/lib/locale/lang/zh-CN';

export default {
  // 企业通用
  GLOBAL_000: '加载中',
  GLOBAL_001: '平台服务协议',
  GLOBAL_002: '隐私政策',
  GLOBAL_003: '温馨提示',
  GLOBAL_004:
    '即将跳转至京东健康页面，在线购药服务由京东健康提供，如您对购药订单、药品配送及药品由任何问题，请联系京东健康客服。',
  GLOBAL_005: '知道了',
  GLOBAL_006: '本人',
  GLOBAL_007: '配偶',
  GLOBAL_008: '子女',
  GLOBAL_009: '父母',
  GLOBAL_010: '父亲',
  GLOBAL_011: '母亲',
  GLOBAL_012: '配偶父母',
  GLOBAL_013: '配偶父亲',
  GLOBAL_014: '配偶母亲',
  GLOBAL_015: '其他',
  GLOBAL_016: '元',
  GLOBAL_017: '查看详情',
  GLOBAL_018: '余额',
  GLOBAL_019: '温馨提示',
  GLOBAL_020: '确认',
  GLOBAL_021: '续保',
  GLOBAL_022: '全部',
  GLOBAL_023: '待支付',
  GLOBAL_024: '待承保',
  GLOBAL_025: '已完成',
  GLOBAL_026: '已取消',
  GLOBAL_027: '待退款',
  GLOBAL_028: '已退款',
  GLOBAL_029: '被保险人',
  GLOBAL_029_P: '被保险人',
  GLOBAL_029_S: '被保险人',
  GLOBAL_030: '保障额度',
  GLOBAL_031: '免赔额',
  GLOBAL_032: '赔付比例',
  GLOBAL_033: '取消成功',
  GLOBAL_034: '即将跳往支付页面，请稍等',
  GLOBAL_035: '福利计划开窗期重复',
  GLOBAL_036: '暂未开启年度选择',
  GLOBAL_037: '暂未开启福利选择',
  GLOBAL_038: '暂未开启月度选择',
  GLOBAL_039: '暂未开启',
  GLOBAL_040: '不在购买名单内，无法购买',
  GLOBAL_041: '温馨提示',
  GLOBAL_042:
    '人生事件是针对年度/月度注册结束后的新婚配偶或新生子女的加保，您当前暂未开启人生事件选择，详细政策请参考福利手册',
  GLOBAL_043: '确定',
  GLOBAL_044: '暂未开通此功能，敬请期待！',
  GLOBAL_045: '温馨提示',
  GLOBAL_046: '该商品您已经提交过订单，若需修改，请前往我的保单进行取消。',
  GLOBAL_047: '查看订单',
  GLOBAL_048: '温馨提示',
  GLOBAL_049: '该商品您已经提交过订单，若需修改，请前往我的保单进行取消。',
  GLOBAL_050: '查看订单',
  GLOBAL_051: '温馨提示',
  GLOBAL_052: '被保险人{name}不符合本产品的参保年龄范围，请更换其他人投保。',
  GLOBAL_053: '确定',
  GLOBAL_054: '订单已取消',
  GLOBAL_055: '取消原因',
  GLOBAL_056: '信息填写有误',
  GLOBAL_057: '需要移除被保人',
  GLOBAL_058: '不想买了',
  GLOBAL_059: '其他【自行填写原因】',
  GLOBAL_061: '请输入',
  GLOBAL_062: '提交',
  GLOBAL_063: '请选择取消原因',
  GLOBAL_064: '请填写取消原因',
  GLOBAL_065: '请选择',
  GLOBAL_066: '请输入',
  GLOBAL_067: '温馨提示',
  GLOBAL_060: '确认',
  GLOBAL_111: '取消',
  GLOBAL_068: '身份证',
  GLOBAL_069: '护照',
  GLOBAL_070: '出生证',
  GLOBAL_071: '军官证',
  GLOBAL_072: '居民户口簿',
  GLOBAL_073: '港澳居民来往内地通行证',
  GLOBAL_074: '台湾居民来往大陆通行证',
  GLOBAL_075: '外国人永久居住证',
  GLOBAL_076: '港澳居民居住证',
  GLOBAL_077: '台湾居民居住证',
  GLOBAL_078: '其他',
  GLOBAL_079: '男',
  GLOBAL_080: '女',
  GLOBAL_081: '其他',
  GLOBAL_082: '积分',
  GLOBAL_083: '工资抵扣',
  GLOBAL_084: '微信支付',
  GLOBAL_085: '支付宝支付',
  GLOBAL_086: '组合支付',
  GLOBAL_087: '积分、工资抵扣组合支付',
  GLOBAL_088: '线下支付',
  GLOBAL_089: '中国',
  GLOBAL_090: '中国香港',
  GLOBAL_091: '中国澳门',
  GLOBAL_092: '中国台湾',
  GLOBAL_093: '美国',
  GLOBAL_094: '加拿大',
  GLOBAL_095: '英国',
  GLOBAL_096: '马来西亚',
  GLOBAL_097: '澳大利亚',
  GLOBAL_098: '日本',
  GLOBAL_100: '支付金额',
  GLOBAL_101: '总价',
  GLOBAL_102: '已优惠',
  GLOBAL_103: '（折扣率{num}%）',
  GLOBAL_104: '抵扣',
  GLOBAL_105: '查看明细',
  GLOBAL_106: '金额明细',
  GLOBAL_107: '最终抵扣金额以下单为准',
  GLOBAL_108: '温馨提示',
  GLOBAL_109: '返回修改',
  GLOBAL_110: '倍',
  GLOBAL_200: '网络异常，请尝试刷新！',
  GLOBAL_300: '国籍',
  LOGIN_000: '登录',
  LOGIN_001: '手机号',
  LOGIN_002: '请输入手机号',
  LOGIN_003: '请输入手机号',
  LOGIN_004: '请输入正确手机号',
  LOGIN_005: '验证码',
  LOGIN_006: '请输入验证码',
  LOGIN_007: '获取验证码',
  LOGIN_008: '{count}秒后重新发送',
  LOGIN_009: '同意{a}及{b}条款并注册',
  LOGIN_010: '请先同意《平台服务协议》及《隐私政策》',
  LOGIN_011: '请完整填写登录信息',
  LOGIN_012: '登录',
  AUTH_001: '姓名',
  AUTH_002: '请输入姓名',
  AUTH_003: '请输入正确的姓名',
  AUTH_004: '退出登录',
  AUTH_005: '提交',
  AUTH_006: '请完整填写认证信息',
  COMPLETE_000: '投保前，请你完善员工个人信息',
  COMPLETE_001: '员工姓名',
  COMPLETE_002: '请输入员工姓名',
  COMPLETE_003: '证件类型',
  COMPLETE_004: '请选择证件类型',
  COMPLETE_005: '证件号',
  COMPLETE_006: '请输入证件号',
  COMPLETE_007: '性别',
  COMPLETE_008: '请选择性别',
  COMPLETE_009: '出生日期',
  COMPLETE_010: '请选择出生日期',
  COMPLETE_011: '退出登录',
  COMPLETE_012: '提交',
  COMPLETE_013: '确定',
  COMPLETE_014: '请确认已完整填写信息',
  COMPLETE_015: '请输入正确的姓名',
  COMPLETE_016: '您的姓名有误，请重新填写',
  COMPLETE_017: '请输入正确的证件号',
  COMPLETE_018: '温馨提示',
  COMPLETE_019: '返回修改',
  COMPLETE_020: '确认无误',
  COMPLETE_021: '所填年龄超过65周岁，请确认是否为员工本人信息。',
  COMPLETE_022: '所填年龄小于18周岁，请确认是否为员工本人信息。',
  COMPLETE_023: '请选择国籍',
  EMAIL_001: '企业员工邮箱认证',
  EMAIL_002: '获取验证码',
  EMAIL_003: '重新发送',
  EMAIL_004: '{count}s后重新发送',
  EMAIL_005: '退出登录',
  EMAIL_006: '提交',
  EMAIL_007: '请选择邮箱后缀',
  EMAIL_008: '确定',
  EMAIL_009: '请输入邮箱前缀',
  EMAIL_010: '请输入邮箱验证码',
  EMAIL_011: '请输入邮箱',
  EMAIL_012: '邮箱前缀超长，最大32个字符',
  EMAIL_013: '仅支持英文、数字、点、减号或者下划线',
  EMAIL_014: '请完整填写信息',
  PCFORBID_001: '请通过手机端访问',
  UPOPEN_001: '您的企业暂未开启福利商城',
  UPOPEN_002: '您的企业暂未开启福利派',
  USERCENTER_000: '个人中心',
  USERCENTER_001: '电话',
  USERCENTER_002: '证件号',
  USERCENTER_003: '退出登录',
  USERCENTER_004: '请输入新手机号',
  USERCENTER_005: '目前绑定的手机号是{Phone} 请输入新的手机号码',
  USERCENTER_006: '新手机号',
  USERCENTER_007: '短信验证码已发至{Phone}，请注意查收。',
  USERCENTER_008: '请输入',
  USERCENTER_009: '请输入手机号',
  USERCENTER_010: '请输入正确手机号',
  USERCENTER_011: '请输入验证码',
  USERCENTER_012: '请输入文字验证码',
  USERCENTER_013: '发送验证码',
  USERCENTER_014: '验证码发送至{Phone}',
  USERCENTER_015: 's后重新发送',
  USERCENTER_016: '重新发送验证码',
  USERCENTER_017: '提交',
  USERCENTER_018: '该手机号与当前手机号相同',
  USERCENTER_019: '修改成功',
  ABOUTUS_000: '关于我们',
  ABOUTUS_001: '公司简介',
  FAMILYLIST_001: '家庭成员管理',
  FAMILYLIST_002: '岁',
  FAMILYLIST_003: '添加家庭成员',
  FAMILYLIST_004: '添加家庭成员',
  FAMILYDETAIL_001: '家庭成员信息',
  FAMILYDETAIL_002: '个人信息',
  FAMILYDETAIL_003: '姓名',
  FAMILYDETAIL_004: '请输入',
  FAMILYDETAIL_005: '请输入姓名',
  FAMILYDETAIL_006: '请输入正确的姓名',
  FAMILYDETAIL_007: '证件类型',
  FAMILYDETAIL_008: '请选择',
  FAMILYDETAIL_009: '请选择证件类型',
  FAMILYDETAIL_010: '证件号码',
  FAMILYDETAIL_011: '请输入',
  FAMILYDETAIL_012: '请输入证件号',
  FAMILYDETAIL_013: '请输入正确的证件号码',
  FAMILYDETAIL_014: '与本人关系',
  FAMILYDETAIL_015: '请选择',
  FAMILYDETAIL_016: '请选择与本人关系',
  FAMILYDETAIL_017: '性别',
  FAMILYDETAIL_018: '请选择',
  FAMILYDETAIL_019: '请选择性别',
  FAMILYDETAIL_020: '出生日期',
  FAMILYDETAIL_021: '请选择',
  FAMILYDETAIL_022: '请选择出生日期',
  FAMILYDETAIL_023: '结婚日期',
  FAMILYDETAIL_024: '请选择（选填）',
  FAMILYDETAIL_025: '联系手机号',
  FAMILYDETAIL_026: '请输入（选填）',
  FAMILYDETAIL_027: '请选择',
  FAMILYDETAIL_028: '请输入手机号',
  FAMILYDETAIL_029: '请输入正确手机号',
  FAMILYDETAIL_030: '删除家庭成员',
  FAMILYDETAIL_031: '确定',
  FAMILYDETAIL_032: '确定',
  FAMILYDETAIL_033: '删除',
  FAMILYDETAIL_034:
    '删除家属将清空该家属当前所有未提交的福利选择，已支付完成的选择不受影响。请确认是否删除该家属信息？',
  FAMILYDETAIL_035: '删除',
  FAMILYDETAIL_036: '取消',
  FAMILYDETAIL_037: '删除家庭成员成功，即将回到列表页',
  FAMILYDETAIL_038: '继续修改',
  FAMILYDETAIL_039: '取消',
  FAMILYDETAIL_040: '新增成功',
  FAMILYDETAIL_041: '修改成功',
  FAMILYDETAIL_042: '添加家庭成员',
  FAMILYDETAIL_043: '编辑家庭成员',
  FAMILYDETAIL_044: '请选择国籍！',
  FAMILYDETAIL_045: '员工本人信息不支持在平台自助修改，如需修改请联系专属客服或HR值班号。',
  HOMEPAGE_000: '首页',
  HOMEPAGE_001: '福利计划开窗期重复',
  HOMEPAGE_002: '温馨提示',
  HOMEPAGE_003: '下次不再弹出',
  CHOOSE_001: '确定选择',
  CHOOSE_002: '温馨提示',
  CHOOSE_003: '以下被保险人投保信息请修改',
  CHOOSE_004: '投保信息与家庭成员中的信息不一致',
  CHOOSE_005: '返回修改',
  CHOOSE_006: '请选择商品',
  CHOOSE_007: '温馨提示',
  CHOOSE_008: '您有未完善的健康告知信息',
  CHOOSE_008_P: '您有未完善的被保险人信息',
  CHOOSE_009: '确定即进入{page}',
  CHOOSE_010: '商品详情页',
  CHOOSE_011: '确定',
  CHOOSE_012: '查看福利',
  CHOOSE_013: '完成支付',
  CHOOSE_014: '重新选择',
  CHOOSE_015: '年度计划订单状态发生变化暂不可修改，请联系平台或者HR！',
  CHOOSE_016: '查看方案',
  // TODO: 待定
  CHOOSE_017: '变更选择',
  CHOOSE_018: '去选择',
  CHOOSE_019: '确定',
  CHOOSE_020: '取消',
  CHOOSE_021: '您有未提交的福利自选方案。为确保福利按时生效，请尽快完成支付。',
  CHOOSE_022: '您已提交了福利自选方案。您可以查看已选方案或重新选择。',
  CHOOSE_023: '重新选择会先取消之前自选订单，您确定要重新选择吗？',
  CHOOSE_024:
    '【重新选择】会取消您当前全部订单并立刻退款，需要您重新确认选择并完成支付。如开窗期内未完成重新支付，您的自选方案将无法生效。请问您确定要重新选择吗？',
  CHOOSE_025: '查看',
  CHOOSE_026: '温馨提示',
  CHOOSE_027: '',
  CHOOSE_028: '',
  PRODUCTDETAIL_000: '了解更多详情请查看',
  PRODUCTDETAIL_001: '确定',
  PRODUCTDETAIL_002: '提交订单（{num}）人',
  PRODUCTDETAIL_003: '温馨提示',
  PRODUCTDETAIL_004: '以下被保险人不符合限购规则',
  PRODUCTDETAIL_005: '可继续参保',
  PRODUCTDETAIL_006: '可投保',
  PRODUCTDETAIL_007: '返回修改',
  PRODUCTDETAIL_008: '为本人添加保障',
  PRODUCTDETAIL_009: '为您的家属添加保障',
  PRODUCTDETAIL_010: '为您和您的家属添加保障',
  PRODUCTDETAIL_011: '取消',
  PRODUCTDETAIL_012: '健康告知已填写，点击修改',
  PRODUCTDETAIL_013: '请填写健康告知',
  PRODUCTDETAIL_014: '查看方案',
  PRODUCTDETAIL_015: '变更信息',
  PRODUCTDETAIL_016: '添加本人保障',
  PRODUCTDETAIL_017: '添加家属保障',
  PRODUCTDETAIL_018: '添加保障',
  PRODUCTDETAIL_019: '温馨提示',
  PRODUCTDETAIL_020: '，不符合本产品的参保年龄范围。',
  PRODUCTDETAIL_021: '确定',
  PRODUCTDETAIL_022: '确定',
  PRODUCTDETAIL_023:
    '如需加保请点击“添加家属信息”完成后续操作。如不需加保请点击页面左上角返回按键退出页面。',
  PRODUCTDETAIL_024: '与员工关系',
  PRODUCTDETAIL_030: '查看订单',
  PRODUCTDETAIL_031: '年龄不符合限购规则',
  PRODUCTDETAIL_031_0: '年龄超过80岁，不符合投保规则', // 续保
  PRODUCTDETAIL_031_1: '年龄超过75岁，不符合投保规则', // 新保
  PRODUCTDETAIL_031_2: '子女年龄超过23岁，不符合投保规则',
  PRODUCTDETAIL_031_3: '子女的年龄不可大于本人年龄，请检查',
  PRODUCTDETAIL_031_4: '父母的年龄不可小于本人年龄，请检查',
  PRODUCTDETAIL_031_5: '配偶父母的年龄不可小于配偶年龄，请检查',
  PRODUCTDETAIL_031_6: '配偶年龄小于20岁，不符合投保规则',
  PRODUCTDETAIL_031_7: '员工年龄小于16岁，不符合投保规则',
  PRODUCTDETAIL_102: '已选：',
  PRODUCTDETAIL_103: '可兑换出',
  PRODUCTDETAIL_032: '请选择方案',
  PRODUCTDETAIL_033: '方案详情',
  PRODUCTDETAIL_034: '险种组合',
  PRODUCTDETAIL_035: '请选择计划',
  PRODUCTDETAIL_036: '同一类人群只能购买同一个方案或计划',
  PRODUCTDETAIL_037: '保障详情',
  PRODUCTDETAIL_038: '确认',
  PRODUCTDETAIL_039: '主被保险人',
  PRODUCTDETAIL_040: '连带被保险人',
  PRODUCTDETAIL_043: '继续添加被保险人',
  PRODUCTDETAIL_044: '下一步',
  PRODUCTDETAIL_045: '缺少主被保人!',
  PRODUCTDETAIL_046: '连带被保人数量不足！',
  PRODUCTDETAIL_047: '当前被保险人不符合限购规则！',
  PRODUCTDETAIL_048: '请先选择方案',
  PRODUCTDETAIL_049: '新增家属',
  PRODUCTDETAIL_050: '被保险人',
  PRODUCTDETAIL_051: '请输入姓名',
  PRODUCTDETAIL_052: '证件类型',
  PRODUCTDETAIL_053: '请选择',
  PRODUCTDETAIL_054: '证件号',
  PRODUCTDETAIL_055: '请输入证件号码',
  PRODUCTDETAIL_056: '出生日期',
  PRODUCTDETAIL_057: '请选择',
  PRODUCTDETAIL_058: '性别',
  PRODUCTDETAIL_059: '请选择',
  PRODUCTDETAIL_060: '结婚日期',
  PRODUCTDETAIL_061: '请选择',
  PRODUCTDETAIL_062: '确认添加',
  PRODUCTDETAIL_063: '确定',
  PRODUCTDETAIL_064: '当前被保险人已添加，不可重复投保！',
  PRODUCTDETAIL_065: '请先选择连带被保人家属关系！',
  PRODUCTDETAIL_066: '请输入{prefix}姓名',
  PRODUCTDETAIL_067: '{prefix}姓名输入有误',
  PRODUCTDETAIL_068: '请选择{prefix}证件类型',
  PRODUCTDETAIL_069: '请输入{prefix}{text}号码',
  PRODUCTDETAIL_070: '{prefix}{text}输入有误',
  PRODUCTDETAIL_071: '{prefix}{text}输入有误',
  PRODUCTDETAIL_072: '请选择{prefix}出生日期',
  PRODUCTDETAIL_073: '请选择{prefix}性别',
  PRODUCTDETAIL_074: '请选择结婚日期',
  PRODUCTDETAIL_075: '手机号输入有误，请重新输入',
  PRODUCTDETAIL_100: '保障详情',
  PRODUCTDETAIL_101: '确认',
  PRODUCTDETAIL_150: '查看全部方案',
  PRODUCTDETAIL_151: '重新选择',
  PRODUCTDETAIL_152: '返回',
  PRODUCTDETAIL_153: '已支付信息',
  PRODUCTDETAIL_154: '已选择方案',
  PRODUCTDETAIL_155: '不符合限购人群校验！',
  PRODUCTDETAIL_160: '当前购买人群不符合限购规则！',
  HEALTHDECLARE_001: '健康告知',
  HEALTHDECLARE_002: '健康告知填写进度（共计{num}份）',
  HEALTHDECLARE_004: '证件号',
  HEALTHDECLARE_005: '确认',
  HEALTHDECLARE_006: '确认提交',
  HEALTHDECLARE_007: '有部分问题',
  HEALTHDECLARE_008: '无以上问题，确认',
  HEALTHDECLARE_009: '健康告知',
  HEALTHDECLARE_010: '请在下方输入您的健康告知信息：',
  HEALTHDECLARE_011: '提交',
  HEALTHDECLARE_012: '温馨提示',
  HEALTHDECLARE_013: '对不起，您目前的健康状况不符合产品承保要求，请查看其他产品。',
  HEALTHDECLARE_014: '返回',
  HEALTHDECLARE_015: '请输入您的问题',
  HEALTHDECLARE_020: '请填写',
  HEALTHDECLARE_021: '图片上传不可超过10M',
  HEALTHDECLARE_022: '请上传 png、jpg 格式图片',
  HEALTHDECLARE_023: '上传中...',
  HEALTHDECLARE_024: '上传失败',
  HEALTHDECLARE_025: '图片上传中，请稍后再试',
  HEALTHDECLARE_026: '请为被保险人【{insured}】确认以上情况',
  CONFIRM_000: '提交订单',
  CONFIRM_001: '重新选择',
  CONFIRM_002: '温馨提示',
  CONFIRM_003: '确认提交',
  CONFIRM_004: '返回修改',
  CONFIRM_005: '请阅读并同意条款内容',
  CONFIRM_006: '温馨提示',
  CONFIRM_007: '年度计划已删除',
  CONFIRM_008: '确定',
  CONFIRM_009: '查看方案',
  CONFIRM_010: '保险责任详情',
  CONFIRM_011: '保险到期前我希望保险公司来电提醒续保',
  CONFIRM_012: '请先确认续保到期前是否允许保险公司电联提醒',
  CONFIRM_013: '是',
  CONFIRM_014: '否',
  CONFIRM_015_O: '我已阅读并同意',
  CONFIRM_015: '我已阅读并同意（{num}/{total}）',
  CONFIRM_016: '继续阅读',
  SETTLE_001: '结算确认',
  SETTLE_002: '金额',
  SETTLE_003: '支付金额',
  SETTLE_004: '总价',
  SETTLE_005: '抵扣',
  SETTLE_006: '确认支付',
  SETTLE_007: '您的结算页空荡荡的',
  SETTLE_008: '快去选择心仪的产品吧～',
  SETTLE_009: '确认删除？',
  SETTLE_010: '温馨提示',
  SETTLE_011: '返回修改',
  PAYRESULT_000: '支付结果',
  PAYRESULT_001: '您已完成本次选择！',
  PAYRESULT_002: '支付成功',
  PAYRESULT_003: '支付失败',
  PAYRESULT_004: '获取支付结果中',
  PAYRESULT_005: '保险名称',
  PAYRESULT_006: '参保人',
  PAYRESULT_007: '订单金额',
  PAYRESULT_008: '支付方式',
  PAYRESULT_009: '支付详情',
  PAYRESULT_010: '返回首页',
  PAYRESULT_011: '查看福利',
  ORDERLIST_001: '我的保险福利订单',
  ORDERLIST_002: '没有更多了',
  ORDERLIST_003: '订单编号',
  ORDERLIST_004: '支付金额',
  ORDERLIST_005: '立即支付',
  ORDERDETAIL_000: '请在{date}内完成支付，逾期订单将自动取消',
  ORDERDETAIL_001: '订单详情',
  ORDERDETAIL_002: '本平台仅做信息收集和订单展示，实际承保情况以保险公司确认为准。',
  ORDERDETAIL_003: '订单状态',
  ORDERDETAIL_004: '待保险公司核保完成后，即可承保。',
  ORDERDETAIL_005: '金额',
  ORDERDETAIL_006: '证件号',
  ORDERDETAIL_007: '订单号',
  ORDERDETAIL_008: '下单时间',
  ORDERDETAIL_009: '支付方式',
  ORDERDETAIL_010: '支付详情',
  ORDERDETAIL_011: '取消订单',
  ORDERDETAIL_012: '保障内容',
  ORDERDETAIL_013: '查看详情',
  ORDERDETAIL_014: '确认取消订单？',
  ORDERDETAIL_015: '注意：取消订单将会取消本次支付购买的所有产品',
  ORDERDETAIL_016: '确认',
  ORDERDETAIL_018: '取消成功',
  ORDERDETAIL_019: '组合支付',
  BALANCE_001: '可用余额',
  BALANCE_002: '使用说明',
  BALANCE_003: '累计获取',
  BALANCE_004: '积分有效期查询',
  BALANCE_005: '福利明细',
  BALANCE_006: '查看更多',
  BALANCE_007: '没有更多了',
  BALANCE_008: '说明',
  BALANCE_009: '暂无积分记录',
  BALANCE_010: '确定',
  BALANCE_011: '30天内即将过期',
  BALANCE_012: '明细',
  BALANCE_013: '全部',
  BALANCE_014: '已获取',
  BALANCE_015: '已消耗',
  BALANCE_016: '积分明细',
  BALANCE_017: '您近6个月积分到期情况',
  BALANCE_018: '{year} {date} 过期',
  BALANCE_019: '您近6个月内无到期积分',
  POLICY_001: '福利政策',
  OVERVIEW_001: '开启福利计划',
  OVERVIEW_002: '选择结果',
  OVERVIEW_003: '重新选择',
  OVERVIEW_004: '返回首页',
  OVERVIEW_005: '修改选择',
  OVERVIEW_006: '员工保障',
  OVERVIEW_007: '配偶保障',
  OVERVIEW_008: '子女保障',
  OVERVIEW_009: '父母保障',
  OVERVIEW_010: '配偶父母保障',
  OVERVIEW_011: '保险责任详情',
  OVERVIEW_012: '合计',
  OVERVIEW_013: '查看积分明细',
  OVERVIEW_014: '查看详情',
  OVERVIEW_015: '默认计划',
  INSURANCE_001: '可用于为自己及家人购买保险方案',
  PRIVACY_001: '进入',
  PRIVACY_002: '本人已阅读并同意',
  EXTRA_001: '温馨提示',
  EXTRA_002: '确定取消【{prefix}】的保险选择吗？',
  EXTRA_003: '确认取消',
  EXTRA_004: '返回',
  EXTRA_005: '手机号',
  EXTRA_006: '查看详情',
  EXTRA_007: '福利积分余额',
  EXTRA_008: '开启商保福利计划',
  EXTRA_016: '温馨提示：',
  EXTRA_017: '您的自选保障将进入待承保状态，预计将于开窗期结束后10个工作日内完成承保。',
  EXTRA_018: '保障生效日仍为自选当季度的第一天。',
  EXTRA_019: '自选计划',
  EXTRA_022: '返回',
  EXTRA_030: '',
  EXTRA_031: '',
  EXTRA_032: '',
  EXTRA_045: '',
  EXTRA_046: '',
  CABIN_001: '健康小屋',
  CABIN_002: '',

  ...elmentZhLocale,
};
