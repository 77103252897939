export default {
  enableDesktop: 1,
  defaultLang: 'cn',
  prePageConfig: {
    cn: {
      loginPage: {
        pageName: '登录',
        backgroundTone: '#f97844',
        showSla: 1,
        isSubPage: 0,
        itemList: [
          {
            avaliableStatus: 1,
            headLine: '',
            subTitle: '',
            picList: [
              {
                picUrl: require('@/assets/images/public/banner_h5.png'),
                pcPicUrl: require('@/assets/images/public/banner_pc.png'),
                redirectType: 0,
                num: 1,
              },
            ],
          },
        ],
      },
      fillPage: {
        pageName: '完善信息',
        backgroundTone: '#f97844',
        isSubPage: 0,
        itemList: [
          {
            avaliableStatus: 1,
            picList: [
              {
                picUrl: require('@/assets/images/public/banner_h5.png'),
                pcPicUrl: require('@/assets/images/public/banner_pc.png'),
                num: 1,
              },
            ],
          },
        ],
      },
      personalLogo: null,
    },
  },
  mainTone: '#f97844',
  personalShow: 0,
  comLogo:
    'https://jkb-test-public-cos.medbanks-test.com/flexible-welfare/202307191328adfaf8a951c741f8877be616541c4189.png',
  pcApplyFlag: 1,
  publicOpenFlag: 1,
  status: 1,
};

export const userCenterDefaultData = {
  comLogo:
    'https://jkb-test-public-cos.medbanks-test.com/flexible-welfare/20230330151715657dec103b44c5a6881792ae8bb8b9.png',
  comHeadImage:
    'https://jkb-test-public-cos.medbanks-test.com/flexible-welfare/202303301517b13778b3785a4066a94bf40fd969be9c.png',
  showComHeadImage: 0,
  status: 1,
  pageList: [
    {
      pageName: '个人中心',
      showSwitchCom: 1,
      showSla: 1,
      showLogout: 1,
      showHr: 0,
      deleted: 0,
      pointsShow: 0,
      paymentShow: 0,
      collectShow: 0,
      isSubPage: 0,
      itemList: [
        {
          id: '768173',
          pageId: '2893905',
          num: '1',
          itemKey: 'personInfo',
          itemName: '个人信息',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          showType: 1,
          notEdit: true,
          notDelete: true,
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [],
        },
        {
          id: '768174',
          pageId: '2893905',
          num: '2',
          itemKey: 'benefitDetail',
          itemName: '权益详情',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          showType: 1,
          notDelete: true,
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [
            {
              itemId: '768174',
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/20230821150961d99cc372884a9bb61c866d8615fda9.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/20230821150961d99cc372884a9bb61c866d8615fda9.png',
              redirectType: 0,
              url: '',
              skuCode: '',
              skuName: '',
              num: 1,
              colsNum: 0,
              deleted: 0,
            },
          ],
        },
        {
          id: '768157',
          pageId: '2893905',
          num: '4',
          itemKey: 'insuranceClaim',
          itemName: '保险理赔',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          notDelete: true,
          headLine: '保险理赔',
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [
            {
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/202308090931d4d79c69a37642478df3679ba838e07b.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/202308090931d4d79c69a37642478df3679ba838e07b.png',
              num: 1,
            },
          ],
        },
        {
          id: '768176',
          pageId: '2893905',
          num: '5',
          itemKey: 'medicalService',
          itemName: '医疗服务',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          notDelete: true,
          headLine: '医疗服务',
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [
            {
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/2023080810283c944a1eb62a46a7bdea9a3cf3c2ceac.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/2023080810283c944a1eb62a46a7bdea9a3cf3c2ceac.png',
              num: 1,
            },
          ],
        },
        {
          id: '768179',
          pageId: '2893905',
          num: '7',
          itemKey: 'familyMember',
          itemName: '家庭成员',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          showType: 1,
          notDelete: true,
          headLine: '家庭成员',
          subTitle: '修改成功',
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          remindContent:
            '您修改的家庭成员原有信息存在有效订单，为了避免影响订单投保信息请取消订单。',
          subRemindContent: '请前往个人中心-保险订单取消订单',
          picList: [
            {
              itemId: '768179',
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/2022072917430064731225b24cebb3b1a71a686145bc.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/2022072917430064731225b24cebb3b1a71a686145bc.png',
              redirectType: 0,
              url: '',
              skuCode: '',
              skuName: '',
              num: 1,
              colsNum: 0,
              deleted: 0,
            },
          ],
        },
        {
          id: '768180',
          pageId: '2893905',
          num: '8',
          itemKey: 'personPrescription',
          itemName: '我的处方',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          showType: 1,
          notDelete: true,
          headLine: '我的处方',
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [
            {
              itemId: '768180',
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/20220729181303c6ce0b785d4bdab93ea67d043f6dbc.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/20220729181303c6ce0b785d4bdab93ea67d043f6dbc.png',
              redirectType: 0,
              url: '',
              skuCode: '',
              skuName: '',
              num: 1,
              colsNum: 0,
              deleted: 0,
            },
          ],
        },
        {
          id: '768181',
          pageId: '2893905',
          num: '9',
          itemKey: 'updatePhone',
          itemName: '修改手机号',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          showType: 1,
          notDelete: true,
          headLine: '修改手机号',
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [
            {
              itemId: '768181',
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/20220729174373c073d146f34ab1986f6638d2676ac1.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/20220729174373c073d146f34ab1986f6638d2676ac1.png',
              redirectType: 0,
              url: '',
              skuCode: '',
              skuName: '',
              num: 1,
              colsNum: 0,
              deleted: 0,
            },
          ],
        },
        {
          id: '768182',
          pageId: '2893905',
          num: '10',
          itemKey: 'aboutUs',
          itemName: '关于我们',
          avaliableStatus: 1,
          itemType: 'PAGE_LIST',
          showType: 1,
          notDelete: true,
          headLine: '关于我们',
          countdownTypeWarmup: 1,
          countdownTypeOpenperiod: 1,
          picList: [
            {
              itemId: '768182',
              picUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/2022072917448336127823eb480885830620dfadaa40.png',
              pcPicUrl:
                'https://jkb-public-1300562124.cos.ap-beijing.myqcloud.com/flexible-welfare/2022072917448336127823eb480885830620dfadaa40.png',
              redirectType: 0,
              url: '',
              skuCode: '',
              skuName: '',
              num: 1,
              colsNum: 0,
              deleted: 0,
            },
          ],
        },
      ],
    },
  ],
  mainTone: window.sessionStorage.getItem('_access') === 'HM' ? '#f97844' : '#7CB302',
  personalShow: 0,
  bottomNavigationShow: 1,
  pcApplyFlag: 1,
  langExtendInfo: [
    {
      lang: 'cn',
      personalLogo: null,
    },
  ],
  version: 59,
  supportLangs: [],
  defaultLang: 'cn',
};
