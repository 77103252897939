var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("drag-click-item", {
    attrs: { "init-top": 40, source: _vm.mobileSwitchSrc },
    on: { "on-click": _vm.onSwitch },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }