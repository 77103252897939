import request from '@/utils/request';
const options = {};

const $get = request.get(options);
const $post = request.post(options);
const PREFIX = '/api';

// 根据状态获取保单列表
export const getPolicyList = params =>
  $post(`${PREFIX}/insurance-fw/h5/order/welfare/orders`, { ...params });

// 保单取消&退款
export const cancelPolicy = mainOrderNo =>
  $post(`${PREFIX}/insurance-fw/h5/order/welfare/order/cancel?mainOrderNo=${mainOrderNo}`);

/** @name 弹性福利订单取消原因补充 */
export const cancelPolicyReason = params =>
  $post(`${PREFIX}/insurance-fw/h5/order/welfare/order/cancel/reason`, { ...params });

// 查询子订单数据
export const masterPolicy = params =>
  $get(`${PREFIX}/trading-order/welfare/findSubOrderInfoListByMainOrderId`, { ...params });
// 查询同一商品下子订单数据
export const orderitemList = params =>
  $get(`${PREFIX}/trading-order/welfare/orderitemList`, { ...params });
