import request from '@/utils/request';
const options = {};

const $post = request.post(options);
const PREFIX = '/api';

// 获取家庭成员详情
export const getFamilyMemberDetail = params =>
  $post(`${PREFIX}/insurance-fw/h5/welfare/member/findInsured`, { ...params });

// 添加家庭人员
export const addInsured = params =>
  $post(
    `${PREFIX}/insurance-fw/h5/welfare/member/addInsured`,
    { ...params },
    {
      hideLoading: true,
    },
  );
// 修改家庭人员
export const editInsured = params =>
  $post(
    `${PREFIX}/insurance-fw/h5/welfare/member/editInsured`,
    { ...params },
    {
      hideLoading: true,
    },
  );
// 家庭列表
export const listInsured = params =>
  $post(`${PREFIX}/insurance-fw/h5/welfare/member/listInsured`, params, {
    hideLoading: true,
  });

// 删除家庭成员信息
export const deleteFamilyMember = params =>
  $post(`${PREFIX}/insurance-fw/h5/welfare/member/delInsured`, { ...params });
