<template>
  <div id="app" :style="cssVariable" v-if="appReady">
    <!-- h5 -->
    <template v-if="isMobile">
      <!-- h5内容主体 -->
      <div id="app-body">
        <keep-alive :include="['MedicalHome']">
          <router-view />
        </keep-alive>
      </div>

      <!-- h5导航 -->
      <template v-if="isBtns">
        <!-- h5导航 -->
        <bottom-nav-bar :findVal="findVal" v-if="isShow" />
        <!-- h5去往个人中心icon -->
        <drag-click-item
          v-if="showToUserCenter"
          :init-top="520"
          :source="toUserCenterSrc"
          @on-click="toUserCenter"
        />
      </template>

      <!-- h5去往首页icon -->
      <drag-click-item v-if="showToHome" :init-top="480" :source="toHomeSrc" @on-click="toHome" />

      <!-- 语言切换 -->
      <mobile-switch-language v-if="showMobileSwitchLanguage" />
    </template>

    <!-- pc -->
    <template v-else>
      <!-- 语言切换 -->
      <desktop-switch-language v-if="showDesktopSwitchLanguage" />
      <!-- pc导航 -->
      <template v-if="showDesktopGlobalHeader">
        <desktop-global-header :findVal="findVal" />
      </template>
      <!-- pc内容主体 -->
      <router-view />
    </template>

    <!-- 水印 -->
    <Watermark v-if="showWatermark" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import mixins from '@/mixins';
import BottomNavBar from '@/components/bottomNavBar.vue';
import DesktopGlobalHeader from '@/components/Desktop/globalHeader.vue';
import DragClickItem from '@/components/Mobile/dragClickItem.vue';
import { isMobile, isIOS } from '@/utils';
import Watermark from '@/components/watermark.vue';
import {
  isAAutoQuicker,
  isLexin,
  isAliGame,
  isAsiaInfo,
  isWenjian,
  isNetease,
  isLiXiang,
  sourceNoLogin,
} from '@/constants/source';
import DesktopSwitchLanguage from '@/components/Desktop/switchLanguage.vue';
import MobileSwitchLanguage from '@/components/Mobile/switchLanguage.vue';

const showNavNameList = [
  { name: 'homepage', redirectType: 9 },
  { name: 'noLoginHomepage', redirectType: 9 },
  { name: 'balance', redirectType: 11 }, // 积分页
  { name: 'balanceQuicker', redirectType: 11 }, // 快手积分定制页
  { name: 'userCenter', redirectType: 7 },
  { name: 'welfarePolicy', redirectType: 10 },
  { name: 'welfareOverviewDetail', redirectType: null },
  { name: 'medicalHome', redirectType: null },
  { name: 'dedicatedService', redirectType: null },
];
const blacklist = [
  'agent',
  'sso',
  'aqSso',
  'gclSso',
  'lfSso',
  'dreameSso',
  'aqError',
  'authAq',
  'authSp',
  'middleware',
  'login',
  'authenticate',
  'supply',
  'email',
  'certification',
  'error',
  'noLoginHomepage',
  'userCenter',
  'paymentResult',
  'homeUnopened',
  'forbid',
  'welfareOverviewDetailQuicker',
  'privacy',
  // 理赔相关
  'spClaim',
  'jdClaim',
  // 个险相关
  'neteasePrompt',
  'neteaseNotification',
  'spcarePrompt',
  'spcareNotification',
  // 健管相关
  'medicalHome',
  'dedicatedService',
  'residentAddress',
  // 健康小屋相关
  'healthCabinContainer',
  'healthCabinAuthAq',
  'healthCabinAuthSp',
  'healthCabinHomepage',
  'healthCabinAppointment',
  'healthCabinResult',
  'healthCabinOrder',
  'healthCabinServices',
  // 字节定制处理
  'promotionByteDance',
  // 精电定制处理
  'jdClaim',
  // 个性化页面配置
  'cms',
  'cmsInvalid',
  'healthSurvey',
];
const watermarkList = [
  'authAq',
  'authSp',
  'healthCabinAuthAq',
  'healthCabinAuthSp',
  'policy',
  'healthNotification',
  'welfareOverviewDetailQuicker',
  'aqAgreement',
  'aqPrivacy',
];
// 不需要返回刷新的页面
const noNeedReload = window.location.href.indexOf('/health-fw-static/aqClaim') > -1;

export default {
  mixins: [mixins],
  provide() {
    return {
      languageSwitchReload: this.languageSwitchReload,
    };
  },
  data() {
    return {
      appReady: false,
      activeName: '',
      isMobile: isMobile(),
      showMobileSwitchLanguage: false,
      showDesktopSwitchLanguage: false,
    };
  },
  computed: {
    ...mapGetters('$_account', ['sourceInfo']),
    ...mapGetters('$_config', [
      'showLanguageSwitch',
      'hasMergeLocale',
      'mergeLocale',
      'paidText',
      'showHomeIcon',
    ]),
    ...mapGetters(['themeColor', 'personalCenterIcon', 'showPersonalCenter']),
    cssVariable() {
      return {
        '--themeColor': this.themeColor,
      };
    },
    isBtns() {
      return (
        this.isMobile &&
        !['homeUnopened', 'privacyPolicy', 'privacyPolicyDetail', 'choose'].includes(
          this.$route.name,
        )
      );
    },
    isShow() {
      // 公众号不显示导航
      const isPublic = window.sessionStorage.getItem('_access') === 'PUBLIC';
      // 健管页面且是历史定制客户不展示
      const isHistory =
        (isAAutoQuicker() || isLexin || isAliGame || isAsiaInfo || isWenjian || isLiXiang) &&
        ['medicalHome', 'dedicatedService'].includes(this.$route.name);
      return (
        !isPublic &&
        !isHistory &&
        showNavNameList.some(item => {
          return item.name == this.activeName;
        })
      );
    },
    findVal() {
      return showNavNameList.find(item => {
        return item.name == this.activeName;
      });
    },
    showToUserCenter() {
      return !blacklist.includes(this.$route.name) && this.showPersonalCenter;
    },
    toUserCenterSrc() {
      const lang = this.$i18n.locale || 'cn';
      return this.personalCenterIcon || require(`@/assets/images/shortcut/usercenter_${lang}.png`);
    },
    showWatermark() {
      return watermarkList.includes(this.$route.name);
    },
    showToHome() {
      const { bizUrl } = this.sourceInfo;
      // 公众号环境从个人中心进入需隐藏
      const isPublicUserCenter =
        +window.sessionStorage.getItem('_isJian1bao') === 1 &&
        window.sessionStorage.getItem('_access') === 'PUBLIC';
      return (
        !isPublicUserCenter &&
        this.$route.name &&
        isMobile() &&
        !showNavNameList.some(item => {
          return item.name == this.activeName;
        }) &&
        !blacklist.includes(this.$route.name) &&
        this.$route.name !== 'insuranceDetail' &&
        !bizUrl &&
        !isNetease &&
        this.showHomeIcon
      );
    },
    toHomeSrc() {
      const lang = this.$i18n.locale || 'cn';
      return require(`@/assets/images/shortcut/to_home_${lang}.png`);
    },
    showDesktopGlobalHeader() {
      // pc端不需要页眉导航栏的routeName
      const list = [
        'agent',
        'middleware',
        'login',
        'authenticate',
        'certification',
        'supply',
        'email',
        'error',
        'homeUnopened',
        'forbid',
        'privacy',
        'aqError',
        'authAq',
        'authSp',
        // 个险页BBC相关
        'personalInsurance',
        'neteasePrompt',
        'neteaseNotification',
        'spcarePrompt',
        'spcareNotification',
        // 健康小屋相关
        'healthCabinContainer',
        'healthCabinAuthAq',
        'healthCabinAuthSp',
        'healthCabinHomepage',
        'healthCabinAppointment',
        'healthCabinResult',
        'healthCabinOrder',
        'healthCabinServices',
        'cms',
        'cmsInvalid',
        'healthSurvey',
      ];
      // 公众号不显示导航
      const isPublic = window.sessionStorage.getItem('_access') === 'PUBLIC';
      return !isPublic && !isMobile() && !list.includes(this.$route.name);
    },
  },
  created() {
    //ios返回 刷新页面
    if (isIOS() && !noNeedReload) {
      window.onpageshow = function(event) {
        if (event.persisted) {
          window.location.reload();
        }
      };
    }
    this.getI18nConfig();
  },
  watch: {
    $route: function(val) {
      this.activeName = val?.name;
      if (isMobile()) {
        const list = [
          'login',
          'authenticate',
          'supply',
          'email',
          'homepage',
          'noLoginHomepage',
          'userCenter',
          // 字节定制
          'promotionByteDance',
        ];
        this.showMobileSwitchLanguage = this.showLanguageSwitch && list.includes(this.$route.name);
      } else {
        // 因交互设计的关系，首页的切换开关放在了其他组件内
        // const list = ['login', 'authenticate', 'supply', 'email'];
        const list = ['confirm'];
        this.showDesktopSwitchLanguage =
          this.showLanguageSwitch && !list?.includes(this.$route.name);
      }
    },
    paidText: {
      handler(val) {
        const cn = { ...(this.mergeLocale?.cn || {}), GLOBAL_024: val?.cn };
        const en = { ...(this.mergeLocale?.en || {}), GLOBAL_024: val?.en };
        window.$i18n.mergeLocaleMessage('cn', cn);
        window.$i18n.mergeLocaleMessage('en', en);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('$_config', ['SET_HAS_MERGE_LOCALE', 'SET_MERGE_LOCALE']),
    ...mapActions('$_openingTime', ['getOpeningTime']),

    async getI18nConfig() {
      try {
        if (this.hasMergeLocale) {
          window.$i18n.mergeLocaleMessage('cn', this.mergeLocale?.cn || {});
          window.$i18n.mergeLocaleMessage('en', this.mergeLocale?.en || {});
        } else {
          if (window.sessionStorage.getItem('_companyCode')) {
            const { code, data } = await this.$apis.getCompanyLocaleConfig({
              companyCode: window.sessionStorage.getItem('_companyCode'),
            });
            if (+code === 0) {
              window.$i18n.mergeLocaleMessage('cn', data?.cn || {});
              window.$i18n.mergeLocaleMessage('en', data?.en || {});
              this.SET_HAS_MERGE_LOCALE();
              this.SET_MERGE_LOCALE(data || {});
            }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.appReady = true;
      }
    },

    toHome() {
      this.$router.push({
        name: sourceNoLogin ? 'noLoginHomepage' : 'homepage',
        pageType: 1,
        companyCode: sessionStorage.getItem('_companyCode') || '',
      });
    },

    toUserCenter() {
      this.skipAction({ redirectType: 7 }, '', 'icon');
    },

    async languageSwitchReload() {
      // 触发显示隐藏
      this.appReady = false;
      this.getOpeningTime();
      await this.$nextTick();
      this.appReady = true;
    },
  },
  components: {
    BottomNavBar,
    DesktopGlobalHeader,
    DragClickItem,
    Watermark,
    DesktopSwitchLanguage,
    MobileSwitchLanguage,
  },
};
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f7fa;
  &-body {
    position: relative;
    z-index: 10;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background: #f5f7fa;
  }
}
</style>
