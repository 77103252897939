import request from '@/utils/request';
const options = {};

const $get = request.get(options);
const $post = request.post(options);

// 获取健康小屋首页商品
export const getProducts = params =>
  $post('/api/health-treatment-client/v1/health/massage/store/list', params);

// 检验店铺内全部理疗师是否都已约满
export const checkCanAppoint = params =>
  $post(`/api/health-treatment-client/v1/health/massage/reservation/has_repertory_v2`, params);

// 获取服务项目列表
export const getServiceList = params =>
  $post(`/api/health-treatment-client/v1/health/massage/item/list`, params);

// 校验是否在惩罚期
export const checkIsPunished = storeId =>
  $get(
    `/api/health-treatment-client/v1/health/massage/info/punish/${storeId}`,
    {},
    {
      errorHideToast: true,
    },
  );

// 获取商品详情和理疗师
export const getProductsDetail = params =>
  $post(`/api/health-treatment-client/v1/health/massage/info/detail_v2`, params);

// 根据理疗师获取服务日期
export const getServiceDate = params =>
  $post(`/api/health-treatment-client/v1/health/massage/technician/days_v2`, params);

// 根据理疗师和服务日期获取服务时间
export const getServiceTimeList = params =>
  $post('/api/health-treatment-client/v1/health/massage/technician/times', params);

// 提交预约
export const submitAppointment = params =>
  $post('/api/health-treatment-client/v1/health/massage/reservation', params, {
    errorHideToast: true,
  });

// 获取预约列表
export const getAppointmentList = params =>
  $post('/api/health-treatment-client/v1/health/massage/reservation/list', params);

// 校验能否取消预约
export const checkCancelAppoint = reservationNo =>
  $get(
    `/api/health-treatment-client/v1/health/massage/reservation/cancel/check/${reservationNo}`,
    {},
    { errorHideToast: true },
  );

// 确认取消预约
export const confirmCancelAppoint = reservationNo =>
  $post(
    `/api/health-treatment-client/v1/health/massage/reservation/cancel/${reservationNo}`,
    {},
    { errorHideToast: true },
  );
