import request from '@/utils/request';
const options = {};
const $get = request.get(options);
const $post = request.post(options);

// 快手sso-触发跳转到sso链接（aqIndex）
export const aqFetchSsoLink = params => $get('/api/jkb-user-center/v1/open/saml/sso', params);

// 获取用户信息\同时校验员工合法性
export const checkStaffWhiteList = params =>
  $post('/api/jkb-user-center/v1/open/third/verifyWhiteEmployeeFlag', params, {
    errorHideToast: true,
  });

// 腾讯子公司-校验邮箱绑定-无需token
export const tcCheckBindEmial = params =>
  $get('/api/jkb-user-center/v1/open/user/accessEmailVerification', params);

// 通过一次性授权码获取员工信息
export const getInfoByOnceCode = params =>
  $get('/api/jkb-user-center/v1/open/third/verify/code', params, {
    hideLoading: true,
    errorHideToast: true,
  });

// 获取cms配置-快手理赔异常提示用到
export const getAqClaimErrorTip = pageId =>
  $get(
    `/combined-service/api/wx/v1/page/${pageId}`,
    {},
    {
      hideLoading: true,
      errorHideToast: true,
    },
  );

// 查询光大是否处于系统升级期间
export const getGuangDaInError = () =>
  $get(
    '/api/jkb-user-center/v1/outside/guangdaSystemUpgradeFlag',
    {},
    {
      hideLoading: true,
      errorHideToast: true,
    },
  );

// 获取贝壳投保跳众安的链接
export const getZhongAnLink = params =>
  $post('/api/insurance-fw/h5/beike/getZAwelfareUrl', params, {
    hideLoading: true,
  });

// sso跳转oauth2
export const getCommonOauth2 = params =>
  $get('/api/jkb-user-center/v1/open/oauth2/common/authorizeCode', params);

// 立白获取员工信息
export const getLibyClientInfo = params =>
  $post('/api/jkb-user-center/v1/open/user/getDuDuUserInfo', params);

// 获取优佳链接
export const getYoujiaUrl = params =>
  $post('/api/jkb-user-center/v1/supply/stream/getSupplierJumpUrl', params);

// 作业帮跳复星
export const getFosunLink = () => $post(`/api/jkb-user-center/v1/outside/fuxing/request-url`);

// 获取跳转下游保司链接
export const getDownstreamInsuranceLink = () =>
  $post('/api/jkb-user-center/v1/supply/stream/createDownStreamInsuranceUrl');

// 慧智微跳转禾连
export const getHelianLink = params =>
  $post('/api/jkb-user-center/v1/supply/stream/createSupplierStreamUrlWithToken', params);

// 珑珠积分支付状态--轮询接口
export const loopLongForPayStatus = params =>
  $post('/api/insurance-fw/h5/plan/longzhuPayStatus', params);

// 贝壳：健康管理服务
export const beikeHealthManageServiceDetail = () =>
  $post('/api/insurance-fw/h5/beike/getThirdEntrance');

// 烟台工会-校验当前token和传入信息是否为同一人
export const checkTokenBelongToCurrentUser = params =>
  $post('/api/jkb-user-center/v1/open/third/check-login-account', params);

// 获取龙湖珑珠余额
export const getLongZhuBalance = params =>
  $post('/api/insurance-fw/h5/plan/longzhuAccount', params);
