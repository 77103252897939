import store from '@/store';
import i18n from '@/lang';
import { getQueryString, cloneDeep } from '@/utils';
/**
 * 获取开窗事件id
 * @param {string | null} type 开窗时间类型：ANNUALLY-年度开窗；ANNUALLY_MULTI-一年多开；MONTHLY-月度开窗；LIFE_EVENTS-人生事件
 * @returns string 开窗事件id
 */
export const getOpenPeriodId = async () => {
  const type = getQueryString('chooseType');
  const openingData = store.state?.$_openingTime || {};
  if (type) {
    // 有开窗事件类型
    if (type !== 'LIFE_EVENTS') {
      // 年度计划
      store.commit('$_productDetail/SET_PROJ_NO', openingData?.openingTime?.[0]?.projNo || '');
      return openingData?.openingTime?.[0]?.openPeriodId || '';
    } else {
      // 人生事件
      if (!openingData?.lifeEventData) {
        await store.dispatch('$_openingTime/getLifeEventOpeningTime');
      }
      store.commit('$_productDetail/SET_PROJ_NO', openingData?.lifeEventData?.projNo || '');
      return isLifeEventOpening(openingData?.lifeEventData)
        ? openingData?.lifeEventData?.openPeriodId || ''
        : '';
    }
  } else {
    // 无开窗事件类型
    if (isYearPlanOpening() === 1) {
      //  首先取年度计划开窗事件ID
      store.commit('$_productDetail/SET_PROJ_NO', openingData?.openingTime?.[0]?.projNo || '');
      return openingData?.openingTime?.[0]?.openPeriodId || '';
    } else {
      // 其次取人生事件开窗事件ID
      if (!openingData?.lifeEventData) {
        await store.dispatch('$_openingTime/getLifeEventOpeningTime');
      }
      store.commit('$_productDetail/SET_PROJ_NO', openingData?.lifeEventData?.projNo || '');
      // 确保insuredId存在并在开窗期
      if (openingData?.lifeEventData?.insuredId && isLifeEventOpening(openingData?.lifeEventData)) {
        return openingData?.lifeEventData?.openPeriodId || '';
      }
      return '';
    }
  }
};

// 人生事件开窗判断
const isLifeEventOpening = lifeEventData => {
  // 先判断个人开窗
  const isSelfOpen = isOpening(lifeEventData);
  if (isSelfOpen) return true;
  // 再判断周期性开窗
  if (+lifeEventData?.cycleFlag === 1) {
    return isCirculationOpen(lifeEventData);
  }
  return false;
};

// 判断是否在个人开窗期
const isOpening = data => {
  const { openPeriodStart, openPeriodEnd } = data || {};
  if (openPeriodStart && openPeriodEnd) {
    const startTime = openPeriodStart.replace(/-/g, '/');
    const endTime = openPeriodEnd.replace(/-/g, '/');
    const Time = new Date().getTime();
    return new Date(endTime).getTime() >= Time && Time >= new Date(startTime).getTime();
  }
};

// 判断周期性开窗
const isCirculationOpen = data => {
  const { monthlyDayStart, monthlyDayEnd } = data;
  if (monthlyDayStart && monthlyDayEnd) {
    const day = new Date().getDate();
    return monthlyDayEnd >= day && monthlyDayStart <= day;
  }
};

// 年度计划开窗判断 0 未开始预热期  1 已开始开窗期 2 已结束
const isYearPlanOpening = () => {
  const openingData = store.state?.$_openingTime?.openingTime?.[0] || {};
  const { openPeriodEnd = '', openPeriodStart = '' } = openingData;
  if (!openPeriodStart || isDown(openPeriodStart)) {
    return 0;
  } else if (isDown(openPeriodEnd)) {
    return 1;
  } else {
    return 2;
  }
};

// 判断当前时间是否小于time
const isDown = val => {
  const time = val && val.replace(/-/g, '/'); // 兼容ios的时间处理
  const nowtime = new Date();
  const endtime = new Date(time);
  return nowtime < endtime;
};

// 处理商品中英文数据
export const formatLocale = productData => {
  if (!productData) {
    return null;
  }
  const lang = i18n.locale;
  const _data = cloneDeep(productData);
  if (lang === 'en') {
    const isPm = _data?.base?.sellModel === 'PM';
    const languagePackageEN = _data?.extend?.languagePackageEN || {};
    _data.img = { ..._data.img, ...cloneDeep(languagePackageEN) };
    _data.other.sellPointTag = languagePackageEN?.sellPointTag || _data.other.sellPointTag;
    _data.sale.productExplain = languagePackageEN?.productExplain || _data.sale.productExplain;
    _data.extFile.extFiles =
      languagePackageEN?.extFiles?.length > 0
        ? languagePackageEN?.extFiles
        : _data?.extFile?.extFiles;
    _data.base.name = languagePackageEN?.name || _data.base.name;
    if (isPm) {
      _data.skus = _data?.skus?.map(item => {
        const languagePackageEN = item?.extend?.languagePackageEN;
        if (languagePackageEN) {
          const attr = { ...item?.attr, ...languagePackageEN };
          const base = languagePackageEN?.name
            ? { ...item?.base, name: languagePackageEN?.name }
            : { ...item?.base };
          return { ...item, base, attr };
        }
        return { ...item };
      });
    } else {
      if (languagePackageEN?.name) {
        _data.base.name = languagePackageEN?.name;
      }
      _data.skus = _data?.skus?.map(item => {
        const specialAttrs = item?.specialAttrs?.map(it => {
          const specialAttrValues = it?.specialAttrValues?.map(i => {
            if (i?.extendDetails?.languagePackageEN) {
              return { ...i, ...i?.extendDetails?.languagePackageEN };
            }
            return { ...i };
          });
          if (it?.extendDetails?.languagePackageEN) {
            return { ...it, specialAttrValues, ...it?.extendDetails?.languagePackageEN };
          }
          return { ...it, specialAttrValues };
        });
        return { ...item, specialAttrs };
      });
    }
  }
  return _data;
};
