import CryptoJS from 'crypto-js';
import Apis from '@/api';
import { Toast } from 'vant';

export function stringifyObject(obj) {
  if (isNilString(obj)) return '';
  const keys = Object.keys(obj) || [];
  return keys.map(key => `${key}=${isNilString(obj[key]) ? '' : obj[key]}`)?.join('&');
}

export function urlcat(url, suffix) {
  if (typeof url !== 'string') {
    return '';
  }
  if (isNilString(suffix)) {
    return url;
  }
  // ['https://www.example.com/a/b?name=leslie&age=18', 'hash']
  var splited = url.split('#');
  var target = url;
  if (splited && splited.length) {
    target = splited.shift();
  }
  if (/\?/.test(target) || !/https?/.test(target)) {
    target = trim(target) + '&' + trim(suffix);
  } else {
    target = trim(target) + '?' + trim(suffix);
  }

  var hash = '';
  if (splited && splited.length) {
    hash = splited.join('#');
  }

  return trim(target + '#' + trim(hash));

  function trim(value) {
    return (value + '').replace(/^[?&#\/]+|[?&#\/]+$/g, ''); // eslint-disable-line
  }
}

export const isNilString = value => {
  return /^(?:null|undefined|)$/.test(value);
};

// 校验是否为空对象
export const isNilObject = value => {
  if (typeof value === 'object') {
    return Object.keys(value)?.length === 0;
  }
  return isNilString(value);
};

/**
 * 去除对象内的空属性
 * @param {object} obj 原对象
 * @returns 新对象
 */
export const removeEmptyProps = (obj = {}) => {
  Object.keys(obj)?.forEach(item => {
    if (isNilString(obj?.[item])) delete obj?.[item];
  });
  return obj;
};

/**
 * @description aes加密
 * @param {string} word 需要加密的字符串
 */

export const cryptoEncrypt = word => {
  if (!word) return word;
  const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ADB);
  const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ABS);
  const srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = '';

  encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};
/**
 * @description aes解密
 * @param {string} word 需要解密的字符串
 * @param {string} ABS 偏移量
 * @param {string} ADB key
 */

export const cryptoDecrypt = word => {
  if (!word) return word;
  const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ADB);
  const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ABS);
  const encryptedHexStr = CryptoJS.enc.Base64.parse(word);
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = '';

  decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypt.toString(CryptoJS.enc.Utf8).toString();
};

// PC模式禁用放大缩小页面
export const disableBrowserZoom = () => {
  document.addEventListener(
    'keydown',
    function(event) {
      if (
        (event.ctrlKey === true || event.metaKey === true) &&
        (event.which === 61 ||
          event.which === 107 ||
          event.which === 173 ||
          event.which === 109 ||
          event.which === 187 ||
          event.which === 189)
      ) {
        event.preventDefault();
      }
    },
    false,
  );
  // Chrome IE 360
  window.addEventListener(
    'mousewheel',
    function(event) {
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault();
      }
    },
    {
      passive: false,
    },
  );

  // firefox
  window.addEventListener(
    'DOMMouseScroll',
    function(event) {
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault();
      }
    },
    {
      passive: false,
    },
  );
};

// 获取小程序链接
export const toWechatMiniprogram = async (params = {}, header = {}) => {
  try {
    const basic = {
      source: window.sessionStorage.getItem('_source'),
      appId: 'wx18792702155c772e',
      path: '/pages/index/index',
      query: 'path=/mine',
    };
    const _params = { ...basic, ...params };
    const res = await Apis.getWechatMiniprogramLink(_params, header);
    const { code, data, success } = res || {};
    if (code === '0' && success && data) {
      window.location.href = data;
    } else {
      Toast.fail('获取小程序链接失败');
    }
  } catch (error) {
    Toast.fail('获取小程序链接失败');
  }
};

/**
 * @description 合并对象，若存在相同key，存入有值的那个，若都有值存入后者
 */
export const safeUpdateObject = (oa, ob) => {
  const arr = [];
  for (let entry of Object.entries(oa)) {
    arr.push(entry);
  }
  for (let entry of Object.entries(ob)) {
    arr.push(entry);
  }
  const ro = new Map();
  arr.forEach(i => {
    if (!ro.has(i[0])) {
      ro.set(i[0], i[1]);
    } else if (!ro.get(i[0])) {
      ro.set(i[0], i[1]);
    } else if (i[1]) {
      ro.set(i[0], i[1]);
    } else {
      // 输出
    }
  });
  return [...ro.entries()].reduce((obj, [key, value]) => ((obj[key] = value), obj), {});
};

// 根据身份证获取出生日期
export const getBirthById = (id, type = 'STRING') => {
  const fillZero = n => {
    if (n < 10) return '0' + n;
    return n?.toString();
  };
  try {
    const y = id?.toString()?.substring(6, 10);
    const m = id?.toString()?.substring(10, 12);
    const d = id?.toString()?.substring(12, 14);
    if (type === 'ARRAY') return [y, m, d];
    return `${y}-${m}-${d}`;
  } catch (error) {
    const _y = new Date().getFullYear()?.toString();
    const _m = fillZero(new Date().getMonth() + 1);
    const _d = fillZero(new Date().getDate());
    if (type === 'ARRAY') return [_y, _m, _d];
    return `${_y}-${_m}-${_d}`;
  }
};
