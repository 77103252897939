import request from '@/utils/request';
const options = {};

// const $get = request.get(options);
const $post = request.post(options);

const PREFIX = '/api';

// 获取保单详情
export const getPolicyDetail = params =>
  $post(`${PREFIX}/insurance-fw/h5/order/details`, { ...params });
