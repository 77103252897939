<template>
  <div
    ref="dragRef"
    class="drag"
    :style="{ left: `${x}px`, top: `${y}px` }"
    @mousedown="e => touchstart(e, 'DESKTOP')"
    @mouseup="e => touchend(e, 'DESKTOP')"
    @mousemove="e => touchmove(e, 'DESKTOP')"
    @touchstart="e => touchstart(e, 'MOBILE')"
    @touchend="e => touchend(e, 'MOBILE')"
    @touchmove="e => touchmove(e, 'MOBILE')"
    @click.stop
  >
    <img :style="`width:${width}px`" :src="source" alt="" class="drag-item" />
  </div>
</template>

<script>
import { device, isMobile } from '@/utils';

export default {
  props: {
    width: {
      type: Number,
      default: 88,
    },
    initRight: {
      type: Number,
      default: 0,
    },
    initTop: {
      type: Number,
      default: 400,
    },
    source: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      dragWidth: 0,
      dragHeight: 0,
      x: 0,
      y: 0,
      rangeX: 0,
      rangeY: 0,
      recordX: 0, // 记录开始点击时的位置，判断是移动还是点击
      recordY: 0,
      isDrop: false,
      isClick: false,
      moveCount: 0, // 兼容android
      timer: '',
    };
  },
  computed: {},
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.init();
    });
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.initWidth = this.$refs?.dragRef?.clientWidth;
        this.dragWidth = this.$refs?.dragRef?.clientWidth || this.width || 0;
        this.dragHeight = this.$refs?.dragRef?.clientHeight;
        this.bodyWidth = document.body.clientWidth;
        this.bodyHeight = document.body.clientHeight;
        this.x = this.bodyWidth - this.dragWidth - this.initRight;
        console.log(this.x, this.bodyWidth, this.dragWidth, this.initRight);
        this.y = this.initTop;
      });
    },
    touchstart(e, type) {
      if ((isMobile() && type === 'MOBILE') || (!isMobile() && type === 'DESKTOP')) {
        this.recordX = this.x;
        this.recordY = this.y;
        this.isDrop = true;
        this.isClick = true;
        this.moveCount = 0;
        this.timer = setTimeout(() => (this.isClick = false), 600);
      }
      e.preventDefault();
    },
    touchmove(e) {
      if (!this.isDrop) return;
      if (device() === 'android') {
        this.moveCount += 1;
        if (this.moveCount > 3) {
          clearTimeout(this.timer);
          this.isClick = false;
        }
      } else {
        clearTimeout(this.timer);
        this.isClick = false;
      }
      const TouchList = e?.changedTouches?.[0] || e;
      if (this.rangeX === 0 && this.rangeY === 0) {
        this.rangeY = TouchList.clientY - this.y;
      }
      let clientX = TouchList.clientX - this.rangeX;
      let clientY = TouchList.clientY - this.rangeY;
      if (clientY < 5) {
        clientY = 5;
      }
      if (clientY > this.bodyHeight - this.dragHeight - 5) {
        clientY = this.bodyHeight - this.dragHeight - 5;
      }
      if (clientX < 20) {
        clientX = 0;
      }
      if (clientX > this.bodyWidth - this.dragWidth) {
        clientX = this.bodyWidth - this.dragWidth;
      }
      this.x = clientX;
      this.y = clientY;
      e.preventDefault();
    },
    touchend(e, type) {
      if ((isMobile() && type === 'MOBILE') || (!isMobile() && type === 'DESKTOP')) {
        clearTimeout(this.timer);
        this.isDrop = false;
        this.rangeX = 0;
        this.rangeY = 0;
        if (this.x > this.bodyWidth / 2 - this.dragWidth / 2) {
          this.x = this.bodyWidth - this.dragWidth;
        } else {
          this.x = 0;
        }
        // 根据位置变化判断是点击还是拖动
        if (this.x === this.recordX && this.y === this.recordY) {
          this.$emit('on-click');
        }
      }

      e.preventDefault();
    },
  },
};
</script>

<style lang="less" scoped>
.drag {
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  &-item {
    display: block;
  }
}
</style>
