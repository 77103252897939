import request from '@/utils/request';
const options = {};

const $get = request.get(options);
const $post = request.post(options);

const PREFIX = '/api';

// 根据spuCode查询保险商品详情
export const getProductDetail = params =>
  $get(`${PREFIX}/trading-product/jkb/product/H5/spu/v2`, params);
export const getProductDetailNew = params => $post(`${PREFIX}/insurance-fw/h5/rule/spu`, params);

/** @name 校验商品 */
export const checkBeforeSubmitOrder = params =>
  $post(`${PREFIX}/insurance-fw/h5/rule/purchaseCheckForYearPlan`, { ...params });

/**
 * @name 订单下单
 * @old /trading-order/welfare/insurance/
 * @new /api/trading-order/outer/personal/order/synchroData
 * @new /insurance-fw/h5/plan/noYearPlanOrder
 */
export const submitOrder = params =>
  $post(`${PREFIX}/insurance-fw/h5/plan/noYearPlanOrder`, { ...params }, { errorHideToast: true });

// 支付下单
export const submitPayment = (unifiedType, params) =>
  $post(`${PREFIX}/insurance-fw/h5/plan/unifiedorder/${unifiedType}/v2`, {
    ...params,
  });

// 根据被保险人姓名模糊搜索
export const getPerson = params =>
  $post(`${PREFIX}/insurance-fw/h5/welfare/person/page`, params, {
    hideLoading: true,
  });
// 校验配偶
export const preCheck = params =>
  $post(`${PREFIX}/trading-order/welfare/insurance/preCheck`, { ...params });

// 动态费率
export const getDynamicPrice = (params, errorHideToast = false) =>
  $post(
    `${PREFIX}/insurance-fw/h5/price/getDynamicPrice`,
    { ...params },
    {
      hideLoading: true,
      cancelRepeatRequest: false,
      errorHideToast,
    },
  );

// 限购提示
export const getAdjust = params =>
  $post(
    `${PREFIX}/insurance-fw/h5/plan/item/group_limit/adjust`,
    { ...params },
    {
      hideLoading: true,
    },
  );

// 限购关系
export const getPlanProducts = params =>
  $post(`${PREFIX}/insurance-fw/h5/rule/conflict/plan_products`, { ...params });

// 查看商品 险种责任、保障详情
export const getSpuCustomDetail = params =>
  $post(`${PREFIX}/insurance-fw/h5/product/getSpuCustomDetail`, { ...params });

// 被保险人关系限制
export const hasFinishOrderByInsuredId = params =>
  $post(`${PREFIX}/insurance-fw/h5/plan/hasFinishOrderByInsuredId`, { ...params });

// 修改批量家庭人员
export const editInsuredBatch = params =>
  $post(
    `${PREFIX}/insurance-fw/h5/welfare/member/editInsuredBatch`,
    { ...params },
    {
      hideLoading: true,
    },
  );

// 重新点击被保人tab时校验续保信息
export const checkRenewalInfoApi = params =>
  $post(
    `${PREFIX}/insurance-fw/h5/plan/checkRenewal`,
    { ...params },
    {
      hideLoading: true,
    },
  );

// 获取被保险人的福利生效日期
export const getWelfareEffectDate = params =>
  $post(`/api/insurance-fw/h5/price/getWelfareEffectDate`, {
    ...params,
  });

// 查询单个spu购物车数据
export const getShopCartDataBySpu = params =>
  $post(`/api/insurance-fw/h5/plan/queryItem`, { ...params });

// 校验商品关系（互斥/才能买）
export const checkRelationProducts = params =>
  $post(`/api/insurance-fw/h5/rule/relationProducts`, {
    ...params,
  });

// 记录限购年龄校验不通过的数据
export const recordAgeCheckData = params =>
  $post(
    '/api/insurance-fw/open/h5/recordLog',
    { ...params },
    {
      hideLoading: true,
      errorHideToast: true,
    },
  );
