import { mapGetters, mapMutations } from 'vuex';
import { getOpenPeriodId } from '@/utils/insure';

export default {
  computed: {
    ...mapGetters(['themeColor']),
    ...mapGetters('$_openingTime', ['openingTime', 'insuranceNotes']),
  },
  methods: {
    ...mapMutations('$_insured', ['SET_CONFIRM_RENEWAL_FLAG']),

    /**
     * 校验是否有一键续保
     * @param {string} url 回跳地址
     */
    async getRenewalConfig(url) {
      // 重置状态
      this.SET_CONFIRM_RENEWAL_FLAG(false);
      const _this = this;
      const openPeriodTypeMap = {
        ANNUALLY: 19,
        ANNUALLY_MULTI: 20,
        MONTHLY: 21,
        LIFE_EVENTS: 22,
      };
      const openPeriodType = this.openingTime?.[0]?.openPeriodType || 'ANNUALLY';
      const lang = this.$i18n.locale;
      const details =
        this.insuranceNotes?.find(
          i => +i?.pageType === +openPeriodTypeMap?.[openPeriodType] && i?.lang === lang,
        ) || {};
      const {
        quickRenewalEnabled,
        quickRenewalTitle,
        quickRenewalContent,
        quickRenewalLeftBtnDisplay,
        quickRenewalLeftBtnText,
        quickRenewalRightBtnDisplay,
        quickRenewalRightBtnText,
      } = details;
      if (+quickRenewalEnabled === 1) {
        const openPeriodId = await getOpenPeriodId();
        const { code, data } = await this.$apis.checkOneKeyRenewal({
          companyEncode: window.sessionStorage.getItem('_companyCode'),
          projectID: window.localStorage.getItem('_projNo') || '',
          openPeriodId,
        });
        if (code === '0' && data) {
          this.$customDialog({
            title: quickRenewalTitle,
            message: quickRenewalContent,
            messageAlign: 'center',
            themeColor: _this.themeColor,
            isButtonMultiple:
              +quickRenewalLeftBtnDisplay === 1 && +quickRenewalRightBtnDisplay === 1,
            showCancelButton: +quickRenewalLeftBtnDisplay === 1,
            cancelButtonText: quickRenewalLeftBtnText,
            showConfirmButton: +quickRenewalRightBtnDisplay === 1,
            confirmButtonText: quickRenewalRightBtnText,
          })
            .then(() => {
              // 存储一键续保标识
              this.SET_CONFIRM_RENEWAL_FLAG(true);
              // 一键续保，再次购买
              window.location.href = url;
            })
            .catch(() => {
              this.SET_CONFIRM_RENEWAL_FLAG(false);
              // 重新选择，取消购物车选择
              window.location.href = url;
            });
        } else {
          window.location.href = url;
        }
      } else {
        window.location.href = url;
      }
    },
  },
};
