import { safeUpdateObject } from '@/utils/utils';

const account = {
  namespaced: true,
  state: {
    sourceInfo: {},
    bizStatus: undefined,
    ssoUserInfo: {},
    insuredId: '',
    authInfo: {},
  },
  getters: {
    sourceInfo: state => state.sourceInfo,
    bizStatus: state => state.bizStatus,
    ssoUserInfo: state => state.ssoUserInfo,
    insuredId: state => state.insuredId,
    authInfo: state => state.authInfo,
  },
  mutations: {
    SET_SOURCE_INFO: (state, payload) => {
      state.sourceInfo = payload;
    },
    SET_BIZ_STATUS: (state, payload) => {
      state.bizStatus = payload;
    },
    SET_SSO_USER_INFO: (state, payload) => {
      state.ssoUserInfo = safeUpdateObject(state.ssoUserInfo, payload);
    },
    SET_INSURED_ID: (state, payload) => {
      state.insuredId = payload;
    },
    SET_AUTH_INFO: (state, payload) => {
      state.authInfo = payload;
    },
  },
  actions: {},
};

export default account;
