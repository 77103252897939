import Apis from '@/api';

/**
 * 通过参数名获取url中的参数值
 * 示例URL:http://htmlJsTest/getrequest.html?uid=admin&rid=1&fid=2&name=小明
 * @param  {string} name 参数名
 * @param  {boolean} hash 是否是hash
 * @return {string}  参数值]
 *
 */
export function getQueryString(name, hash, href) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  let r;
  if (hash) {
    r = location.hash.split('?')[1] && location.hash.split('?')[1].match(reg);
  } else {
    r = location.search.substr(1).match(reg);
  }
  if (href) {
    if (hash) {
      r = href.split('?')[1] && href.split('?')[1].match(reg);
    } else {
      r = href.substr(1).match(reg);
    }
  }
  if (r != null) {
    return unescape(r[2]);
  }
  return '';
}

/**
 * 判断微信环境
 */
export const isWeChat = (() => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isEnv = userAgent.indexOf('MicroMessenger') >= 0 && userAgent.indexOf('wxwork') < 0;
  const isDev = ['development', 'dev'].includes(process.env.NODE_ENV);
  if (location.hostname.indexOf('localhost') != -1) {
    //本地开发环境  微信授权
    return false;
  }
  return isEnv || isDev;
})();

/**
 * 判断企业微信环境
 */
export const isWeWorkChat = (() => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isEnv =
    userAgent.toLowerCase().indexOf('micromessenger') > -1 &&
    userAgent.toLowerCase().indexOf('wxwork') > -1;
  return isEnv;
})();

/**
 * 时间转化年月日
 */
export const yyyymmdd = t => {
  const supply = time => (time < 10 ? `0${time}` : time);
  const date = new Date(t);
  const Y = date.getFullYear();
  const M = supply(date.getMonth() + 1);
  const D = supply(date.getDate());
  return `${Y}-${M}-${D}`;
};

/**
 * 时间转化年月日
 */
export const _yyyymmdd = t => {
  const supply = time => (time < 10 ? `0${time}` : time);
  const date = new Date(t);
  const Y = date.getFullYear();
  const M = supply(date.getMonth() + 1);
  const D = supply(date.getDate());
  return `${Y}-${M}-${D}`;
};
//
export const isIOS = () => {
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};
/**
 * ios键盘归位
 */
export const iosRestoration = () => {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  // 如果是IOS设备
  if (isiOS) {
    let flag = true;
    let pageBackNormFunc;
    // 聚焦后，键盘弹起
    document.body.addEventListener('focusin', () => {
      flag = false;
      pageBackNormFunc && clearTimeout(pageBackNormFunc);
    });
    // 失焦后，键盘关闭
    document.body.addEventListener('focusout', () => {
      flag = true;
      if (flag) {
        // 页面滚动回原来的位置
        pageBackNormFunc = setTimeout(() => {
          // window.scroll(0, 0); // 失焦后强制让页面归位
          // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 200);
      }
    });
  }
};

// 支付场景信息
export const scene = () => {
  return !isMobile() ? 'PC' : isWeChat ? 'JSAPI' : 'H5';
};

// 获取设备类型
export function device() {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isAndroid) return 'android';
  if (isiOS) return 'ios';
  return '未知';
}

// 删除url中某个参数;
export const removeUrlParam = (url, paramName) => {
  const u = url || window.location.href;
  const base = u.split('?')?.[0];
  const search = u.split('?')?.[1];
  if (search) {
    const entries = search.split('&');
    const entriesMap = entries?.map(i => {
      return {
        key: i?.split('=')?.[0],
        value: i?.split('=')?.[1],
      };
    });
    const filterEntriesMap = entriesMap?.filter(i => i.key !== paramName);
    if (filterEntriesMap?.length > 0) {
      const filterEntries = filterEntriesMap
        ?.map(i => {
          return `${i.key}=${i.value}`;
        })
        ?.join('&');
      return `${base}?${filterEntries}`;
    } else {
      return base;
    }
  } else {
    return u;
  }
};

export function isNull(value) {
  return /^(?:undefined|null|)$/.test(value);
}

/**
 * 判断深层嵌套对象是否为空
 * @param {要判断的对象} obj
 * @example isDeepEmpty({ a: { b: { c: [{ d: '' }] } } }) => true
 */
export function isDeepEmpty(obj) {
  if (isNull(obj)) {
    return true;
  }
  const keys = Object.keys(obj);
  return keys.every(key =>
    !isNull(obj[key]) && typeof obj[key] === 'object' ? isDeepEmpty(obj[key]) : isNull(obj[key]),
  );
}

// 给URL链接拼接多个参数
export function getUrlAllParams(urlString) {
  const url = decodeURI(urlString) || decodeURI(location.href);
  const res = {};
  const url_data = url?.split('?')?.length > 1 ? url?.split('?')?.[1] : null;
  if (!url_data) return {};
  const params_arr = url_data?.split('&') || [];
  params_arr.forEach(item => {
    const key = item?.split('=')?.[0];
    const value = item?.split('=')?.[1];
    res[key] = value;
  });
  return res;
}

export function objAddToUrlParams(param) {
  if (Object.prototype.toString.call(param) !== '[object Object]') {
    return '';
  }
  let queryParam = '';
  for (let key in param) {
    if (Object.hasOwnProperty.call(param, key)) {
      let value = param[key] || '';
      queryParam += `&${key}=${value}`;
    }
  }
  return queryParam ? queryParam.substring(1) : queryParam;
}
// 颜色16进制转化为rgba格式
export function hexToRgba(hex, opacity) {
  if (!hex) {
    hex = '#bacf60';
  }

  return (
    'rgba(' +
    parseInt('0x' + hex.slice(1, 3)) +
    ',' +
    parseInt('0x' + hex.slice(3, 5)) +
    ',' +
    parseInt('0x' + hex.slice(5, 7)) +
    ',' +
    opacity +
    ')'
  );
}

// PC/移动端判断
export const isMobile = () => {
  return window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  );
};

// 是否钉钉
export function isDingTalk() {
  const UA = window.navigator.userAgent;
  return /DingTalk/i.test(UA);
}

export const compareObjects = function(o1, o2) {
  if (typeof o1 !== 'object' || typeof o2 !== 'object') {
    return false;
  }
  const obj1 = cloneDeep(o1);
  const obj2 = cloneDeep(o2);
  const filter = obj => {
    Object.keys(obj).forEach(item => {
      if (isNull(obj[item])) delete obj[item];
    });
    return obj;
  };
  for (const p in filter(obj1)) {
    if (Object.prototype.hasOwnProperty.call(obj1, p)) {
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
  }
  for (const p in filter(obj2)) {
    if (Object.prototype.hasOwnProperty.call(obj2, p)) {
      if (obj1[p] !== obj2[p]) {
        return false;
      }
    }
  }
  return true;
};
//身份证中获取性别和年龄
export const getIdInfo = IdCard => {
  var reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
  if (reg.test(IdCard)) {
    let sex;
    if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
      sex = '1';
    } else {
      sex = '0';
    }
    var ageDate = new Date();
    var month = ageDate.getMonth() + 1;
    var day = ageDate.getDate();
    var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1;
    if (
      IdCard.substring(10, 12) < month ||
      (IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day)
    ) {
      age++;
    }
    if (age <= 0) {
      age = 1;
    }
    return { sex, age };
  } else {
    return false;
  }
};

// 防抖
export function debounce(func, delay = 300, immediate = false) {
  let timer = null;
  return function() {
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate && !timer) {
      func.apply(this, arguments);
    }
    timer = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

// 节流
export function throttle(func, delay = 300) {
  let prev = 0;
  return function() {
    let now = Date.now();
    if (now - prev >= delay) {
      func.apply(this, arguments);
      prev = Date.now();
    }
  };
}

// 数组求和
export const getSum = arr => {
  const sum = arr?.reduce((total, next) => {
    return total + parseFloat(next);
  }, 0);
  return sum;
};

// 深拷贝
export function cloneDeep(obj) {
  if (typeof obj !== 'object') return obj;
  if (obj == null) return obj;
  let newObj = Array.isArray(obj) ? [] : {};
  if (obj instanceof Date) {
    newObj = new Date(obj);
  }
  if (obj instanceof RegExp) {
    newObj = new RegExp(obj);
  }
  for (let key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      newObj[key] = typeof obj[key] == 'object' ? cloneDeep(obj[key]) : obj[key];
    }
  }
  return newObj;
}

// 对象拼接成字符串
export const formatObjectToString = obj => {
  if (Object.keys(obj)?.length > 0) {
    let arr = [];
    for (let [key, value] of Object.entries(obj)) {
      const _value = isNull(value) ? '' : typeof value === 'object' ? JSON.stringify(value) : value;
      arr.push(`${key}=${_value}`);
    }
    return arr.join('&');
  }
  return '';
};

/**
 * 判断终端系统类型
 * @returns {string}
 */
export const checkSystemType = () => {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // g
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isAndroid) {
    // 这个是安卓操作系统
    return 'android';
  }
  if (isIOS) {
    // 这个是ios操作系统
    return 'ios';
  }
  return '未知';
};

// 获取ip
export const getInternetProtocol = async () => {
  if (window.sessionStorage.getItem('_setInternetProtocol')) return;
  let ip = '';
  try {
    window.sessionStorage.setItem('_setInternetProtocol', '1');
    const res = await Apis.getUserIp();
    ip = res?.data;
  } catch (err) {
    console.log(`'获取IP失败：${err}`);
  } finally {
    window.sessionStorage.setItem('_internetProtocol', ip || 'unknown');
  }
};

// 以对象的形式返回链接上的参数
export const urlParamsToObject = url => {
  const u = new URL(url);
  const params = new URLSearchParams(u?.search);
  const paramObj = {};
  for (let [key, value] of params) {
    paramObj[key] = decodeURIComponent(value);
  }
  return paramObj;
};
