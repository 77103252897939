import Vue from 'vue';
import Vuex from 'vuex';
// import createLogger from 'vuex/dist/logger';
import modules from './modules';
import getters from './getter';
import persistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  getters,
  strict: true,
  plugins: [persistedState({ key: '_fw_store', storage: window.sessionStorage })],
});

export default store;
