import request from '@/utils/request';
const options = {};

// const $get = request.get(options);
const $post = request.post(options);

const PREFIX = '/api/insurance-fw';

/** @api 个性化页面配置-查询详情 */
export const getDiyPageDetailApi = params =>
  $post(`${PREFIX}/open/h5/diyPage/queryDetail`, { ...params });
