import request from '@/utils/request';
import { resolveWeChatSource } from '@/utils/business';
import { cryptoDecrypt } from '@/utils/utils';
import store from '@/store';

const options = {};
const $get = request.get(options);
const $post = request.post(options);

// 获取openId
export const getOpenId = params => $post('/api/usercenter/wechat/get_openId', params);

// 获取用户关联企业
export const relatedCompany = params =>
  $post('/api/insurance-fw/h5/welfare/auth/company/list', params);

// 根据token获取认证状态
export const getStateByToken = async (option = {}) => {
  const clientId = store.state?.$_account?.ssoUserInfo?.extId || null;
  const data = await $post(
    '/api/insurance-fw/h5/welfare/auth/person/auth/exactQuery',
    {
      source: +window.sessionStorage.getItem('_source'),
      channel: +window.sessionStorage.getItem('_isJian1bao') === 1 ? 'YL' : null,
      clientId,
    },
    { ...option },
  );
  //AES 解密
  if (data?.data) {
    data.data = Object.assign(data.data, {
      credentialsNumber: cryptoDecrypt(data?.data?.credentialsNumber),
      phone: cryptoDecrypt(data?.data?.phone),
    });
  }
  return data;
};

// 激活福利金账户
export const activateAccount = params =>
  $post(`/api/insurance-fw/h5/welfare/auth/company/activation?${params}`);

// 获取企业用户token
export const getToken = params => $post('/api/usercenter/platform/wecom/login', params);

//操作记录接口请求
export const setOperateLog = params => $post('/api/trading-order/welfare/insurance/log', params);

// 获取用户信息详情
export const getUserInfoDetail = params =>
  $post('/api/insurance-fw/h5/welfare/person/getUserDetail', params);

// 短信验证码
export const send_code = params => $post('/api/usercenter/login/send_code', params);

// 防刷验证码
export const refresh_code = params => $post('/api/usercenter/login/refresh_code', params);

//修改手机号
export const change_phone = params =>
  $post('/api/insurance-fw/h5/welfare/person/changeAuthPhone', params, {
    header: {
      language: window.localStorage.getItem('_language') === 'en' ? 'en-US' : 'zh-CN',
    },
  });

//修改手机号
export const infoUpdate = params => $post('/api/usercenter/userinfo/update', params);

/**
 * 以下为登录认证完善信息模块的接口
 */
// 校验登录态是否失效
export const checkLoginStatus = params => $get('/api/usercenter/login/valid_token', params);

// 根据source查询相关配置信息
export const getConfigBySource = params =>
  $get(`/api/jkb-user-center/v1/open/config/source/${params?.source}`);

// 发送短信验证码
export const sendVerifyCode = (params, header) =>
  $post('/api/usercenter/login/v2/send_code', params, { header });

// 登录
export const login = (params, errorHideToast = false) =>
  $post('/api/jkb-user-center/v1/user/login', params, { errorHideToast });

// 获取认证信息，校验认证状态
export const checkAuth = async (errorHideToast = false) => {
  const isJian1bao = +window.sessionStorage.getItem('_isJian1bao') === 1;
  let res;
  const curTimestamp = new Date().getTime();
  const info = JSON.parse(window.sessionStorage.getItem('_user_auth_info') || '{}');
  // 2秒内避免重复请求
  if (curTimestamp - 2000 < info?.timestamp) {
    res = info?.response;
  } else {
    const clientId = store.state?.$_account?.ssoUserInfo?.extId || null;
    res = await $get(
      '/api/jkb-user-center/v1/user/user-auth-info',
      {
        source: window.sessionStorage.getItem('_source'),
        companyCode: isJian1bao ? '' : window.sessionStorage.getItem('_companyCode'),
        clientId,
      },
      {
        errorHideToast,
      },
    );
  }
  const cache = {
    timestamp: curTimestamp,
    response: res,
  };
  window.sessionStorage.setItem('_user_auth_info', JSON.stringify(cache));
  window.sessionStorage.setItem('_isOnJob', +res?.data?.isWorking !== 2 ? '1' : '0');
  if (isJian1bao && res?.data?.companyEncode) {
    window.sessionStorage.setItem('_companyCode', res?.data?.companyEncode);
    window.sessionStorage.removeItem('_globalConfigCheck');
  }
  return res;
};

// 认证(新认证v3)
export const authenticate = params => $post('/api/jkb-user-center/v1/user/common-auth-v3', params);

export const commonAuthenticate = params =>
  $post('/api/jkb-user-center/v1/user/common-auth', params);

// 预认证
export const preAuthenticate = params => $post('/api/jkb-user-center/v1/user/pre-auth', params);

// 通过自定义标识和source获取手机号
export const getPhoneByIdentify = params =>
  $post('/api/jkb-user-center/v1/open/third/getCompanyStaffInfo', params);

// 企业微信授权登录
export const wxworkLogin = params => $post('/api/usercenter/platform/wecom/login', params);

//获取用户微信权限验证配置参数
export const wxConfigApi = params => {
  params = Object.assign({}, params, {
    source: resolveWeChatSource(false, true, false), // source为必填项
  });
  return $post('/api/trading-order/outer/wx/token/', params);
};

// 根据 source+自定义标识 校验认证状态，无需登录态
export const getAuthWithoutLoginByIdentify = params =>
  $post('/api/jkb-user-center/v1/open/third/isClientFirstLogin', params);

// 补充用户信息
export const completeUserInfo = (params, errorHideToast = false) =>
  $post('/api/jkb-user-center/v1/user/complete-info', params, { errorHideToast });

// 发送邮箱验证码
export const sendEmailVerifyCode = params =>
  $post('/api/jkb-user-center/v1/user/sendEmailAuthCode', params);

// 提交邮箱验证
export const submitEmailVerify = params =>
  $post('/api/jkb-user-center/v1/user/verifyEmailAuthCode', params);

// 绑定企业
export const userBindCompany = params =>
  $post('/api/jkb-user-center/v1/company-staff/saveOrBindCompany', params);

// 退出登录
export const logout = params => $get('/api/jkb-user-center/v1/user/logout', params);

// 无需token发送邮箱验证码
export const sendEmailVerifyCodeOpen = params =>
  $post('/api/jkb-user-center/v1/open/user/sendEmailAuthCode', params);

// 无需token提交邮箱验证
export const submitEmailVerifyOpen = params =>
  $post('/api/jkb-user-center/v1/open/user/verifyEmailAuthCode', params);

// 查询用户选择的语言
export const queryUserLanguage = params =>
  $post('/api/jkb-user-center/v1/jkb-user/lang/query', params, {
    errorHideToast: true,
    hideLoading: true,
  });

// 保存用户选择的语言
export const updateUserLanguage = params =>
  $post('/api/jkb-user-center/v1/jkb-user/lang/saveOrUpdate', params, {
    errorHideToast: true,
    hideLoading: true,
  });

// 代登录
export const agentLogin = params => $post('/api/jkb-user-center/v1/open/user/proxyLogin', params);

// 获取用户绑定企业信息
export const getBindCompany = () => $get('/api/jkb-user-center/v1/company-staff/getBindCompany');

// 获取用户全部关联企业信息
export const getRelatedCompany = params =>
  $post('/api/jkb-user-center/v1/company-staff/allCompany', params);

// 龙湖小湖医生登录
export const longforHmLogin = params =>
  $post('/api/jkb-user-center/v1/open/third/third-login', params);

// 龙湖弹福登录
export const longforFwLogin = params =>
  $post('/api/jkb-user-center/v1/open/third/longhu/fw/login', params);

// 获取企业中英文配置数据
export const getCompanyLocaleConfig = params =>
  $post('/api/insurance-fw/content/manage/c/contentManageDetail', params);

// 记录用户授权
export const recordUserAuthorization = params =>
  $post('/api/jkb-user-center/v1/open/jkb-user/user_authorize_tag/save', params);

// 获取用户人员可见分类和标签
export const getUserClassifyAndTag = params =>
  $post('/api/insurance-fw/h5/company/member/classifyAndTag', params);

// 获取ip
export const getUserIp = () => $post('/api/jkb-user-center/v1/open/user/ip');
