import request from '@/utils/request';
const options = {};

const $post = request.post(options);

const PREFIX = '/api/insurance-fw';

// 查询福利余额
export const getBenefitsBalance = params =>
  $post(`${PREFIX}/h5/welfare/personal/account/balance`, { ...params });

// 查询福利余额明细
export const getBenefitsBalanceDetail = params =>
  $post(`${PREFIX}/h5/welfare/personal/balance/detail/page`, { ...params });
// 获取积分流水
export const getPersonalPointsOperationPage = params =>
  $post(`${PREFIX}/h5/points/getPersonalPointsOperationPage`, { ...params });
// 获取积分明细
export const getPointsAccountDetails = params =>
  $post(`${PREFIX}/h5/points/getPointsAccountDetails`, { ...params });
// 获取积分明细
export const getCompanyPointsConfig = params =>
  $post(`${PREFIX}/h5/points/getCompanyPointsConfig`, { ...params });
//积分失效列表
export const getPersonalExpiredPointsPage = params =>
  $post(`${PREFIX}/h5/points/getPersonalExpiredPointsPage`, { ...params });

export const projectDetailByProjNo = params =>
  $post(`${PREFIX}/h5/project/projectDetailByProjNo`, { ...params });

// 获取快手积分流水
export const getQuickerTransaction = params =>
  $post(`${PREFIX}/kuaishou/transaction/getTransaction`, { ...params });
// 获取积分明细
export const getQuickerPointInfo = params =>
  $post(
    `${PREFIX}/kuaishou/transaction/getUserPointsInfo`,
    { ...params },
    { errorHideToast: true },
  );

// 未认证获取积分明细
export const queryUserPointByNoAuth = params =>
  $post(
    `${PREFIX}/kuaishou/transaction/queryUserPointByNoAuth`,
    { ...params },
    { errorHideToast: true },
  );
// 获取剩余积分
export const getCommonPointUsable = params =>
  $post(`${PREFIX}/kuaishou/transaction/getCommonPointUsable`, { ...params });
