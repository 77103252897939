import Vue from 'vue';
import VueI18N from 'vue-i18n';
import locale from 'element-ui/lib/locale';
import cn from './module/cn';
import en from './module/en';

Vue.use(VueI18N);
const i18n = new VueI18N({
  locale: window.localStorage.getItem('_language') === 'en' ? 'en' : 'cn',
  messages: {
    en,
    cn,
  },
});

locale.i18n((key, value) => i18n.t(key, value));

window.$i18n = i18n;
export default i18n;
