<template>
  <drag-click-item :init-top="40" :source="mobileSwitchSrc" @on-click="onSwitch" />
</template>

<script>
import DragClickItem from '@/components/Mobile/dragClickItem.vue';
import mixins from '@/mixins/switchLanguage';
export default {
  mixins: [mixins],
  props: {
    type: String,
  },
  components: { DragClickItem },
};
</script>

<style lang="less" scoped></style>
