// 已离职员工可以访问的页面路由
export default [
  'agent',
  'middleware',
  'login',
  'authenticate',
  'supply',
  'email',
  'error',
  'certification',
  'userCenter',
  'familyMember',
  'familyMemberInfo',
  'policy',
  'policyMaster',
  'policyDetail',
  'welfareOverviewUnselected',
  'welfareOverviewUncreated',
  'welfareOverviewDetail',
  'welfareOverviewDetailQuicker',
  'welfareOverviewDetailQuicker',
  'aboutUs',
  'spAgreement',
  'spPrivacy',
  'noLoginHomepage',
  'catlNotification',
  'catlAnnouncement',
];
