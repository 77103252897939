var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dragRef",
      staticClass: "drag",
      style: { left: `${_vm.x}px`, top: `${_vm.y}px` },
      on: {
        mousedown: (e) => _vm.touchstart(e, "DESKTOP"),
        mouseup: (e) => _vm.touchend(e, "DESKTOP"),
        mousemove: (e) => _vm.touchmove(e, "DESKTOP"),
        touchstart: (e) => _vm.touchstart(e, "MOBILE"),
        touchend: (e) => _vm.touchend(e, "MOBILE"),
        touchmove: (e) => _vm.touchmove(e, "MOBILE"),
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("img", {
        staticClass: "drag-item",
        style: `width:${_vm.width}px`,
        attrs: { src: _vm.source, alt: "" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }