var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.bottomNavigationShow
    ? _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            staticClass: "head",
            style: { justifyContent: _vm.justifyAlign ? "center" : "flex-end" },
          },
          [
            (_vm.navigationData.picList || []).length > 0
              ? _c(
                  "div",
                  { staticClass: "navList" },
                  _vm._l(_vm.navigationData.picList, function (nav, index) {
                    return _c(
                      "div",
                      {
                        key: `nav${index}`,
                        staticClass: "nav align-center pointer",
                        style: _vm.getColor(nav),
                        on: {
                          click: function ($event) {
                            return _vm.skipAction(
                              nav,
                              _vm.navigationData.itemKey,
                              "navs"
                            )
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(nav.configName))])]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }