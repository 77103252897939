import {
  balanceDetailStatusList,
  policyStatusList,
  categoryStatusList,
  insuredRelationshipOptions,
} from '@/constants/enumeration';

/**
 * 原始价格单位元，金额千分位并保留两位小数
 * @returns 保留两位小数的千分位字符串数字，
 */
export const dieTausendstel = num => {
  const reg = /\B(?=(\d{3})+(?!\d))/g;
  if (typeof num !== 'number') {
    if (isNaN(parseFloat(num))) {
      return num;
    }
    return (parseFloat(num).toFixed(2) + '').replace(reg, '$&,');
  }
  return (num.toFixed(2) + '').replace(reg, '$&,');
};

/**
 * 原始价格单位分，金额千分位并保留两位小数
 * @returns 保留两位小数的千分位字符串数字
 */
export const dieTausendstelFen = num => {
  const reg = /\B(?=(\d{3})+(?!\d))/g;
  if (typeof num !== 'number') {
    if (isNaN(parseFloat(num))) {
      return num;
    }
    return ((parseFloat(num) / 100).toFixed(2) + '').replace(reg, '$&,');
  }
  return ((num / 100).toFixed(2) + '').replace(reg, '$&,');
};
/**
 *
 * @returns 千分位字符串数字
 */
export const dieTausen = num => {
  const reg = /\B(?=(\d{3})+(?!\d))/g;
  if (typeof num !== 'number') {
    if (isNaN(parseFloat(num))) {
      return num;
    }
    return (parseFloat(num) + '').replace(reg, '$&,');
  }
  return (num + '').replace(reg, '$&,');
};
/**
 * 福利金明细状态映射
 * @param {number} code 状态编码
 * @returns 状态中文名
 */
export const balanceStatus = code => {
  return balanceDetailStatusList.find(i => i.value === code)?.text;
};

/**
 * 保单状态映射
 * @param {number} code 状态编码
 * @returns 状态中文名
 */
export const policyStatus = code => {
  return policyStatusList().find(i => i.value === code)?.text;
};

/**
 * 福利金类型态映射
 * @param {number} code 状态编码
 * @returns 状态中文名
 */
export const categoryStatus = code => {
  return categoryStatusList.find(i => i.value === code)?.text;
};

//
export const sensitiveField = (field, before = 3, after = 4) => {
  if (!field) {
    return '';
  }
  field = String(field);

  // 匹配中文、英文、数字
  const regItem = '.';
  const regExp = `(${regItem}{${before}})${regItem}*(${regItem}{${after}})`;
  const reg = new RegExp(regExp);

  return field.replace(reg, '$1*****$2');
};
export const coverageUnit = unit => {
  const coverageUnit = {
    1: '元',
    2: '万',
  };
  return coverageUnit[unit] || '';
};

// 家属关系
export const relationName = val => {
  return insuredRelationshipOptions().find(item => item?.value === val)?.text || '';
};

// 健康小屋订单状态
export const healthCabinOrderStatus = value => {
  if (!value) return '-';
  const Map = {
    INIT: '已预约',
    USED: '已使用',
    CANCELED: '已取消',
    EXPIRED: '已过期',
  };
  return Map[value] || '-';
};
