var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.bottomNavigationShow
    ? _c(
        "footer",
        {
          class: ["flex-row", _vm.iosCompatibility],
          attrs: { id: "app-footer" },
        },
        [
          (_vm.navigationData.picList || []).length > 0
            ? _vm._l(_vm.navigationData.picList, function (nav, index) {
                return _c(
                  "div",
                  {
                    key: `nav${index}`,
                    staticClass: "nav flex-column align-center",
                    style: {
                      width: 100 / _vm.navigationData.picList.length + "%",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.skipAction(
                          nav,
                          _vm.navigationData.itemKey,
                          true
                        )
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "svg-icon",
                      style: {
                        webkitMaskImage: `url(${nav.picUrl})`,
                        maskImage: `url(${nav.picUrl})`,
                        background: _vm.getColor(nav),
                      },
                    }),
                    _c("span", { style: { color: _vm.getColor(nav) } }, [
                      _vm._v(_vm._s(nav.configName)),
                    ]),
                  ]
                )
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }