const insured = {
  namespaced: true,
  state: {
    form: [], //被保险人数据
    cardType: [1], //允许人员类型
    productData: {}, //产品数据
    familyList: [], //家庭成员
    hasCanBuy: false, // 是否配置了只能买规则
    relationship: '', // 默认人群关系
    currentInsured: {}, // 当前添加被保险人信息
    currentChosenSuit: {}, // 当前选中的计划
    rootExtra: {}, //年龄限购提示
    relationshipList: [], //关系列表
    showDefaultPrice: false, // 金额默认显示
    planItemID: '', // 已加入购物车的spu id
    confirmRenewalFlag: false, // 确认一键续保标识
  },
  getters: {
    productData: state => state.productData,
    familyList: state => state.familyList,
    form: state => state.form,
    showDefaultPrice: state => state.showDefaultPrice,
    planItemID: state => state.planItemID,
    confirmRenewalFlag: state => state.confirmRenewalFlag,
  },
  mutations: {
    SET_RELATIONSHIP: (state, value) => {
      state.relationship = value;
    },
    SET_FORM: (state, value) => {
      state.form = value;
    },
    SET_PRODUCT_DATA: (state, value) => {
      state.productData = value;
    },
    SET_CARD_TYPE: (state, value) => {
      state.cardType = value;
    },
    SET_FAMILY_LIST: (state, value) => {
      // console.log(JSON.parse(JSON.stringify(value || {})), 'value111');
      state.familyList = value;
    },
    SET_RELATIONSHIP_LIST: (state, value) => {
      // console.log(JSON.parse(JSON.stringify(value || {})), 'value111');
      state.relationshipList = value;
    },
    SET_HAS_CAN_BUY: (state, value) => {
      state.hasCanBuy = value;
    },
    SET_CURRENT_INSURED: (state, value) => {
      state.currentInsured = value;
    },
    SET_CURRENT_CHOSEN_SUIT: (state, value) => {
      state.currentChosenSuit = value;
    },
    SET_ROOTEXTRA: (state, value) => {
      state.rootExtra = value;
    },
    SET_PLAN_ITEM_ID: (state, value) => {
      state.planItemID = value;
    },
    SET_SHOW_DEFAULT_MONEY: (state, value) => {
      state.showDefaultPrice = value;
    },
    SET_CONFIRM_RENEWAL_FLAG: (state, value) => {
      state.confirmRenewalFlag = value;
    },
  },
  actions: {},
};

export default insured;
