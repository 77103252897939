import { getQueryString } from '@/utils';
import store from '@/store';
import i18n from '@/lang';

/**
 * 公众号source相关处理，需考虑存在迁移公众号导致需要同时使多个公众号能使用的情况
 * @param {boolean} onlyCheckSource 是否仅校验是公众号source
 * @param {boolean} onlyGetPublicSource 是否仅获取公众号source
 * @param {boolean} onlyGetAppId 是否仅获取公众号source对应appid
 */
export const resolveWeChatSource = (
  onlyCheckSource = false,
  onlyGetPublicSource = false,
  onlyGetAppId = false,
) => {
  const source = window.sessionStorage.getItem('_source') || getQueryString('source', false);
  const obj = JSON.parse(process.env.VUE_APP_PUBLIC_MAP || '{}') || {};
  if (onlyCheckSource) {
    return source in obj;
  }
  if (onlyGetPublicSource) {
    if (source in obj) {
      return source;
    } else {
      return process.env.VUE_APP_DEFAULT_SOURCE;
    }
  }
  if (onlyGetAppId) {
    if (source in obj) {
      window.localStorage.setItem('_weChatSource', source);
      window.localStorage.setItem('_weChatAppId', obj?.[source]);
      return obj?.[source];
    } else {
      window.localStorage.setItem('_weChatSource', process.env.VUE_APP_DEFAULT_SOURCE);
      window.localStorage.setItem('_weChatAppId', process.env.VUE_APP_ID);
      return process.env.VUE_APP_ID;
    }
  }
};

/**
 * 获取登录短信模板id
 * @param {string} countryCode 国际区号编码
 */
export const getMessageTemplateId = countryCode => {
  // 模板id集合
  const ids = store.state?.$_config?.messageTemplateId || {};
  // 是否开启国际区号
  const isOpenAbroad = store.state?.$_config?.countryCodeEnabled;
  // 是否大陆
  const isMainland = +countryCode === 86;
  if (isOpenAbroad && !isMainland) {
    if (i18n.locale === 'en' && ids?.abroadMsgIdEn) {
      return ids?.abroadMsgIdEn;
    } else if (i18n.locale === 'cn' && ids?.abroadMsgIdCn) {
      return ids?.abroadMsgIdCn;
    } else {
      return '';
    }
  } else {
    if (i18n.locale === 'en' && ids?.msgIdEn) {
      return ids?.msgIdEn;
    } else if (i18n.locale === 'cn' && ids?.msgIdCn) {
      return ids?.msgIdCn;
    } else {
      return '';
    }
  }
};

/**
 * 主题变更域名备案的对策处理：替换跳转链接域名是jkb.sipaicare.com.cn或jkb.medbanks.cn为当前项目访问链接域名
 * @param {string} url 原链接
 * @returns
 */
export const transferDomain = url => {
  if (
    url?.indexOf('jkb.spcare.com.cn') > -1 ||
    url?.indexOf('jkb.sipaicare.com.cn') > -1 ||
    url?.indexOf('jkb.medbanks.cn') > -1
  ) {
    const _url = url?.replace(
      /jkb.spcare.com.cn|jkb.sipaicare.com.cn|jkb.medbanks.cn/g,
      window.location.hostname,
    );
    return _url;
  }
  return url;
};
